import React, { useState, useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import Alert from "@mui/material/Alert";

import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import { UseEditProjectGoal } from "../../hooks/project/singleProject";
import { Button } from "@mui/material";

function ProjectGoalEditModal({ isOpen, closeModal, id, editState }) {
  const [inputFields, setInputFields] = useState([
    { name: "", project_id: id },
  ]);
  const [goal, setGoal] = useState("");

  useEffect(() => {
    setGoal(editState.project_goal);
    setInputFields(editState.project_goal_objectives);
  }, [editState]);

  const editProjectGoalMutation = UseEditProjectGoal(closeModal);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const goalData = {
      data: {
        name: goal,
        project_goal_objectives_attributes: inputFields,
      },
      project_id: id,
      id: editState.id,
    };
    editProjectGoalMutation.mutate(goalData);
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#047C7C" }}
        closeButton
      >
        <Modal.Title>Edit Project Goal and Objectives</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {editProjectGoalMutation.isError && (
          <Alert variant="filled" severity="error">
            Something went wrong while editing the goal. Kindly try again
          </Alert>
        )}

        <form className="project-goals-tt" onSubmit={handleFormSubmit}>
          <div className="form-group project-goal-field">
            <label htmlFor="title">Goal</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={editState.project_goal}
              onChange={(event, editor) => {
                const data = editor.getData();
                setGoal(data);
              }}
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={editProjectGoalMutation.isLoading}
          >
            {editProjectGoalMutation.isLoading ? "Editing..." : "Edit Goal"}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ProjectGoalEditModal;
