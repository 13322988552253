import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import styles from "../css/main.module.css";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import { deepOrange } from "@mui/material/colors";
import { displayDate, displayTime } from "../../../helpers/timeModule";

const MostRecentActivity = ({ dashboardContent }) => {
  const history = useHistory();

  const navigateToUserProfile = (profile) => {
    history.push({
      pathname: `/profile/${profile}`,
    });
  };

  const navigateToProject = (project) => {
    history.push({
      pathname: `/projects/${project}`,
    });
  };
  const displayActivites = () => {
    const { activities } = dashboardContent;

    if (activities.length < 1)
      return (
        <Alert severity="info">There are no Engagements at the moment</Alert>
      );

    const results = activities.map((value) => {
      return (
        <Fragment key={value.id}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              {value.user.profile_avatar && value.user.profile_avatar.url ? (
                <Avatar
                  alt={`${value.user.first_name} ${value.user.last_name}`}
                  src={`${value.user.profile_avatar.url}`}
                />
              ) : (
                <Avatar
                  sx={{ bgcolor: deepOrange[500] }}
                  src="/broken-image.jpg"
                  alt={`${value.user.first_name}`}
                />
              )}
            </ListItemAvatar>

            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    sx={{
                      display: "inline",
                      fontSize: "16px",
                      fontWeight: "500",
                      letterSpacing: "1px",
                      color: "purple",
                    }}
                    variant="body2"
                    color="text.primary"
                  >
                    <Link
                      sx={{
                        display: "inline",
                        fontWeight: "500",
                        paddingRight: "10px",
                        fontSize: "16px",
                      }}
                      component="button"
                      variant="body2"
                      onClick={() => {
                        navigateToUserProfile(value.user.id);
                      }}
                    >
                      {value.user.first_name} {value.user.last_name}
                    </Link>
                    {`has ${value.action} a ${value.model}.`}
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{
                      display: "inline",
                      fontWeight: "500",
                      paddingRight: "10px",
                    }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Project Name:
                  </Typography>
                  <Link
                    sx={{
                      display: "inline",
                      fontWeight: "500",
                      paddingRight: "10px",
                    }}
                    component="button"
                    variant="body2"
                    onClick={() => {
                      navigateToProject(value.project.id);
                    }}
                  >
                    {value.project.name}
                  </Link>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      marginTop: "10px",
                      textAlign: "right",
                      color: "#5f5d5d",
                      fontWeight: "600",
                      fontFamily: "Roboto, sans-serif",
                      textTransform: "lowercase",
                    }}
                    variant="body2"
                    color="text.primary"
                  >
                    {value.action}: {displayDate(value.created_at).value}
                    &nbsp;
                    {displayTime(
                      displayDate(value.created_at).unit,
                      displayDate(value.created_at).value
                    )}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </Fragment>
      );
    });

    return (
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>{results}</List>
    );
  };

  return (
    <div className={styles.most_recent_activity_container}>
      <div className={styles.info_card_header_activites}>
        <GroupWorkIcon
          sx={{
            fontSize: "32px",
            marginRight: "5px",
            fontWeight: "600",
          }}
        />
        <h4 className={styles.info_card_header_title}>
          Recent Engagements {`(${dashboardContent.activities.length})`}
        </h4>
      </div>
      <div className={styles.info_card_body}>{displayActivites()}</div>
    </div>
  );
};

export default MostRecentActivity;
