import { BASEURL } from "../../API/apiData";
import axios from "axios";

const createNewProjectGallery = async (data) => {
  const token = localStorage.getItem("token");

  const { project_id, gallerFormData } = data;
  const response = await axios.post(
    `${BASEURL}projects/${project_id}/project_files`,
    gallerFormData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};

const fetchAllProjectGallery = async (project_id) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_files`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export { createNewProjectGallery, fetchAllProjectGallery };
