import React, { useState } from "react";
import parse from "html-react-parser";
import EditSwotModal from "./EditSwotModal";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Badge from "@mui/material/Badge";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { NotificationManager } from "react-notifications";
import { downvote, upvote } from "../../../services/upvote";
import { showSpecificSwotDetail } from "../../../services/swotAnalysis/swotDetails";
import AppProfilesList from "../globals/AppProfilesList";
import CommentBox from "../globals/CommentBox";
import {
  createNewSwotComment,
  createSwotCommentResponses,
  editSwotComment,
  editSwotCommentResponses,
} from "../../../services/swotAnalysis/projectSwot";

function SwotDetailsView({
  swotTitleDetail,
  setShowCreateModal,
  swotDetails,
  projectID,
  swotID,
  dynamicRoute,
  fetchSwotSpecificDetails,
  tabDetails,
  setSwotDetails,
  block,
}) {
  const [commentModal, setCommentModal] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentBoxDetail, setCommentBoxDetail] = useState("");
  const [submitButton, setSubmitButton] = useState(false);
  const [editSubmitButton] = useState(false);
  const [labelDetails, setLabelDetails] = useState("");
  const [item, setItem] = useState({});

  const [editState, setEditState] = useState({
    editModal: false,
    editname: "",
    detailID: null,
  });
  const [state, setState] = useState(false);
  const [profileList, setProfileList] = useState({
    profiles: [],
    openModal: false,
  });
  // ==== HANDLE UPVOTE OF PARTICULAR ITEM ====
  const [upvoteBtns, setUpvoteButtons] = useState({
    id: null,
    clicked: false,
  });

  // ==== REUSABLE SWOT UPVOTE FUNCTION ====
  const upvoteStateChange = async (swot, initialSwotDetail, value) => {
    setUpvoteButtons({
      id: value.id,
      clicked: true,
    });

    // ==== FIRST CHANGE THE UPVOTE BUTTON TO TRUE FOR A USER EXPERIENCE IMPROVEMENT ====
    const currentSwotItem = swotDetails.findIndex(
      (detail) => detail.id === value.id
    );
    const updatedSwotItems = {
      ...swotDetails[currentSwotItem],
      likes: {
        upvoted: true,
        counter: swotDetails[currentSwotItem].likes.counter + 1,
      },
      upvoted_by: [],
    };

    let newProjectSwotItems = [...swotDetails];
    newProjectSwotItems[currentSwotItem] = updatedSwotItems;
    setSwotDetails(newProjectSwotItems);

    // ===== THEN LETS POST THE UPVOTE TO THE DATABASE =====

    try {
      const { message } = await upvote(value.id, swot);
      if (message === "upvote successful") {
        const { result } = await showSpecificSwotDetail(
          projectID,
          value.project_swot_id,
          dynamicRoute,
          value.id
        );

        const updatedSwotItems = {
          ...swotDetails[currentSwotItem],
          likes: {
            upvoted: result.likes.upvoted,
            counter: result.likes.counter,
          },
          upvoted_by: result.upvoted_by,
        };

        let newProjectSwotItems = [...swotDetails];
        newProjectSwotItems[currentSwotItem] = updatedSwotItems;
        setSwotDetails(newProjectSwotItems);

        setUpvoteButtons({
          id: null,
          clicked: false,
        });
      }
    } catch (error) {
      // --- if it doesnt work return the state to its initial state and show an error ---
      setUpvoteButtons({
        id: null,
        clicked: false,
      });

      setSwotDetails(initialSwotDetail);
      NotificationManager.error("Something went wrong when upvoting");
    }
  };

  // <==== DOWN VOTE A PARTICULAR SWOT ITEM ===

  const downVoteStateChange = async (swot, initialSwotDetail, value) => {
    setUpvoteButtons({
      id: value.id,
      clicked: true,
    });

    // ==== FIRST CHANGE THE UPVOTE BUTTON TO FALSE FOR A USER EXPERIENCE IMPROVEMENT ====
    const currentSwotItem = swotDetails.findIndex(
      (detail) => detail.id === value.id
    );
    const updatedSwotItems = {
      ...swotDetails[currentSwotItem],
      likes: {
        upvoted: false,
        counter: swotDetails[currentSwotItem].likes.counter - 1,
      },
      upvoted_by: [],
    };

    let newProjectSwotItems = [...swotDetails];
    newProjectSwotItems[currentSwotItem] = updatedSwotItems;
    setSwotDetails(newProjectSwotItems);

    // ===== THEN LETS POST THE DOWNVOTE TO THE DATABASE =====

    try {
      const { message } = await downvote(value.id, swot);
      if (message === "Your vote has been removed") {
        const { result } = await showSpecificSwotDetail(
          projectID,
          value.project_swot_id,
          dynamicRoute,
          value.id
        );

        const updatedSwotItems = {
          ...swotDetails[currentSwotItem],
          likes: {
            upvoted: result.likes.upvoted,
            counter: result.likes.counter,
          },
          upvoted_by: result.upvoted_by,
        };

        let newProjectSwotItems = [...swotDetails];
        newProjectSwotItems[currentSwotItem] = updatedSwotItems;
        setSwotDetails(newProjectSwotItems);

        setUpvoteButtons({
          id: null,
          clicked: false,
        });
      }
    } catch (error) {
      // --- if it doesnt work return the state to its initial state and show an error ---
      setUpvoteButtons({
        id: null,
        clicked: false,
      });

      setSwotDetails(initialSwotDetail);
      NotificationManager.error("Something went wrong when downvoting");
    }
  };

  // ==== UPVOTE DEPENDING ON WHICH TAB SWOT SELECTED THAT A USER IS IN ====
  const handleLike = (value) => {
    const initialSwotDetail = swotDetails;
    // < ==== STRENGTH ==== >
    if (swotTitleDetail === "Strength") {
      upvoteStateChange("SwotStrength", initialSwotDetail, value);
      return;
    }

    // <==== WEAKNESS ====>

    if (swotTitleDetail === "Weakness") {
      upvoteStateChange("SwotWeakness", initialSwotDetail, value);
      return;
    }
    // < ==== OPPORTUNITY ==== >
    if (swotTitleDetail === "Opportunity") {
      upvoteStateChange("SwotOpportunity", initialSwotDetail, value);
      return;
    }
    // <==== THREAT ====>
    if (swotTitleDetail === "Threat") {
      upvoteStateChange("SwotThreat", initialSwotDetail, value);
      return;
    }
  };

  // ==== DOWNVOTE DEPENDING ON WHICH TAB SWOT SELECTED THAT A USER IS IN ====
  const handleDislike = (value) => {
    const initialSwotDetail = swotDetails;

    if (swotTitleDetail === "Strength") {
      downVoteStateChange("SwotStrength", initialSwotDetail, value);
      return;
    }

    if (swotTitleDetail === "Weakness") {
      downVoteStateChange("SwotWeakness", initialSwotDetail, value);
      return;
    }

    if (swotTitleDetail === "Opportunity") {
      downVoteStateChange("SwotOpportunity", initialSwotDetail, value);
      return;
    }

    if (swotTitleDetail === "Threat") {
      downVoteStateChange("SwotThreat", initialSwotDetail, value);
      return;
    }
  };

  const handleShowProfiles = (profiles) => {
    setProfileList({
      profiles,
      openModal: true,
    });
  };

  const handleCloseProfileList = () => {
    setProfileList({
      profiles: [],
      openModal: false,
    });
  };

  // ==== COMMENTS ====
  const refreshCommentData = async (value) => {
    const { result } = await showSpecificSwotDetail(
      projectID,
      value.project_swot_id,
      dynamicRoute,
      value.id
    );
    setComments(result.comments);
  };
  const handleOpenCommentBox = (value) => {
    const { comments } = value;
    let swotName = `${swotTitleDetail}_name`.toLocaleLowerCase();
    setComments(comments);
    setLabelDetails(value[swotName]);
    setCommentModal(true);
  };

  const checkData = (item, detail) => {
    if (swotTitleDetail === "Strength") {
      const data = {
        comment: {
          content: detail,
          swot_strength_id: item.id,
        },
      };

      return data;
    }

    if (swotTitleDetail === "Weakness") {
      const data = {
        comment: {
          content: detail,
          swot_weakness_id: item.id,
        },
      };

      return data;
    }

    if (swotTitleDetail === "Opportunity") {
      const data = {
        comment: {
          content: detail,
          swot_opportunity_id: item.id,
        },
      };

      return data;
    }
    if (swotTitleDetail === "Threat") {
      const data = {
        comment: {
          content: detail,
          swot_threat_id: item.id,
        },
      };

      return data;
    }
  };

  const checkResponseData = (item, detail) => {
    if (swotTitleDetail === "Strength") {
      const data = {
        comment: {
          content: detail,
          swot_strength_comment_id: item,
        },
      };

      return data;
    }

    if (swotTitleDetail === "Weakness") {
      const data = {
        comment: {
          content: detail,
          swot_weakness_comment_id: item,
        },
      };

      return data;
    }

    if (swotTitleDetail === "Opportunity") {
      const data = {
        comment: {
          content: detail,
          swot_opportunity_comment_id: item,
        },
      };

      return data;
    }
    if (swotTitleDetail === "Threat") {
      const data = {
        comment: {
          content: detail,
          swot_threat_comment_id: item,
        },
      };

      return data;
    }
  };

  const handleCreateComment = async (e) => {
    e.preventDefault();

    const { id, project_swot_id } = item;
    setSubmitButton(true);
    let comment = checkData(item, commentBoxDetail);

    try {
      const { message } = await createNewSwotComment(
        projectID,
        project_swot_id,
        id,
        block,
        comment
      );
      if (message === "Comment created succesfully") {
        NotificationManager.success("New Comment", "Created successfully");
        console.log("its created");
        refreshCommentData(item);
        setCommentBoxDetail("");
        setSubmitButton(false);
      }
      return;
    } catch (error) {
      console.log(
        error,
        "There was an error creating the swot comment in projects"
      );
      NotificationManager.error("Error", "Failed to create new comment");
      setSubmitButton(false);
    }
  };

  const handleEditOfComment = async (editState, setEditState) => {
    const { itemId, content } = editState;
    const { id, project_swot_id } = item;
    let comment = checkData(item, content);

    try {
      const { message } = await editSwotComment(
        projectID,
        project_swot_id,
        id,
        block,
        comment,
        itemId
      );
      if (message === "Comment updated successfully") {
        NotificationManager.success("Comment edited successfully");
        refreshCommentData(item);
        setEditState({
          form: false,
          itemId: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "There was an error Editing the swot comment in projects"
      );
      NotificationManager.error("Error", "Failed to edit comment");
      setEditState({
        form: false,
        itemId: null,
        content: "",
      });
    }
  };

  const refreshSwotDetails = async () => {
    fetchSwotSpecificDetails(dynamicRoute);
  };

  const returnDataBaseOnTabSelection = (upvote) => {
    if (swotTitleDetail === "Strength") {
      const data = {
        upvoteable_id: upvote.id,
        upvoteable_type: "SwotStrengthComment",
      };
      return data;
    }

    if (swotTitleDetail === "Weakness") {
      const data = {
        upvoteable_id: upvote.id,
        upvoteable_type: "SwotWeaknessComment",
      };

      return data;
    }

    if (swotTitleDetail === "Opportunity") {
      const data = {
        upvoteable_id: upvote.id,
        upvoteable_type: "SwotOpportunityComment",
      };

      return data;
    }
    if (swotTitleDetail === "Threat") {
      const data = {
        upvoteable_id: upvote.id,
        upvoteable_type: "SwotThreatComment",
      };

      return data;
    }
  };

  const handleUpvoteOfComment = async (ID) => {
    let { upvoteable_id, upvoteable_type } = returnDataBaseOnTabSelection(ID);
    try {
      const { message } = await upvote(upvoteable_id, upvoteable_type);
      if (message === "upvote successful") {
        refreshCommentData(item);
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of swot comments"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfComment = async (ID) => {
    let { upvoteable_id, upvoteable_type } = returnDataBaseOnTabSelection(ID);

    try {
      const { message } = await downvote(upvoteable_id, upvoteable_type);
      if (message === "Your vote has been removed") {
        refreshCommentData(item);
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of  swot comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  const handleCreateOfCommentReponses = async (replyState, setReplyState) => {
    const { itemReplyId, replyContent } = replyState;
    const { id, project_swot_id } = item;

    let comment = checkResponseData(itemReplyId, replyContent);

    try {
      const { message } = await createSwotCommentResponses(
        projectID,
        project_swot_id,
        id,
        block,
        comment,
        itemReplyId
      );
      if (message === "Comment created succesfully") {
        NotificationManager.success("Response created!!!");
        refreshCommentData(item);
        setReplyState({
          display: true,
          itemReplyId: itemReplyId,
          replyContent: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to respond to comment");
      setReplyState({
        display: false,
        itemReplyId: null,
        replyContent: "",
      });
    }
  };

  const handleEditOfCommentResponses = async (
    editCommentResponse,
    setEditCommentResponses,
    replyState
  ) => {
    const { responseID, content } = editCommentResponse;
    const { itemReplyId } = replyState;
    const { id, project_swot_id } = item;
    let comment = checkResponseData(itemReplyId, content);

    try {
      const { message } = await editSwotCommentResponses(
        projectID,
        project_swot_id,
        id,
        block,
        comment,
        itemReplyId,
        responseID
      );
      if (message === "Comment updated successfully") {
        NotificationManager.success("Edited sucessfully!!!");
        refreshCommentData(item);
        setEditCommentResponses({
          form: false,
          responseID: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the edit of project assumption comments response"
      );
      NotificationManager.error("Error", "Failed to Edit");
      setEditCommentResponses({
        form: false,
        responseID: null,
        content: "",
      });
    }
  };

  const returnDataBaseOnTabSelectionReponses = (upvote) => {
    if (swotTitleDetail === "Strength") {
      const data = {
        upvoteable_id: upvote,
        upvoteable_type: "SwotStrengthCommentResponse",
      };
      return data;
    }

    if (swotTitleDetail === "Weakness") {
      const data = {
        upvoteable_id: upvote,
        upvoteable_type: "SwotWeaknessCommentResponse",
      };

      return data;
    }

    if (swotTitleDetail === "Opportunity") {
      const data = {
        upvoteable_id: upvote,
        upvoteable_type: "SwotOpportunityCommentResponse",
      };

      return data;
    }
    if (swotTitleDetail === "Threat") {
      const data = {
        upvoteable_id: upvote,
        upvoteable_type: "SwotThreatCommentResponse",
      };

      return data;
    }
  };

  const handleUpvoteOfCommentResponses = async (ID) => {
    let { upvoteable_id, upvoteable_type } =
      returnDataBaseOnTabSelectionReponses(ID);
    try {
      const { message } = await upvote(upvoteable_id, upvoteable_type);
      if (message === "upvote successful") {
        refreshCommentData(item);
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of swot comments"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfCommentResponses = async (ID) => {
    let { upvoteable_id, upvoteable_type } =
      returnDataBaseOnTabSelectionReponses(ID);

    try {
      const { message } = await downvote(upvoteable_id, upvoteable_type);
      if (message === "Your vote has been removed") {
        refreshCommentData(item);
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of  swot comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  const displayUpvotedProfiles = (profiles) => {
    if (profiles.length < 1) return null;

    const results = profiles.map((value) => {
      return (
        <Avatar
          key={value.profile.id}
          alt={`${value.profile.first_name} ${value.profile.last_name}`}
          src={`${value.profile.profile_avatar.url}`}
          className="sb-shared-icons-avatars"
        />
      );
    });

    return (
      <>
        <AvatarGroup max={2} onClick={() => handleShowProfiles(profiles)}>
          {results}
        </AvatarGroup>
      </>
    );
  };

  const displayList = () => {
    let name = `${swotTitleDetail}_name`.toLocaleLowerCase();
    const results = swotDetails.map((value, index) => {
      return (
        <div className="card list-card-container" key={value.id}>
          <div className="card-body list-card">
            <span className="list-number">{index + 1}</span>{" "}
            <div className="list-item-stength">
              {" "}
              {value[name] ? parse(value[name]) : null}
            </div>
            <div className="action_items_container">
              <div className="upvoted_profiles">
                {displayUpvotedProfiles(value.upvoted_by)}
              </div>

              {value.likes.upvoted ? (
                <IconButton
                  aria-label="upvote"
                  className="action_swot_item"
                  onClick={() => handleDislike(value)}
                  disabled={
                    value.id === upvoteBtns.id && upvoteBtns.clicked
                      ? true
                      : false
                  }
                >
                  <Badge badgeContent={value.likes.counter} color="secondary">
                    <FavoriteIcon
                      fontSize="large"
                      style={{
                        color: "red",
                      }}
                    />
                  </Badge>
                </IconButton>
              ) : (
                <IconButton
                  aria-label="upvote"
                  className="action_swot_item"
                  onClick={() => handleLike(value)}
                  disabled={
                    value.id === upvoteBtns.id && upvoteBtns.clicked
                      ? true
                      : false
                  }
                >
                  <Badge badgeContent={value.likes.counter} color="secondary">
                    <FavoriteBorderIcon
                      fontSize="large"
                      style={{
                        color: "black",
                      }}
                    />
                  </Badge>
                </IconButton>
              )}

              <IconButton
                aria-label="comments"
                className="action_swot_item"
                onClick={() => {
                  setItem(value);
                  handleOpenCommentBox(value);
                }}
              >
                <Badge badgeContent={value.comments.length} color="secondary">
                  <MessageOutlinedIcon
                    fontSize="large"
                    style={{
                      color: "black",
                    }}
                  />
                </Badge>
              </IconButton>
              <div
                className="edit-button-list"
                onClick={() => {
                  setEditState({
                    editModal: true,
                    editname: value[name],
                    detailID: value.id,
                  });
                }}
              >
                <i className="fa fa-pencil-square" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return results;
  };

  return (
    <>
      <AppProfilesList
        open={profileList.openModal}
        handleClose={handleCloseProfileList}
        profiles={profileList.profiles}
      />
      <CommentBox
        open={commentModal}
        setOpen={setCommentModal}
        comments={comments}
        handleCreateComment={handleCreateComment}
        detail={commentBoxDetail}
        setCommentBoxDetail={setCommentBoxDetail}
        submitButton={submitButton}
        setSubmitButton={setSubmitButton}
        label={swotTitleDetail}
        labelDetails={labelDetails}
        idea={""}
        purpose={""}
        handleEditOfComment={handleEditOfComment}
        editSubmitButton={editSubmitButton}
        refreshGoals={refreshSwotDetails}
        handleUpvoteOfComment={handleUpvoteOfComment}
        handleUnvoteOfComment={handleUnvoteOfComment}
        handleCreateOfCommentReponses={handleCreateOfCommentReponses}
        handleEditOfCommentReponses={handleEditOfCommentResponses}
        handleUpvoteOfCommentResponses={handleUpvoteOfCommentResponses}
        handleUnvoteOfCommentResponses={handleUnvoteOfCommentResponses}
        commentBoxIdea={""}
        commentBoxPurpose={""}
        handleCreateOfCommentReplyOfReply={() => console.log("reply of reply")}
      />
      <EditSwotModal
        show={editState.editModal}
        onHide={() =>
          setEditState({
            editModal: false,
            editname: "",
            detailID: null,
          })
        }
        editname={editState.editname}
        detailID={editState.detailID}
        projectID={projectID}
        swotID={swotID}
        dynamicRoute={dynamicRoute}
        swotTitleDetail={swotTitleDetail}
        fetchSwotSpecificDetails={fetchSwotSpecificDetails}
        editState={editState}
        setEditState={setEditState}
      />
      <div className="container-fluid">
        <div className="back-btn-createNew">
          <div className="add-to-the-list">
            <Button
              variant="contained"
              onClick={() => setShowCreateModal(true)}
              className="mt-3 mb-3"
              startIcon={<AddBoxIcon />}
              size="large"
              color="secondary"
            >
              Add New
            </Button>
          </div>
          <PsychologyAltIcon
            className={state ? "question-bmc-active" : "question-bmc"}
            onClick={() => setState(!state)}
          />
        </div>
        {state && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            {tabDetails}
          </Alert>
        )}
        {displayList()}
      </div>
    </>
  );
}

export default SwotDetailsView;
