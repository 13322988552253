import { BASEURL } from "../../API/apiData";

const addCollaborators = async (data) => {
  const { project_id, collaboratorsData } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(collaboratorsData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/collaborations`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export { addCollaborators };
