import React, { useState, Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Divider } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CountryDropdown } from "react-country-region-selector";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditProfile = ({ isOpen, closeModal, currentUser, updateProfile }) => {
  const [state, setState] = useState({
    facebook_url: currentUser.facebook_url,
    instagram_url: currentUser.instagram_url,
    twitter_url: currentUser.twitter_url,
    role: currentUser.role,
    vision: currentUser.vision,
    mission: currentUser.mission,
    address: currentUser.address,
    contact_no: currentUser.contact_no,
    country: currentUser.country,
    region: currentUser.region,
  });
  const [profileAvatar, setProfileAvatar] = useState(null);
  const [privacy, setPrivacy] = useState(currentUser.is_private);
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("lg");

  const handleFormSubmit = (e) => {
    const profileData = new FormData();
    profileData.append("profile[first_name]", currentUser.first_name);
    profileData.append("profile[last_name]", currentUser.last_name);
    profileData.append("profile[facebook_url]", state.facebook_url);
    profileData.append("profile[instagram_url]", state.instagram_url);
    profileData.append("profile[twitter_url]", state.twitter_url);
    profileData.append("profile[role]", state.role);
    profileData.append("profile[vision]", state.vision);
    profileData.append("profile[mission]", state.mission);
    profileData.append("profile[address]", state.address);
    profileData.append("profile[country]", state.country);
    profileData.append("profile[region]", state.region);
    profileData.append("profile[contact_no]", state.contact_no);
    profileData.append("profile[is_private]", privacy);
    if (profileAvatar !== null) {
      profileData.append("profile[profile_avatar]", profileAvatar);
    }
    updateProfile(currentUser.id, profileData);
    closeModal();
    e.preventDefault();
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeModal}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "800",
              color: "purple",
              textTransform: "uppercase",
            }}
          >
            Edit Profile
          </span>

          <Button
            sx={{
              position: "absolute",
              right: "10px",
            }}
            startIcon={<CloseIcon />}
            onClick={closeModal}
            variant="contained"
            size="small"
            color="error"
          >
            Close
          </Button>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <form>
            <Fragment>
              <div
                className="add-image-projects"
                style={{
                  marginBottom: "20px",
                }}
              >
                <label htmlFor="profile_avatar">Upload Avatar:</label>
                <input
                  type="file"
                  className="form-control border-0"
                  name="profile_avatar"
                  onChange={(event) => setProfileAvatar(event.target.files[0])}
                />
              </div>

              <div
                className="form-row"
                style={{
                  marginBottom: "20px",
                }}
              >
                <div className="col">
                  <label
                    style={{
                      fontWeight: "800",
                      fontSize: "18px",
                    }}
                    htmlFor="role"
                  >
                    <FacebookIcon
                      style={{
                        color: "blue",
                      }}
                      fontSize="large"
                    />{" "}
                    Facebook url:
                  </label>
                  <input
                    pattern="https://.*"
                    type="url"
                    className="form-control"
                    placeholder="Facebook Link"
                    defaultValue={currentUser.facebook_url}
                    onChange={(event) =>
                      setState({
                        ...state,
                        facebook_url: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="col">
                  <label
                    style={{
                      fontWeight: "800",
                      fontSize: "18px",
                    }}
                    htmlFor="role"
                  >
                    <InstagramIcon
                      style={{
                        color: "blue",
                      }}
                      fontSize="large"
                    />
                    instagram url:
                  </label>
                  <input
                    defaultValue={currentUser.instagram_url}
                    pattern="https://.*"
                    type="url"
                    className="form-control"
                    placeholder="Instagram Link"
                    onChange={(event) =>
                      setState({
                        ...state,
                        instagram_url: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="col">
                  <label
                    style={{
                      fontWeight: "800",
                      fontSize: "18px",
                    }}
                    htmlFor="role"
                  >
                    <TwitterIcon
                      style={{
                        color: "blue",
                      }}
                      fontSize="large"
                    />{" "}
                    twitter url:
                  </label>
                  <input
                    defaultValue={currentUser.twitter_url}
                    pattern="https://.*"
                    type="url"
                    className="form-control"
                    placeholder="Twitter Link"
                    onChange={(event) =>
                      setState({
                        ...state,
                        twitter_url: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="form-group mt-2 mb-2">
                <label
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                  }}
                  htmlFor="role"
                >
                  Role:
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="role"
                  defaultValue={currentUser.role}
                  onChange={(event) =>
                    setState({
                      ...state,
                      role: event.target.value,
                    })
                  }
                />
              </div>

              <div className="form-group mt-2 mb-2">
                <label
                  htmlFor="vision"
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                  }}
                >
                  Vision:
                </label>

                <CKEditor
                  className="form-control"
                  editor={ClassEditor}
                  data={currentUser.vision}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setState({
                      ...state,
                      vision: data,
                    });
                  }}
                />
              </div>

              <div className="form-group mt-2 mb-2">
                <label
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                  }}
                  htmlFor="mission"
                >
                  Mission:
                </label>

                <CKEditor
                  className="form-control"
                  editor={ClassEditor}
                  data={currentUser.vision}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setState({
                      ...state,
                      mission: data,
                    });
                  }}
                />
              </div>

              <div className="form-group mt-2 mb-2">
                <label
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                  }}
                  htmlFor="address"
                >
                  Address:
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="address"
                  defaultValue={currentUser.address}
                  onChange={(event) =>
                    setState({
                      ...state,
                      address: event.target.value,
                    })
                  }
                />
              </div>

              <div className="form-group mt-2 mb-2">
                <label
                  htmlFor="contact_no"
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                  }}
                >
                  contact Number:
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="contact_no"
                  defaultValue={currentUser.contact_no}
                  onChange={(event) =>
                    setState({
                      ...state,
                      contact_no: event.target.value,
                    })
                  }
                />
              </div>

              <div className="form-group mt-2 mb-2">
                <label
                  htmlFor="contact_no"
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                  }}
                >
                  Country:
                </label>
                <CountryDropdown
                  selected=""
                  className="form-control"
                  value={state.country}
                  onChange={(val) => setState({ ...state, country: val })}
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="privacy"
                  style={{
                    fontWeight: "800",
                    fontSize: "18px",
                  }}
                >
                  Do you want to your profile to be private?
                </label>
                &nbsp;&nbsp;
                <input
                  type="checkbox"
                  name="privacy"
                  defaultChecked={currentUser.is_private}
                  onChange={(e) => setPrivacy(e.target.checked)}
                />
              </div>
            </Fragment>
          </form>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleFormSubmit}
            variant="contained"
            size="medium"
            color="secondary"
            startIcon={<EditIcon />}
            // disabled={editProjectMutation.isLoading && true}
          >
            Edit profile
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditProfile;
