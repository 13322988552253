import { BASEURL } from "../../API/apiData";
import axios from "axios";

const fetchAllGoals = async (projectID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_goals`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewProjectGoal = async (goalData) => {
  const token = localStorage.getItem("token");
  const { id, data } = goalData;
  try {
    const result = await axios.post(
      `${BASEURL}projects/${id}/project_goals`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

const editProjectGoal = async (goalData) => {
  const token = localStorage.getItem("token");
  const { project_id, data, id } = goalData;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const results = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${id}`,
    requestOptions
  );
  const response = await results.json();
  return response;
};

const upvoteProjectGoal = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}upvotes`, requestOptions);
  const results = await response.json();
  return results;
};

const unvoteProjectGoal = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}unvote`, requestOptions);
  const results = await response.json();
  return results;
};

const createGoalComment = async (data) => {
  const { project_id, project_goal_id, commentData } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${project_goal_id}/project_goal_comments`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const fetchAllGoalComments = async (data) => {
  const { project_id, project_goal_id } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${project_goal_id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editCommentProjectGoal = async (data) => {
  const { project_id, project_goal_id, id, commentData } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${project_goal_id}/project_goal_comments/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createProjectGoalCommentResponses = async (data) => {
  const { project_id, project_goal_id, project_goal_comment_id, commentData } =
    data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${project_goal_id}/project_goal_comments/${project_goal_comment_id}/project_goal_comment_responses`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editProjectGoalCommentResponses = async (data) => {
  const {
    project_id,
    project_goal_id,
    project_goal_comment_id,
    commentData,
    id,
  } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}/projects/${project_id}/project_goals/${project_goal_id}/project_goal_comments/${project_goal_comment_id}/project_goal_comment_responses/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const fetchAllProjectKPI = async (projectID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/all_kpis`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewReplyOfReplyGoals = async (data) => {
  const token = localStorage.getItem("token");
  const {
    projectID,
    goalID,
    projectCommentID,
    projectCommentResponseID,
    commentReplyOfReply,
  } = data;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentReplyOfReply),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_goals/${goalID}/project_goal_comments/${projectCommentID}/project_goal_comment_responses/${projectCommentResponseID}/project_goal_comment_reponse_of_responses`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editReplyOfReplyCommentGoals = async (data) => {
  const token = localStorage.getItem("token");
  const {
    projectID,
    goalID,
    projectCommentID,
    projectCommentResponseID,
    ID,
    commentReplyOfReplyEdit,
  } = data;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentReplyOfReplyEdit),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_goals/${goalID}/project_goal_comments/${projectCommentID}/project_goal_comment_responses/${projectCommentResponseID}/project_goal_comment_reponse_of_responses/${ID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export {
  upvoteProjectGoal,
  unvoteProjectGoal,
  fetchAllGoals,
  createNewProjectGoal,
  editProjectGoal,
  createGoalComment,
  fetchAllGoalComments,
  editCommentProjectGoal,
  createProjectGoalCommentResponses,
  editProjectGoalCommentResponses,
  fetchAllProjectKPI,
  createNewReplyOfReplyGoals,
  editReplyOfReplyCommentGoals,
};
