import React, { useState } from "react";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";
// ---- material ui ----
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { Button, Divider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// ---- assets ----
import projectImage from "../../../assets/nocorevalues.png";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const NetworkCard = ({ data, setNetworkID, setAddProjectsToNetworkModal }) => {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToShowPage = (id) => {
    history.push(`/networks/${id}`);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const menuItems = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>More actions coming soon...</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>More actions coming soon...</MenuItem>
      </Menu>
    );
  };

  return (
    <Card
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      }}
    >
      <CardHeader
        sx={{
          backgroundColor: "#f7f7f7",
          color: "#000",
          fontWeight: "700",
        }}
        avatar={
          data.creator.profile_avatar.url === null ? (
            <Avatar
              sx={{ bgcolor: red[500] }}
              aria-label="recipe"
              alt={`${data.creator.first_name}`}
            />
          ) : (
            <Avatar
              alt={`${data.creator.first_name} ${data.creator.last_name}`}
              src={`${data.creator.profile_avatar.url}`}
            />
          )
        }
        action={
          <IconButton aria-label="more_project_actions">
            <Tooltip title="Project Actions">
              <MoreVertIcon
                onClick={handleClick}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              />
            </Tooltip>
          </IconButton>
        }
        title="Creator:"
        subheader={`${data.creator.first_name} ${data.creator.last_name}`}
      />
      <Divider />
      <CardMedia
        component="img"
        height="150"
        image={projectImage}
        alt="Project Image"
        sx={{
          objectFit: "contain",
          objectPosition: "center",
          marginTop: "20px",
        }}
      />
      {menuItems()}
      <CardContent
        sx={{
          minHeight: "100px",
          textAlign: "center",
          textTransform: "uppercase",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Typography
          variant="h3"
          color="text.secondary"
          sx={{
            color: "#000",
            fontSize: "20px",
            fontWeight: "700",
          }}
        >
          <ReadMoreReusable>{data.name}</ReadMoreReusable>
          <div
            style={{
              textTransform: "uppercase",
              fontWeight: 800,
              color: "purple",
              marginTop: "14px",
              fontSize: "14px",
            }}
          >
            <span
              style={{
                color: "purple",
                fontWeight: 800,
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              {" "}
              ({data.projects_count})
            </span>
            Projects{" "}
          </div>
        </Typography>
      </CardContent>
      <Divider />
      <CardActions
        disableSpacing
        sx={{
          backgroundColor: "#f7f7f7",
        }}
      >
        <Button
          variant="contained"
          size="small"
          color="primary"
          sx={{
            marginLeft: "8px",
          }}
          onClick={() => {
            setNetworkID(data.id);
            setAddProjectsToNetworkModal(true);
          }}
        >
          Add Projects
        </Button>

        <Button
          variant="contained"
          size="small"
          color="secondary"
          sx={{
            marginLeft: "8px",
          }}
          onClick={() => navigateToShowPage(data.id)}
        >
          Enter
        </Button>

        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              fontWeight: 700,
              textTransform: "uppercase",
              color: "purple",
              marginTop: "10px",
              marginBottom: "20px",
              textDecoration: "underline",
            }}
          >
            <TipsAndUpdatesIcon
              sx={{
                marginRight: "10px",
              }}
            />
            Network Idea:
          </div>
          {parse(data.idea)}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default NetworkCard;
