import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { UseCreateNewProjectAssumption } from "../../../hooks/project/singleProject";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";

function CreateAssumption({ isOpen, closeModal, projectID }) {
  //  ==== FORM CONTROL STATE ====
  const [formState, setFormState] = useState({
    assumption: "",
    idea: "",
    purpose: "",
  });

  const resetAssumptionForm = () => {
    setFormState({
      assumption: "",
      idea: "",
      purpose: "",
    });

    closeModal();
  };

  // --- RQ CREATE NEW ASSUMPTION MUTATATION
  const CreateAssumptionMutation =
    UseCreateNewProjectAssumption(resetAssumptionForm);

  //  ==== SUBMIT THE FORM ====
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      projectID,
      assumptionData: {
        ...formState,
      },
    };

    CreateAssumptionMutation.mutate(data);
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#047C7C" }}
        closeButton
      >
        <Modal.Title>Add Assumption</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {CreateAssumptionMutation.isError && (
          <Alert variant="filled" severity="error">
            Something went wrong while creating a new assumptions. Kindly try
            again
          </Alert>
        )}

        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="need">Assumption</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formState.assumption}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, assumption: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="idea">Idea</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formState.idea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, idea: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="purpose">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formState.purpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, purpose: data });
              }}
            />
          </div>
          <div className="form-group float-right">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={CreateAssumptionMutation.isLoading ? true : false}
            >
              {" "}
              create assumption
            </Button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateAssumption;
