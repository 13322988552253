/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import ProjectTopTenEditModal from "./ProjectTopTenEditModal";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";

import parse from "html-react-parser";
import { UseDeleteProjectTop10 } from "../../../hooks/project/singleProject";

const ProjectTopTenCard = ({ pId, id, title, description, entries, setId }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [alertNotice, setAlertNotice] = useState(false);

  const openEditModal = () => {
    setOpenEdit(true);
  };

  const closeEditModal = () => {
    setOpenEdit(false);
  };

  const deleteProjectTop10Mutation = UseDeleteProjectTop10(() =>
    setAlertNotice(false)
  );

  const handleTop10Delete = async () => {
    const data = {
      pId,
      id,
    };
    deleteProjectTop10Mutation.mutate(data);
  };

  const showEdit = () => {
    if (openEdit) {
      return (
        <ProjectTopTenEditModal
          isOpen={openEdit}
          closeEditModal={closeEditModal}
          name={title}
          desc={description}
          pId={pId}
          ttId={id}
        />
      );
    }
  };

  const showAlertModal = () => {
    if (alertNotice) {
      return (
        <React.Fragment>
          <Dialog
            open={alertNotice}
            onClose={() => setAlertNotice(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Alert!!!</DialogTitle>
            {deleteProjectTop10Mutation.isError && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Alert variant="filled" severity="error">
                  Something went wrong while deleting the project top10
                </Alert>
              </div>
            )}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this Top10?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => handleTop10Delete()}
                variant="contained"
                color="error"
                disabled={deleteProjectTop10Mutation.isLoading ? true : false}
              >
                {deleteProjectTop10Mutation.isLoading
                  ? "Deleting top10..."
                  : "Delete top10"}
              </Button>
              <Button
                onClick={() => setAlertNotice(false)}
                variant="outlined"
                color="secondary"
              >
                {" "}
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    }
  };

  return (
    <>
      {showEdit()}
      {showAlertModal()}
      <div className="project-top-ten-card shadow-lg">
        <div className="project-top-ten-card-header">
          <h5 className="project-top-ten-card-header-text">{parse(title)}</h5>
        </div>
        <hr className="header-split" />
        <div className="project-top-ten-card-list">
          <div className="col w-50 position-well-items">
            {entries
              .filter((item, idx) => idx < 5)
              .map((i, ix) => (
                <h6 key={i.id} className="card-list-item">
                  {ix + 1}. {i.name}
                </h6>
              ))}
          </div>
          <div className="col w-50 position-well-items">
            {entries
              .filter((item, idx) => idx > 4 && idx <= 10)
              .map((i, ix) => (
                <h6 key={i.id} className="card-list-item">
                  {ix + 6}. {i.name}
                </h6>
              ))}
          </div>
        </div>
        <div className="project-top-ten-card-buttons">
          <button
            className="ptt-exp-btn"
            type="button"
            onClick={() => setId(id)}
          >
            Explore
          </button>

          <button
            className="ptt-edit-btn"
            type="button"
            onClick={openEditModal}
          >
            Edit
          </button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setAlertNotice(true)}
          >
            Delete
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProjectTopTenCard;
