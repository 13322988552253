import React from "react";
import DesktopDisplay from "./DesktopDisplay";
import MobileDisplay from "./MobileDisplay";
import AppSearchBar from "./reusableComponents/AppSearchBar";
import styles from "./css/styles.module.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";

const MainDisplay = (props) => {
  const { setCreateNetworkModal, searchValue, setSearchValue, networkData } =
    props;
  return (
    <div className={styles.main_container}>
      <div className={`${styles.search_container} container`}>
        <AppSearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          networks={networkData}
        />
        <Button
          onClick={() => setCreateNetworkModal(true)}
          startIcon={<AddCircleIcon />}
          color="secondary"
          size="large"
          variant="contained"
          style={{
            display: "inline-flex",
            marginLeft: "20px",
          }}
        >
          {" "}
          Network{" "}
        </Button>
      </div>
      <DesktopDisplay props={props} />
      <MobileDisplay props={props} />
    </div>
  );
};

export default MainDisplay;
