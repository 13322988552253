import React, { useState } from "react";
import nocores from "../../assets/nocorevalues.png";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import ProjectCard from "../All_Projects/ReusableComponents/ProjectCard";
import {
  UseCreateFollow,
  UseCreateNewProject,
  UseDeleteFollow,
  UseFetchAllProjectCategories,
  UseFetchSDGs,
} from "../../hooks/project/allProjects";
import ProjectFormModal from "../All_Projects/ReusableComponents/ProjectFormModal";

const ProfileProjects = ({ projects, privacy, handlRefreshProjects }) => {
  const history = useHistory();
  const [openProjectMoal, setOpenProjectModal] = useState(false);

  const showProjectModal = () => {
    setOpenProjectModal(!openProjectMoal);
  };
  // --- RQ (MUTATIONS)
  const {
    data: sdgsData,
    error: sdgError,
    isLoading: sdgLoading,
  } = UseFetchSDGs();

  const {
    data: projectCategories,
    error: projectCategoriesError,
    isLoading: projectCategoriesIsLoading,
  } = UseFetchAllProjectCategories();

  const handleProjectCreatedSuccesFully = () => {
    handlRefreshProjects();
    setOpenProjectModal(false);
  };

  // --- MUTATION ---
  const followAction = UseCreateFollow();
  const unFollowAction = UseDeleteFollow();
  const ProjectCreationMutation = UseCreateNewProject(
    handleProjectCreatedSuccesFully
  );

  const handleFollowAction = (projectID) => {
    followAction.mutate(projectID);
  };
  const handleUnfollowAction = (projectID) => {
    unFollowAction.mutate(projectID);
  };
  const navigateTo = (project) => {
    history.push(`/projects/${project}`);
  };

  // ---- CREATE A NEW PROJECT ----
  const createNewProject = async (projectData) => {
    ProjectCreationMutation.mutate(projectData);
  };

  return (
    <>
      {openProjectMoal ? (
        <ProjectFormModal
          open={openProjectMoal}
          handleClose={() => setOpenProjectModal(false)}
          categories={projectCategories}
          createNewProject={createNewProject}
          sdgs={sdgsData}
          sdgLoading={sdgLoading}
          sdgError={sdgError}
          projectCategoriesError={projectCategoriesError}
          projectCategoriesIsLoading={projectCategoriesIsLoading}
          ProjectCreationMutation={ProjectCreationMutation}
        />
      ) : null}
      <div className="profile-core-values">
        <div
          style={{
            display: "flex",
          }}
        >
          <h5
            style={{
              textTransform: "uppercase",
              fontWeight: "800",
              margin: "10px",
              flex: "1",
            }}
          >
            Projects ({projects.length})
          </h5>
          {privacy ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={showProjectModal}
              size="small"
            >
              Create Project
            </Button>
          ) : null}
        </div>
      </div>
      <div>
        <div className="container-fluid">
          {projects.length === 0 ? (
            <div className="w-100 text-center">
              <img className="no-cores-thumbnail" src={nocores} alt="no-cv" />
              <h4 className="text-success">No projects available</h4>
            </div>
          ) : (
            <div className="row">
              {projects.map((project) => (
                <div
                  className="col-md-3"
                  key={project.id}
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <ProjectCard
                    project={project}
                    handleFollowAction={handleFollowAction}
                    handleUnfollowAction={handleUnfollowAction}
                    navigateTo={navigateTo}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileProjects;
