import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./singleProjectNetwork.module.css";
import VpnLockTwoToneIcon from "@mui/icons-material/VpnLockTwoTone";
import Button from "@mui/material/Button";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
// import AddCircleIcon from "@mui/icons-material/AddCircle";

const SingleProjectNetwork = ({ project }) => {
  const history = useHistory();
  const { networks } = project;

  const navigateToNetworks = () => {
    history.push(`/networks`);
  };

  const displayNetworks = () => {
    const result = networks.map((value) => {
      return (
        <TimelineItem key={value.id}>
          <TimelineSeparator>
            <TimelineDot color="secondary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Link
              color="inherit"
              sx={{
                textTransform: "uppercase",
              }}
              href={`/networks/${value.id}`}
            >
              {value.name}
            </Link>
          </TimelineContent>
        </TimelineItem>
      );
    });
    return result;
  };
  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <VpnLockTwoToneIcon /> <h6> Linked Networks</h6>
        {/* <div
          style={{
            textAlign: "right",
            position: "absolute",
            right: "0",
          }}
        >
          <Button
            startIcon={<AddCircleIcon />}
            variant="contained"
            color="secondary"
            size="small"
            sx={{
              textAlign: "right",
            }}
          >
            Network
          </Button>
        </div> */}
      </div>
      <div className={styles.container_body}>
        {networks.length < 1 ? (
          <Alert severity="info">
            This project is not linked to any networks
          </Alert>
        ) : (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {displayNetworks()}
          </Timeline>
        )}
      </div>

      <div className={styles.container_footer}>
        <Button
          variant="contained"
          color="secondary"
          onClick={navigateToNetworks}
        >
          All Networks
        </Button>
      </div>
    </div>
  );
};

export default SingleProjectNetwork;
