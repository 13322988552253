import React from "react";
import ProjectTopTenCard from "./ProjectTopTenCard";

const ProjectTop10All = ({ pId, topTens, setId }) => {
  const showData = () => {
    if (topTens.length === 0) {
      return <h2 className="text-center text-success">No Top10's available</h2>;
    } else {
      return (
        <>
          {topTens.map((t) => (
            <ProjectTopTenCard
              key={t.id}
              pId={pId}
              id={t.id}
              title={t.title}
              description={t.description}
              entries={t.entries}
              setId={setId}
            />
          ))}
        </>
      );
    }
  };
  return <div className="project-top-ten-main">{showData()}</div>;
};

export default ProjectTop10All;
