/* eslint-disable */
import React, { useState } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { NotificationManager } from "react-notifications";
import {
  createProjectSuggestionComment,
  updateProjectSuggestion,
} from "../../../redux";
import "../../../stylesheets/projectSuggestion.css";
import ProjectSuggestionModal from "./ProjectSuggestionModal";
import _, { result } from "lodash";
import AppReusableCard from "../globals/AppReusableCard";
import ProjectSuggestionEditModal from "./ProjectSuggestionEditModal";
import {
  createProjectSuggestionCommentResponses,
  editProjectSuggestionComment,
  editProjectSuggestionCommentResponses,
  getOneSuggestion,
} from "../../../services/projects/ProjectSuggestionService";
import AppReusableDisplayUpvotedProfiles from "../globals/AppReusableDisplayUpvotedProfiles";

import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CommentBox from "../globals/CommentBox";
import {
  unvoteProjectAssumption,
  upvoteProjectAssumption,
} from "../../../services/projects/ProjectAssumptionService";
import {
  UseCreateNewSuggestion,
  UseCreateProjectSuggestionReplyOfReply,
  UseDownVoteProjectSuggestion,
  UseEditProjectSuggestionReplyOfReply,
  UseUnvoteOfProjectSuggestionResponseOfResponse,
  UseUpvoteOfProjectSuggestion,
  UseUpvoteOfProjectSuggestionResponseOfResponse,
} from "../../../hooks/project/singleProject";

const ProjectSuggestion = ({
  id,
  suggestions,
  suggestionError,
  suggestionIsLoading,
  createComment,
  projectID,
}) => {
  const [open, setOpen] = useState(false);
  const [suggestionEdit, setSuggestionEdit] = useState({
    id: null,
    idea: "",
    purpose: "",
    details: "",
  });
  const [openEdit, setOpenEdit] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  // ==== OPEN UPVOTES PROFILES ====
  const [votersToggleDrawer, setVotersToggleDrawer] = useState(false);
  const [votersProfiles, setVotersProfiles] = useState([]);
  const [drawerTitle, setDrawerTitle] = useState("");
  // ==== DISPLAY THE SUGGESTION CONDITIONALLY ====
  const [commentBoxDetail, setCommentBoxDetail] = useState("");
  const [submitButton, setSubmitButton] = useState(false);
  const [suggestionSelection, setSuggestionSelection] = useState({
    idea: "",
    purpose: "",
    suggestion: "",
  });
  const [editSubmitButton, setEditSubmitButton] = useState(false);
  const [suggestionID, setSuggestionID] = useState(null);
  const [suggestionComments, setSuggestionsComments] = useState([]);
  const [likeButton, setLikeButton] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const closeEditModal = () => {
    setOpenEdit(false);
  };

  // --- RQ (MUTATIONS)
  const createNewsuggestionMutation = UseCreateNewSuggestion(closeModal);
  const handleCreate = (data) => {
    const suggData = {
      pId: id,
      suggestionData: data,
    };
    createNewsuggestionMutation.mutate(suggData);
  };

  const upvoteSuggestionMutation = UseUpvoteOfProjectSuggestion();
  const handleSuggestionUpvote = async (sug) => {
    const data = {
      upvote: {
        upvoteable_id: sug.id,
        upvoteable_type: "ProjectSuggestion",
      },
    };
    upvoteSuggestionMutation.mutate(data);
  };

  const downVoteSuggestionMutation = UseDownVoteProjectSuggestion();
  const handleSuggestionUnvote = async (sug) => {
    const data = {
      upvoteable_id: sug.id,
      upvoteable_type: "ProjectSuggestion",
    };
    downVoteSuggestionMutation.mutate(data);
  };

  const refreshSuggestionComments = async () => {
    const refreshData = {
      suggestion_id: suggestionID,
    };
    try {
      const res = await getOneSuggestion(refreshData);
      setSuggestionsComments([...res.result.comments]);
    } catch (error) {
      console.log(error, "Failed to refresh the project suggestion comments");
    }
  };

  const handleCreateComment = () => {
    setSubmitButton(true);
    const data = {
      project_suggestion_comment: {
        comment_text: commentBoxDetail,
        project_suggestion_id: suggestionID,
      },
    };

    createComment(id, suggestionID, data)
      .then((res) => {
        toast.success(res.payload.message);
        refreshSuggestionComments();
        setCommentBoxDetail("");
        setSubmitButton(false);
      })
      .catch((e) => {
        console.log(e, "Failed to create a comment for the project suggestion");
        NotificationManager.error("Failed to create comment. Kindly try again");
      });
  };

  const handleOpenCommentModal = (id, comments, data) => {
    setSuggestionsComments([...comments]);
    setSuggestionSelection({
      idea: data.idea,
      purpose: data.purpose,
      suggestion: data.details,
    });
    setSuggestionID(id);
    setCommentModal(true);
  };

  const handleSugEdit = (value) => {
    setSuggestionEdit({
      id: value.id,
      idea: value.idea,
      purpose: value.purpose,
      details: value.details,
    });
    setOpenEdit(true);
  };

  const showSuggestions = () => {
    if (_.isEmpty(suggestions)) {
      return (
        <h2 className="text-center text-success">No suggestions available</h2>
      );
    } else if (suggestions.length > 0) {
      return (
        <>
          {suggestions.map((sug) => (
            <AppReusableCard
              id={sug.id}
              key={sug.id}
              idea={sug.idea}
              purpose={sug.purpose}
              inputField={sug.details}
              inputFieldName="Suggestion"
              suggestion={sug}
              handleCreateComment={handleCreateComment}
              openCommentModal={handleOpenCommentModal}
              creatorImage={sug.avatar}
              creatorFirstName={sug.created_by.first_name}
              creatorLastName={sug.created_by.last_name}
              createdAt={sug.created_at}
              editAction={() => handleSugEdit(sug)}
              actionItems={true}
              upvoted_by={sug.upvoted_by}
              shared_with={sug.shared_with}
              votes={sug.likes.counter}
              userLiked={sug.likes.upvoted}
              likeAction={() => handleSuggestionUpvote(sug)}
              unLikeAction={() => handleSuggestionUnvote(sug)}
              setVotersToggleDrawer={setVotersToggleDrawer}
              setVotersProfiles={setVotersProfiles}
              setDrawerTitle={setDrawerTitle}
              openShareModal={() => handleOpenShareModal(sug)}
              comments={sug.comments}
              likeButton={likeButton}
              data={sug}
            />
          ))}
        </>
      );
    }
  };

  // ==== COMMENTS AND UPVOTES METHODS ====
  const refreshSuggestions = () => {
    getAllSuggestions();
  };

  const handleEditOfComment = async (editState, setEditState) => {
    const { content, itemId } = editState;
    const data = {
      projectID: id,
      suggestionID,
      ID: itemId,
      commentData: {
        comment_text: content,
        project_suggestion_id: suggestionID,
      },
    };

    try {
      const { message } = await editProjectSuggestionComment(data);
      if (message === "Comment updated successfully") {
        refreshSuggestionComments();
        setEditState({
          content: "",
          itemId: null,
          form: false,
        });
      }
    } catch (error) {
      console.log(
        error,
        "Something went wrong while tring to edit the project suggestion comment"
      );
    }
  };

  const handleUpvoteOfComment = async (item) => {
    const data = {
      upvote: {
        upvoteable_id: item.id,
        upvoteable_type: "ProjectSuggestionComment",
      },
    };
    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        NotificationManager.success("Comment liked");
        refreshSuggestionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project suggestion comment"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfComment = async (item) => {
    const data = {
      upvoteable_id: item.id,
      upvoteable_type: "ProjectSuggestionComment",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        NotificationManager.success("You have unvoted this comment");
        refreshSuggestionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project Suggestion comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  const handleCreateOfCommentResponses = async (replyState, setReplyState) => {
    const { itemReplyId, replyContent } = replyState;
    const data = {
      project_id: id,
      project_suggestion_id: suggestionID,
      project_suggestion_comment_id: itemReplyId,
      commentData: {
        project_suggestion_comment_response: {
          project_suggestion_comment_id: itemReplyId,
          content: replyContent,
        },
      },
    };
    try {
      const { message } = await createProjectSuggestionCommentResponses(data);
      if (message === "Your comment added") {
        NotificationManager.success("Response created!!!");
        refreshSuggestionComments();
        setReplyState({
          display: false,
          itemReplyId: itemReplyId,
          replyContent: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to respond to comment");
      setReplyState({
        display: false,
        itemReplyId: null,
        replyContent: "",
      });
    }
  };

  const handleEditOfCommentResponses = async (
    editCommentResponse,
    setEditCommentResponses,
    replyState
  ) => {
    const { responseID, content } = editCommentResponse;
    const { itemReplyId } = replyState;
    const data = {
      project_id: id,
      project_suggestion_id: suggestionID,
      project_suggestion_comment_id: itemReplyId,
      id: responseID,
      commentData: {
        project_suggestion_comment_response: {
          project_suggestion_comment_id: itemReplyId,
          content: content,
        },
      },
    };

    try {
      const { message } = await editProjectSuggestionCommentResponses(data);
      if (message === "Comment updated successfully") {
        NotificationManager.success("Edited sucessfully!!!");
        refreshSuggestionComments();
        setEditCommentResponses({
          form: false,
          responseID: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the edit of project suggestion comments response"
      );
      NotificationManager.error("Error", "Failed to Edit");
      setEditCommentResponses({
        form: false,
        responseID: null,
        content: "",
      });
    }
  };

  const handleUpvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectSuggestionCommentResponse",
      },
    };

    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        NotificationManager.success("Comment liked");
        refreshSuggestionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project assumption comments response"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectSuggestionCommentResponse",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        NotificationManager.success("You have unvoted this comment");
        refreshSuggestionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  const createSuggestionReplyOfReplyMutation =
    UseCreateProjectSuggestionReplyOfReply(refreshSuggestionComments);

  const handleCreateOfCommentReplyOfReply = async (data, commentID, state) => {
    const replyOfReplyData = {
      projectID: projectID,
      suggestionID: suggestionID,
      projectSuggestionCommentID: commentID,
      projectSuggestionCommentReplyID: data.itemReplyId,
      commentReplyOfReply: {
        comment: {
          response_text: data.replyContent,
        },
      },
    };

    createSuggestionReplyOfReplyMutation.mutate(replyOfReplyData);

    state({
      display: false,
      itemReplyId: null,
      replyContent: "",
    });
  };

  const editOfReplyOfReplyMutation = UseEditProjectSuggestionReplyOfReply(
    refreshSuggestionComments
  );
  const handleEditOfReplyOfReply = async (
    replyOfReplyEditState,
    commentID,
    commentResponseID,
    setReplyOfReplyEditState
  ) => {
    const replyOfReplyData = {
      projectID: projectID,
      suggestionID: suggestionID,
      projectSuggestionCommentID: commentID,
      projectSuggestionCommentReplyID: commentResponseID,
      ID: replyOfReplyEditState.responseID,
      commentReplyOfReply: {
        comment: {
          response_text: replyOfReplyEditState.content,
        },
      },
    };

    editOfReplyOfReplyMutation.mutate(replyOfReplyData);

    setReplyOfReplyEditState({
      ...replyOfReplyEditState,
      form: false,
    });
  };
  const upvoteCommentSuggestionReplyOfReplyMutation =
    UseUpvoteOfProjectSuggestionResponseOfResponse(refreshSuggestionComments);
  const handleUpvoteOfCommentReplyOfReply = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectSuggestionCommentResponseOfResponse",
      },
    };
    upvoteCommentSuggestionReplyOfReplyMutation.mutate(data);
  };
  const downvoteCommentSuggestionReplyOfReplyMutation =
    UseUnvoteOfProjectSuggestionResponseOfResponse(refreshSuggestionComments);
  const handleUnvoteOfCommentReplyOfReply = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectSuggestionCommentResponseOfResponse",
    };
    downvoteCommentSuggestionReplyOfReplyMutation.mutate(data);
  };

  if (suggestionIsLoading)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );

  if (suggestionError)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          Something went wrong while fetching the suggestions
        </Alert>
      </div>
    );

  return (
    <>
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={openModal}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button"
        >
          Add Suggestion
        </Button>
      </div>
      <ToastContainer />
      <CommentBox
        open={commentModal}
        setOpen={setCommentModal}
        comments={suggestionComments}
        handleCreateComment={handleCreateComment}
        detail={commentBoxDetail}
        setCommentBoxDetail={setCommentBoxDetail}
        submitButton={submitButton}
        setSubmitButton={setSubmitButton}
        label="Suggestion"
        labelDetails={suggestionSelection.suggestion}
        idea={suggestionSelection.idea}
        purpose={suggestionSelection.purpose}
        handleEditOfComment={handleEditOfComment}
        editSubmitButton={editSubmitButton}
        refreshGoals={refreshSuggestions}
        handleUpvoteOfComment={handleUpvoteOfComment}
        handleUnvoteOfComment={handleUnvoteOfComment}
        handleCreateOfCommentReponses={handleCreateOfCommentResponses}
        handleEditOfCommentReponses={handleEditOfCommentResponses}
        handleUpvoteOfCommentResponses={handleUpvoteOfCommentResponses}
        handleUnvoteOfCommentResponses={handleUnvoteOfCommentResponses}
        commentBoxIdea={suggestionSelection.idea}
        commentBoxPurpose={suggestionSelection.purpose}
        handleCreateOfCommentReplyOfReply={handleCreateOfCommentReplyOfReply}
        handleEditOfReplyOfReply={handleEditOfReplyOfReply}
        handleUpvoteOfCommentReplyOfReply={handleUpvoteOfCommentReplyOfReply}
        handleUnvoteOfCommentReplyOfReply={handleUnvoteOfCommentReplyOfReply}
      />

      <ProjectSuggestionModal
        closeModal={closeModal}
        isOpen={open}
        handleSubmit={handleCreate}
        createNewsuggestionMutation={createNewsuggestionMutation}
      />

      <AppReusableDisplayUpvotedProfiles
        votersToggleDrawer={votersToggleDrawer}
        setVotersToggleDrawer={setVotersToggleDrawer}
        profiles={votersProfiles}
        drawerTitle={drawerTitle}
        setDrawerTitle={setDrawerTitle}
        setVotersProfiles={setVotersProfiles}
      />

      <ProjectSuggestionEditModal
        projectID={id}
        suggestionEdit={suggestionEdit}
        setSuggestionEdit={setSuggestionEdit}
        closeModal={closeEditModal}
        isOpen={openEdit}
      />
      <div className="profile-core-values">
        <div className="project-sug-main">
          <div className="project-sug-body">{showSuggestions()}</div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateSuggestion: (pId, psId, data) =>
    dispatch(updateProjectSuggestion(pId, psId, data)),
  createComment: (pId, psId, data) =>
    dispatch(createProjectSuggestionComment(pId, psId, data)),
});

export default connect(null, mapDispatchToProps)(ProjectSuggestion);
