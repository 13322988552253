import React from "react";
import styles from "../../stylesheets/cssModules/profile.module.css";
import parse from "html-react-parser";

function ProfileMVCard({ icon, title, details }) {
  return (
    <>
      <div className={styles.profile_reusable_card_container}>
        <div className={styles.profile_reusable_card_header}>
          <img
            src={icon}
            style={{
              height: "35px",
            }}
            alt="icon"
          />
          <span> {title}</span>
        </div>
        <div className={styles.profile_reusable_card_body}>
          <div className={styles.actual_details}> {parse(details)}</div>
        </div>
      </div>
    </>
  );
}

export default ProfileMVCard;
