import React from "react";
import { useParams } from "react-router-dom";
import nocores from "../../../assets/nocorevalues.png";
import DisplayProjects from "../../DisplayProjects";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { UseDeleteProjectSubProjects } from "../../../hooks/project/singleProject";

function SubProject({
  subProjects,
  subprojectError,
  subprojectIsloading,
  categories,
  sdgs,
  speedDialState,
  setTab,
}) {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const { id } = useParams();

  const deleteSubProjectMutation = UseDeleteProjectSubProjects();
  const handleDelete = async (subProjectID) => {
    const data = { id, subProjectID };
    deleteSubProjectMutation.mutate(data);
  };

  const displaySubProjects = () => {
    if (subProjects.length > 0) {
      return (
        <div className="sub-project-cont">
          <DisplayProjects
            categories={categories}
            sdgs={sdgs}
            projects={subProjects}
            icon={true}
            handleDelete={handleDelete}
            setTab={setTab}
          />
        </div>
      );
    }
    return (
      <div className="w-100 text-center">
        <h4 className="text-dark mt-3 mb-3"> No sub projects</h4>
        <img className="no-cores-thumbnail mb-5" src={nocores} alt="no-cv" />
      </div>
    );
  };

  if (subprojectIsloading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );

  if (subprojectError)
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Alert variant="filled" severity="error">
        Something went wrong while fetching the subprojects
      </Alert>
    </div>;

  return (
    <div className="container-fluid container-m-well">
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => toggleProjectSpeedDialMenu("subproject", true)}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button mt-4"
        >
          Add Sub Project
        </Button>
      </div>
      {displaySubProjects()}
    </div>
  );
}

export default SubProject;
