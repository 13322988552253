import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Navbar from "../../navbar/Navbar";
import {
  UseAddMemberToNetwork,
  UseAddProjectsToNetwork,
  UseEditNetwork,
  UseFetchAllProjectsForNetwork,
  UseFetchNetworkShowPage,
} from "../../../hooks/Network";
import AppLoadingSkeleton from "../../reusablecomponents/AppLoadingSkeleton";
import _ from "lodash";
import { useMutation, useQueryClient } from "react-query";
// ---materila ui
import Alert from "@mui/material/Alert";
import AppTopBar from "./AppTopBar";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { useCountUp } from "use-count-up";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import { red } from "@mui/material/colors";

// -- styles ---
import styles from "../css/styles.module.css";
import DetailsCard from "./DetailsCard";
import { Button, Divider } from "@mui/material";
import ProjectCard from "../../All_Projects/ReusableComponents/ProjectCard";
import AddProjectsToNetworkModal from "./AddProjectsToNetworkModal";
import EditNetworkModal from "./EditNetworkModal";
import AppProjectSearchBar from "./AppProjectSearchBar";
import { follow, unFollow } from "../../../services/favorites/favoriteService";
import useGetProfiles from "../../../hooks/profiles/useGetProfiles";
import AddProfilesModal from "./AddProfilesModal";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: "30px",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  "& > :not(style) ~ :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

const NetworkShowPage = () => {
  const { id } = useParams();
  const history = useHistory();

  // ---- REACT QUERY ----
  const queryClient = useQueryClient();
  const { data, error, isLoading } = UseFetchNetworkShowPage(id);

  // ------ STATE ------
  const [addProjectsToNetworkModal, setAddProjectsToNetworkModal] =
    useState(false);
  const [networkID] = useState(id);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [addMembersModal, setAddMembersModal] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  // -- CREATE NEW NETWORK MODAL ----
  const [editNetworkModal, setEditNetworkModal] = useState(false);

  const [networkDataElements, setNetworkDataElements] = useState({
    name: "",
    idea: "",
    purpose: "",
    mission: "",
    vision: "",
  });

  useEffect(() => {
    setNetworkDataElements({
      name: data?.name,
      idea: data?.idea,
      purpose: data?.purpose,
      mission: data?.mission,
      vision: data?.vision,
    });
  }, [data]);

  // ---- FORM RESET ----

  const resetAddProjectToNetworkForm = () => {
    setSelectedProjects([]);
    setAddProjectsToNetworkModal(false);
  };

  const resetEditNetworkForm = () => {
    setEditNetworkModal(false);
  };

  // <---- REACT QUERY FETCHING DATA---->
  const {
    data: networkProjects,
    error: networkProjectsError,
    isLoading: networkProjectsIsLoading,
  } = UseFetchAllProjectsForNetwork(id);

  const { allProfilesData, allProfilesError, allProfilesLoading } =
    useGetProfiles();

  // <---- REACT QUERY MUTATION OF DATA ---->
  const addProjectToNetworkMutation = UseAddProjectsToNetwork(
    resetAddProjectToNetworkForm
  );
  const editNetworkMutation = UseEditNetwork(resetEditNetworkForm);

  const addMembersToNetworkMutation = UseAddMemberToNetwork(() =>
    setAddMembersModal(false)
  );

  const newNetworkMembers = async () => {
    let selectedProfileID = _.uniq(selectedProfiles);

    if (_.isEmpty(selectedProfileID)) return;

    selectedProfileID.forEach((item) => {
      let data = {
        network_team: {
          user_id: item,
          network_id: networkID,
        },
      };

      addMembersToNetworkMutation.mutate(data);
    });
  };

  const editNetworkfn = async (data) => {
    editNetworkMutation.mutate({ networkID: id, data });
  };

  const followAction = useMutation({
    mutationFn: (projectID) => follow(projectID),
    onSuccess: (serverData, appData) => {
      //  --- Invalidate the cache to get actual data ---
      queryClient.invalidateQueries({
        queryKey: ["networks"],
      });
    },
  });

  const unFollowAction = useMutation({
    mutationFn: (projectID) => unFollow(projectID),
    onSuccess: () => {
      //  --- Invalidate the cache to get actual data ---
      queryClient.invalidateQueries({
        queryKey: ["networks"],
      });
    },
  });

  const handleUnfollowAction = async (projectID) => {
    unFollowAction.mutate(projectID);
  };
  const handleFollowAction = async (projectID) => {
    followAction.mutate(projectID);
  };

  const newNetworkProject = async () => {
    let selectedProjectID = _.uniq(selectedProjects);

    if (_.isEmpty(selectedProjectID)) return;

    selectedProjectID.forEach((item) => {
      let data = {
        project_network: {
          project_id: item,
          network_id: networkID,
        },
      };

      addProjectToNetworkMutation.mutate(data);
    });
  };

  const { value } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: 70,
  });
  // --- Card details ----

  const detailsData = [
    {
      title: "Vision",
      description: data?.vision,
      id: 4,
      icon: <RemoveRedEyeIcon />,
    },
    {
      title: "Idea",
      description: data?.idea,
      id: 1,
      icon: <TipsAndUpdatesIcon />,
    },
    {
      title: "Purpose",
      description: data?.purpose,
      id: 2,
      icon: <AutoModeIcon />,
    },
    {
      title: "Mission",
      description: data?.mission,
      id: 3,
      icon: <FlagCircleIcon />,
    },
  ];

  // ----Navigation ---

  const naviageToDashBoard = () => {
    history.push("/dashboard");
  };

  const navigateToNetworks = () => {
    history.push("/networks");
  };

  const navigateToProfile = (profileID) => {
    history.push(`/profile/${profileID}`);
  };

  const navigateToProject = (projectID) => {
    history.push(`/projects/${projectID}`);
  };

  const displayProfiles = () => {
    if (_.isEmpty(data.network_members))
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert variant="filled" severity="info">
            There are no members in this network yet!
          </Alert>
        </div>
      );

    const display = () => {
      return data.network_members.map((profile) =>
        profile?.profile_avatar?.url === null ? (
          <Avatar
            sx={{ bgcolor: red[500] }}
            aria-label="recipe"
            alt={`${profile?.first_name}`}
            key={profile.id}
          />
        ) : (
          <Avatar
            alt={`${profile?.first_name} ${profile?.last_name}`}
            src={`${profile?.profile_avatar?.url}`}
            key={profile.id}
          />
        )
      );
    };

    return <AvatarGroup>{display()}</AvatarGroup>;
  };

  const displayDetailsCards = () => {
    return detailsData.map((item) => {
      return (
        <div className="col-md-6 mb-4" key={item.id}>
          <DetailsCard
            title={item.title}
            details={item.description}
            icon={item.icon}
          />
        </div>
      );
    });
  };

  if (isLoading)
    return (
      <>
        <Navbar />
        <AppLoadingSkeleton />
      </>
    );

  if (error)
    return (
      <>
        <Navbar />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert variant="filled" severity="error">
            There was an error while fetching the page data
          </Alert>
        </div>
      </>
    );

  // --- filter profiles data ----
  const displayProfileMembersList = () => {
    if (allProfilesLoading) return [];
    if (allProfilesError) return [];

    if (_.isEmpty(allProfilesData)) return [];

    if (_.isEmpty(data.network_members)) return allProfilesData;

    const array1 = new Set(data.network_members.map((item) => item.id));
    const result = allProfilesData.filter((item) => !array1.has(item.id));
    return result;
  };

  //  --- Search filter bar ----
  const filterProjects = () => {
    if (searchValue === null) return data.projects;

    return data.projects.filter(
      (value) => value.name === searchValue.name && value
    );
  };

  const displayProjects = () => {
    if (_.isEmpty(data.projects))
      return (
        <Alert variant="filled" severity="info">
          There are no projects assigned to this network
        </Alert>
      );

    return filterProjects().map((item) => {
      return (
        <Fragment key={item.id}>
          <div className="col-md-4 col-sm-6 col-xl-3 mb-4">
            <ProjectCard
              project={item}
              handleUnfollowAction={handleUnfollowAction}
              handleFollowAction={handleFollowAction}
              navigateTo={navigateToProject}
            />
          </div>
        </Fragment>
      );
    });
  };

  return (
    <>
      <Navbar />
      <EditNetworkModal
        open={editNetworkModal}
        setOpen={setEditNetworkModal}
        createEditNetworkMutation={editNetworkMutation}
        networkData={networkDataElements}
        setNetworkData={setNetworkDataElements}
        editNetwork={editNetworkfn}
      />
      <AddProfilesModal
        open={addMembersModal}
        setOpen={setAddMembersModal}
        allProfilesData={displayProfileMembersList()}
        allProfilesLoading={allProfilesLoading}
        allProfilesError={allProfilesError}
        selectedProfiles={selectedProfiles}
        setSelectedProfiles={setSelectedProfiles}
        newNetworkMembers={newNetworkMembers}
        addMembersToNetworkMutation={addMembersToNetworkMutation}
      />
      <AddProjectsToNetworkModal
        open={addProjectsToNetworkModal}
        setOpen={setAddProjectsToNetworkModal}
        networkProjects={networkProjects}
        networkProjectsIsLoading={networkProjectsIsLoading}
        networkProjectsError={networkProjectsError}
        setSelectedProjects={setSelectedProjects}
        selectedProjects={selectedProjects}
        newNetworkProject={newNetworkProject}
        addProjectToNetworkMutation={addProjectToNetworkMutation}
      />
      <AppTopBar
        pageName={data.name}
        naviageToDashBoard={naviageToDashBoard}
        navigateToNetworks={navigateToNetworks}
      />
      <div className={`container-fluid ${styles.show_network_page_container}`}>
        <div className="row">
          <div className="col-md-9 col-sm-12">
            <h4
              style={{
                position: "relative",
                top: "10px",
                marginTop: "20px",
                textTransform: "uppercase",
                marginLeft: "20px",
                fontWeight: "800",
              }}
            >
              <strong>{data.name}</strong>
            </h4>
            <div
              className="text-right"
              style={{
                position: "relative",
                top: "-30px",
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<AddIcon />}
                sx={{
                  marginRight: "10px",
                }}
                onClick={() => setAddProjectsToNetworkModal(true)}
              >
                Add Project
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<EditIcon />}
                onClick={() => setEditNetworkModal(true)}
              >
                Modify Network
              </Button>
            </div>
            <div className="container-fluid">
              <div className="row">{displayDetailsCards()}</div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className={styles.network_info_container}>
              <div
                className={styles.image_container_section}
                onClick={() => navigateToProfile(data.creator.id)}
                style={{
                  cursor: "pointer",
                }}
              >
                {data.creator?.profile_avatar?.url === null ? (
                  <Avatar
                    sx={{ bgcolor: red[500], width: "56px", height: "56px" }}
                    aria-label="Profile"
                    alt={`${data.creator?.first_name}`}
                  />
                ) : (
                  <Avatar
                    alt={`${data.creator?.first_name} ${data.creator?.last_name}`}
                    src={`${data.creator?.profile_avatar?.url}`}
                    sx={{ width: "56px", height: "56px" }}
                  />
                )}
              </div>
              <div className={styles.creator_name_container}>
                <h6>Network Creator:</h6>
              </div>
              <div className={styles.creator_name_container}>
                <h5>{`${data.creator?.first_name && data.creator?.first_name} ${
                  data.creator?.last_name && data.creator?.last_name
                }`}</h5>
              </div>
              <div className={styles.roles_container}>
                <p>{data.creator?.role && data.creator?.role}</p>
              </div>

              <div className={styles.profile_details_info}>
                <Chip
                  label={`CREATED PROJECTS(${data.creater_projects_count})`}
                  variant="outlined"
                  sx={{
                    marginRight: "10px",
                    fontWeight: "600",
                  }}
                />
                <Chip
                  label={`FAVORITE PROJECTS (${data.creator_favorite_projects_count})`}
                  variant="outlined"
                  sx={{
                    fontWeight: "600",
                  }}
                />
              </div>

              <Root>
                <Divider>
                  <Chip
                    label="NETWORK STATISTICS"
                    size="large"
                    color="secondary"
                  />
                </Divider>
              </Root>
              <div className={styles.project_statistics_container}>
                <div
                  className="text-center"
                  style={{
                    position: "relative",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <CircularProgress
                    size="lg"
                    determinate
                    value={parseInt(value)}
                    color="primary"
                  >
                    <Typography>{value}%</Typography>
                  </CircularProgress>

                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontWeight: 600,
                      textTransform: "uppercase",
                      color: "grey",
                      marginTop: "20px",
                    }}
                  >
                    Average Project Engagements
                  </Typography>
                </div>
              </div>
              <Root>
                <Divider>
                  <Chip
                    label="NETWORK TEAM MEMBERS"
                    size="large"
                    color="secondary"
                  />
                </Divider>
              </Root>

              <div className={`${styles.team_container} `}>
                <div className="text-right">
                  <Button
                    onClick={() => setAddMembersModal(true)}
                    variant="outlined"
                    color="secondary"
                    sx={{
                      margin: "10px",
                    }}
                    startIcon={<AddIcon />}
                  >
                    team member
                  </Button>
                </div>
                <div className={styles.team_display}>{displayProfiles()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className={styles.projects_display_container}>
              <div className={`${styles.search_bar_area} container`}>
                <AppProjectSearchBar
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  projects={data.projects}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.actual_project_container}>
        <div className="container-fluid">
          <div className="row">{displayProjects()}</div>
        </div>
      </div>
    </>
  );
};

export default NetworkShowPage;
