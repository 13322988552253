import React, { useState } from "react";
import nocores from "../../assets/nocorevalues.png";
import _ from "lodash";
// ---- MATERIAL UI ----
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

import ProjectGoalDetailsCard from "./ProjectGoalDetailsCard";
import ProjectGoalEditModal from "./ProjectGoalEditModal";
import { NotificationContainer } from "react-notifications";
import ProjectKpisModal from "./ProjectKpisModal";
import ProjectGoalObjectiveModal from "./ProjectGoalObjectiveModal";

import ProjectGoalobjectiveEdit from "./ProjectGoalobjectiveEdit";
import ProjectKpiEditModal from "./ProjectKpiEditModal";
import { Button } from "@mui/material";
import {
  UseCreateNewProjectObjective,
  UseCreateProjectKPI,
  UseEditProjectKPI,
  UseEditProjectObjective,
  UseUnVoteProjectGoal,
  UseUpvoteProjectGoal,
} from "../../hooks/project/singleProject";

const ProjectGoals = ({
  id,
  goals,
  goalsIsLoading,
  goalsError,
  editState,
  setEditState,
  fetchSingleProject,
  setGoals,
  kpisIsLoading,
  kpisError,
  projectGoalKpis,
  speedDialState,
}) => {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const closeEditModal = () => setOpenEditModal(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [kpiModal, setKpiModal] = useState(false);
  const [objectiveModal, setObjectiveModal] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [objectiveEditModal, setObjectiveEditModal] = useState(false);
  const [objectiveEditstate, setObjectiveEditState] = useState("");
  const [editKpiState, setEditKpiState] = useState("");
  const [kpiEditModal, setKpiEditModal] = useState(false);
  const [kpiID, setKpiID] = useState(null);

  const resetProjectObjectiveEdit = () => {
    setObjectiveEditModal(false);
    setObjectiveEditState("");
  };

  const resetKPIEdit = () => {
    setKpiEditModal(false);
    setEditKpiState("");
    setKpiID(null);
  };

  // ---- REACT QUERY ----
  const upvoteGoalMutation = UseUpvoteProjectGoal();
  const unvoteGoalMutation = UseUnVoteProjectGoal();
  const newProjectGoalObjectiveMutatation = UseCreateNewProjectObjective(() =>
    setObjectiveModal(false)
  );
  const editGoalObjectiveMutation = UseEditProjectObjective(
    resetProjectObjectiveEdit
  );
  const createNewProjectKpimutation = UseCreateProjectKPI(() =>
    setKpiModal(false)
  );
  const editProjectKpiMutation = UseEditProjectKPI(resetKPIEdit);

  const handleLikeAction = (goal) => {
    const data = {
      upvote: {
        upvoteable_id: goal.id,
        upvoteable_type: "ProjectGoal",
      },
    };
    upvoteGoalMutation.mutate(data);
  };

  const handleDislikeAction = (goal) => {
    const data = {
      upvoteable_id: goal.id,
      upvoteable_type: "ProjectGoal",
    };
    unvoteGoalMutation.mutate(data);
  };

  const handleCreateNewObjective = (data) => {
    newProjectGoalObjectiveMutatation.mutate(data);
  };

  const handleEditObjective = async (data) => {
    editGoalObjectiveMutation.mutate(data);
  };

  const handleCreateKpi = async (data) => {
    createNewProjectKpimutation.mutate(data);
  };

  const handleEditKpi = async (data) => {
    editProjectKpiMutation.mutate(data);
  };

  const showData = () => {
    if (_.isEmpty(goals)) {
      return (
        <div className="w-100 text-center">
          <h4 className="text-dark mt-1 mb-2">No Goals for this project.</h4>
          <img className="no-cores-thumbnail" src={nocores} alt="no-cv" />
        </div>
      );
    }

    if (goals !== "undefined") {
      return (
        <div className="accordion container-fluid" id={`goalAccordian`}>
          {goals.map((goal, idx) => (
            <ProjectGoalDetailsCard
              setEditState={setEditState}
              setOpenEditModal={setOpenEditModal}
              closeModal={closeEditModal}
              key={`gg${idx}`}
              data={goal}
              setKpiModal={setKpiModal}
              setSelectedGoal={setSelectedGoal}
              setObjectiveModal={setObjectiveModal}
              setObjectiveEditState={setObjectiveEditState}
              setSelectedObjective={setSelectedObjective}
              setObjectiveEditModal={setObjectiveEditModal}
              projectGoalKpis={projectGoalKpis}
              kpisIsLoading={kpisIsLoading}
              kpisError={kpisError}
              editKpiState={editKpiState}
              setEditKpiState={setEditKpiState}
              kpiEditModal={kpiEditModal}
              setKpiEditModal={setKpiEditModal}
              setKpiID={setKpiID}
              like={handleLikeAction}
              dislike={handleDislikeAction}
              projectID={id}
            />
          ))}
        </div>
      );
    }
  };

  if (goalsIsLoading || kpisIsLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );

  if (goalsError || kpisError)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          Something went wrong while fetching the goals and KPIs
        </Alert>
      </div>
    );

  return (
    <div className="profile-core-values container-m-well">
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => toggleProjectSpeedDialMenu("goal", true)}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button mt-4 mb-4"
        >
          Add Goal
        </Button>
      </div>
      <ProjectKpiEditModal
        editKpiState={editKpiState}
        setEditKpiState={setEditKpiState}
        kpiEditModal={kpiEditModal}
        setKpiEditModal={setKpiEditModal}
        projectID={id}
        goalID={selectedGoal}
        objectiveID={selectedObjective}
        handleEditKpi={handleEditKpi}
        kpiID={kpiID}
      />
      <ProjectKpisModal
        kpiModal={kpiModal}
        setKpiModal={setKpiModal}
        projectID={id}
        goalID={selectedGoal}
        objectiveID={selectedObjective}
        handleCreateKpi={handleCreateKpi}
      />
      <ProjectGoalObjectiveModal
        objectiveModal={objectiveModal}
        setObjectiveModal={setObjectiveModal}
        handleCreateNewObjective={handleCreateNewObjective}
        projectID={id}
        goalID={selectedGoal}
        newProjectGoalObjectiveMutatation={newProjectGoalObjectiveMutatation}
      />
      <ProjectGoalobjectiveEdit
        objectiveEditModal={objectiveEditModal}
        setObjectiveEditModal={setObjectiveEditModal}
        projectID={id}
        goalID={selectedGoal}
        objectiveEditstate={objectiveEditstate}
        setObjectiveEditState={setObjectiveEditState}
        handleEditObjective={handleEditObjective}
        objectiveID={selectedObjective}
        setSelectedObjective={setSelectedObjective}
        editGoalObjectiveMutation={editGoalObjectiveMutation}
      />
      <NotificationContainer />
      <ProjectGoalEditModal
        editState={editState}
        isOpen={openEditModal}
        closeModal={closeEditModal}
        id={id}
        fetchSingleProject={fetchSingleProject}
        setGoals={setGoals}
      />

      {showData()}
    </div>
  );
};

export default ProjectGoals;
