import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DialogContent from "@mui/material/DialogContent";

import IconButton from "@mui/material/IconButton";
import styles from "./CommentBox.module.css";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Avatar, Button, Divider, ListItem } from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import AvatarGroup from "@mui/material/AvatarGroup";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import { deepPurple } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { displayDate, displayTime } from "../../../helpers/timeModule";
import parse from "html-react-parser";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import List from "@mui/material/List";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import { useHistory } from "react-router-dom";

// From text, to editor ====
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";
// ==== THIS WILL BE A REUSABLE COMMENT BOX FOE THE PROJECT FIELDS ====

/*
ACTIONS:

1. handleCreateComment is a method to create a comment to the backend
2. handleCreateCommentReply is a method for creating a reply for a particular comment
3. handleUpvoteComment is for creating an upvote for comment,
4. handleUpvoteCommentReply is for creating an upvote for comment reply
5. handleEditOfComment is for editing a comment
6. handleEditOfCommentReply is to handle edit of comment reply

props:

1. Detail: This is for showing the details of the module.
2. Comments: This is for displaying all comments
3. CommentsReply: This is for showing all the comments reply.
*/

const CommentVotesProfiles = ({
  profilesDisplayState,
  setProfileDisplayState,
}) => {
  const history = useHistory();
  const { show, upvoted_by } = profilesDisplayState;

  const handleClose = () => {
    setProfileDisplayState({
      show: false,
      upvoted_by: [],
      id: null,
    });
  };

  const displayProfiles = () => {
    return upvoted_by.map((value, index) => {
      return (
        <ListItem
          key={index}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            history.push(`/profile/${value.profile.id}`);
          }}
        >
          <ListItemAvatar>
            {value.profile.profile_avatar !== null ? (
              <Avatar
                alt="profile avatar"
                src={`${value.profile.profile_avatar.url}`}
                className="sb-shared-icons-avatars"
              />
            ) : (
              <Avatar src="/broken-image.jpg" />
            )}
          </ListItemAvatar>
          <ListItemText
            primary={`${value.profile.first_name} ${value.profile.last_name}`}
            secondary={value.profile.role}
          />
        </ListItem>
      );
    });
  };

  return (
    <React.Fragment>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Upvoted Profiles"}</DialogTitle>
        <DialogContent>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {displayProfiles()}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            autoFocus
            variant="contained"
            color="error"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BottomAppBar({
  handleCreateComment,
  setCommentBoxDetail,
  detail,
  submitButton,
}) {
  return (
    <React.Fragment>
      <div className={styles.comment_box_area_box}>
        <label
          className={styles.new_comment_reply_label}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          New Comment
        </label>
        <CKEditor
          className="form-control"
          editor={ClassEditor}
          data={detail}
          onChange={(event, editor) => {
            const data = editor.getData();
            setCommentBoxDetail(data);
          }}
          label="Comment"
        />

        <Button
          variant="outlined"
          color="secondary"
          endIcon={<SendOutlinedIcon />}
          className="mt-2 mb-1 ml-2"
          onClick={handleCreateComment}
          disabled={submitButton}
        >
          {submitButton ? <CircularProgress color="success" /> : "Comment"}
        </Button>
      </div>
    </React.Fragment>
  );
}

function CommentBox({
  isLoading,
  isError,
  handleCreateComment,
  handleEditOfComment,
  detail,
  setCommentBoxDetail,
  comments,
  open,
  setOpen,
  submitButton,
  setSubmitButton,
  label,
  labelDetails,
  editSubmitButton,
  handleUpvoteOfComment,
  handleUnvoteOfComment,
  handleCreateOfCommentReponses,
  handleEditOfCommentReponses,
  handleUpvoteOfCommentResponses,
  handleUnvoteOfCommentResponses,
  commentBoxIdea,
  commentBoxPurpose,
  handleCreateOfCommentReplyOfReply,
  handleEditOfReplyOfReply,
  handleUpvoteOfCommentReplyOfReply,
  handleUnvoteOfCommentReplyOfReply,
}) {
  // === THIS STATE IS RESPONSIBLE FOR DISPLAYING PEOPLE THAT HAVE UPVOTED =====
  const [profilesDisplayState, setProfileDisplayState] = useState({
    show: false,
    upvoted_by: [],
    id: null,
  });

  // ==== EDIT STATE HANDLES THE EDIT OF COMMENT FUNCTIONALITY ====
  const [editState, setEditState] = useState({
    form: false,
    itemId: null,
    content: "",
  });

  // ==== COMMENT REPLY STATE IT HANDLES THE REPLY OF COMMENT FUNCTIONALITY ====
  const [replyState, setReplyState] = useState({
    display: false,
    itemReplyId: null,
    replyContent: "",
  });

  // ==== COMMENT REPLY OF REPLY STATE  IT HANDLES THE REPLY OF REPLY OF COMMENT FUNCTIONALITY ====
  const [replyOfReplyState, setReplyOfReplyState] = useState({
    display: false,
    itemReplyId: null,
    replyContent: "",
  });

  const [replyOfReplyEditState, setReplyOfReplyEditState] = useState({
    form: false,
    responseID: null,
    content: "",
  });

  // ===== HANDLES COMMENT EDIT REPONSES AND ALSO UPVOTE OF COMMENT RESPONSES =====
  const [editCommentResponse, setEditCommentResponses] = useState({
    form: false,
    responseID: null,
    content: "",
  });

  // ====== THIS DISPLAYS THE EDIT FORM FOR EACH COMMENT ====
  const displayEditForm = () => {
    const { content } = editState;
    return (
      <>
        <label className={styles.new_comment_reply_label}>Edit Comment</label>
        <CKEditor
          className="form-control"
          editor={ClassEditor}
          data={content}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditState({ ...editState, content: data });
          }}
          label="Comment-edit"
        />
        <Button
          variant="outlined"
          color="secondary"
          endIcon={<SendOutlinedIcon />}
          className="mt-2 mb-1"
          onClick={() => handleEditOfComment(editState, setEditState)}
          disabled={editSubmitButton}
        >
          submit
        </Button>
        <Button
          variant="outlined"
          color="error"
          endIcon={<HighlightOffOutlinedIcon />}
          className="mt-2 mb-1 ml-2"
          onClick={() =>
            setEditState({
              form: false,
              itemId: null,
              content: "",
            })
          }
        >
          cancel
        </Button>
      </>
    );
  };

  const displayEditResponseForm = (commentID) => {
    const { content } = editCommentResponse;
    return (
      <>
        <label className={styles.new_comment_reply_label}>
          Edit Comment Reply
        </label>
        <CKEditor
          className="form-control"
          editor={ClassEditor}
          data={content}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditCommentResponses({
              ...editCommentResponse,
              content: data,
            });
          }}
          label="Comment-reply-edit"
        />
        <div className={styles.response_action_edit_items}>
          <Button
            variant="outlined"
            color="secondary"
            endIcon={<SendOutlinedIcon />}
            className="mt-2 mb-1"
            onClick={() =>
              handleEditOfCommentReponses(
                editCommentResponse,
                setEditCommentResponses,
                commentID
              )
            }
            disabled={editSubmitButton}
          >
            edit
          </Button>
          <Button
            variant="outlined"
            color="error"
            endIcon={<HighlightOffOutlinedIcon />}
            className="mt-2 mb-1 ml-2"
            onClick={() =>
              setEditCommentResponses({
                form: false,
                responseID: null,
                content: "",
              })
            }
          >
            cancel
          </Button>
        </div>
      </>
    );
  };

  const displayUpvotedProfiles = (profiles) => {
    return profiles.map((value, index) => {
      return (
        <Avatar
          key={index}
          alt={`${value.profile.first_name} ${value.profile.last_name}`}
          sx={{ bgcolor: deepPurple[500], width: 40, height: 40 }}
          src={value.profile.profile_avatar.url}
        />
      );
    });
  };

  const displayCommentResponsesOfResponses = (
    commentResponses,
    commentID,
    commentResponseID
  ) => {
    const { form } = replyOfReplyEditState;

    if (commentResponses.length < 1) return null;
    const results = commentResponses.map((value, index) => {
      return (
        <Grid item key={index}>
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            className="main-grid"
            key={index}
          >
            <Grid item>
              {!_.isEmpty(value.creator) ? (
                <Avatar
                  alt={`${value.creator.first_name} ${value.creator.last_name}`}
                  src={`${value.creator.profile_avatar.url}`}
                  sx={{ width: 50, height: 50 }}
                />
              ) : (
                <Avatar src="/broken-image.jpg" />
              )}
              {comments.length < 2 ||
              value.id ===
                commentResponses[commentResponses.length - 1].id ? null : (
                <div className="vertical-bar-comment-box-sub"></div>
              )}
            </Grid>

            <Grid item xs={11} zeroMinWidth className="sub-comment-container">
              <h4
                style={{ margin: 0, textAlign: "left" }}
                className="creator-of-comment"
              >
                {value.creator.first_name}
                {value.creator.last_name}
              </h4>
              <p
                style={{ textAlign: "left", color: "gray" }}
                className="comment-date-posted"
              >
                Posted &nbsp;
                {displayDate(value.created_at).value < 1
                  ? "Just now"
                  : displayDate(value.created_at).value}{" "}
                {displayDate(value.created_at).value < 1
                  ? null
                  : displayTime(
                      displayDate(value.created_at).unit,
                      displayDate(value.created_at).value
                    )}{" "}
              </p>
              <div className={styles.each_comment_container}>
                <div className={styles.comment_content_container}>
                  <div className={styles.comment_details}>
                    {form && value.id === replyOfReplyEditState.responseID ? (
                      <div
                        style={{
                          border: "1px solid #eee",
                          padding: "20px",
                          marginBottom: "40px",
                        }}
                        className="container"
                      >
                        <label className={styles.new_comment_reply_label}>
                          Edit Comment
                        </label>
                        <CKEditor
                          className="form-control"
                          editor={ClassEditor}
                          data={value.content}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setReplyOfReplyEditState({
                              ...replyOfReplyEditState,
                              content: data,
                            });
                          }}
                          label="Comment-Edit"
                        />

                        <Button
                          variant="outlined"
                          color="secondary"
                          endIcon={<SendOutlinedIcon />}
                          className="mt-2 mb-1"
                          onClick={() =>
                            handleEditOfReplyOfReply(
                              replyOfReplyEditState,
                              commentID,
                              commentResponseID,
                              setReplyOfReplyEditState
                            )
                          }
                        >
                          Edit Comment
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          endIcon={<HighlightOffOutlinedIcon />}
                          className="mt-2 mb-1 ml-2"
                          onClick={() =>
                            setReplyOfReplyEditState({
                              form: false,
                              responseID: value.id,
                              content: value.content,
                            })
                          }
                        >
                          cancel
                        </Button>
                      </div>
                    ) : (
                      parse(value.content)
                    )}
                  </div>
                </div>
              </div>
              <div
                className="suggestion-comments-icons-v container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <div
                  className="sugget-like"
                  style={{
                    textAlign: "start",
                    display: "flex",
                  }}
                >
                  <div>
                    <AvatarGroup
                      max={3}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setProfileDisplayState({
                          show: true,
                          upvoted_by: value.upvoted_by,
                          id: value.id,
                        })
                      }
                    >
                      {displayUpvotedProfiles(value.upvoted_by)}
                    </AvatarGroup>
                  </div>
                  {value.likes.upvoted ? (
                    <IconButton
                      aria-label="upvote"
                      onClick={() =>
                        handleUnvoteOfCommentReplyOfReply(value.id)
                      }
                    >
                      <FavoriteIcon
                        style={{
                          fontSize: "30px",
                          color: "#ff0000",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "15px",
                          textTransform: "uppercase",
                          color: "#000 !important",
                          marginLeft: "10px",
                          fontWeight: "700",
                        }}
                      >
                        upvotes({value.likes.counter})
                      </span>
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="unvote"
                      onClick={() =>
                        handleUpvoteOfCommentReplyOfReply(value.id)
                      }
                    >
                      <FavoriteBorderIcon
                        style={{
                          fontSize: "30px",
                          color: "#000",
                        }}
                      />

                      <span
                        style={{
                          fontSize: "15px",
                          textTransform: "uppercase",
                          color: "#000 !important",
                          marginLeft: "10px",
                          fontWeight: "700",
                        }}
                      >
                        upvotes({value.likes.counter})
                      </span>
                    </IconButton>
                  )}
                </div>
                <div
                  className="edit_button_cont"
                  style={{
                    flex: 1,
                    textAlign: "right",
                  }}
                >
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      setReplyOfReplyEditState({
                        ...replyOfReplyEditState,
                        form: true,
                        responseID: value.id,
                        content: value.content,
                      });
                    }}
                  >
                    <ModeEditIcon
                      style={{
                        fontSize: "30px",
                        color: "#000",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "15px",
                        textTransform: "uppercase",
                        color: "#000",
                        marginLeft: "10px",
                        fontWeight: "700",
                      }}
                    >
                      edit
                    </span>
                  </IconButton>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      );
    });
    return results;
  };

  const displayCommentsResponses = (commentResponses, commentID) => {
    const { form, responseID } = editCommentResponse;
    const { display, itemReplyId, replyContent } = replyOfReplyState;
    const results = commentResponses.map((value, index) => {
      return (
        <Grid item key={index}>
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            className="main-grid"
            key={index}
          >
            <Grid item>
              {!_.isEmpty(value.creator) ? (
                <Avatar
                  alt={`${value.creator.first_name} ${value.creator.last_name}`}
                  src={`${value.creator.profile_avatar.url}`}
                  sx={{ width: 50, height: 50 }}
                />
              ) : (
                <Avatar src="/broken-image.jpg" />
              )}
              {comments.length < 2 ||
              value.id ===
                commentResponses[commentResponses.length - 1].id ? null : (
                <div className="vertical-bar-comment-box-sub"></div>
              )}
            </Grid>

            <Grid item xs={11} zeroMinWidth className="sub-comment-container">
              <h4
                style={{ margin: 0, textAlign: "left" }}
                className="creator-of-comment"
              >
                {value.creator.first_name}
                {value.creator.last_name}
              </h4>
              <p
                style={{ textAlign: "left", color: "gray" }}
                className="comment-date-posted"
              >
                Posted &nbsp;
                {displayDate(value.created_at).value < 1
                  ? "Just now"
                  : displayDate(value.created_at).value}{" "}
                {displayDate(value.created_at).value < 1
                  ? null
                  : displayTime(
                      displayDate(value.created_at).unit,
                      displayDate(value.created_at).value
                    )}{" "}
              </p>
              <div className={styles.each_comment_container}>
                <div className={styles.comment_content_container}>
                  <div className={styles.comment_details}>
                    {form && value.id === responseID
                      ? displayEditResponseForm(commentID)
                      : parse(value.content)}
                  </div>
                </div>
              </div>
              <div
                className="suggestion-comments-icons-v container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <div
                  className="sugget-like"
                  style={{
                    textAlign: "start",
                    display: "flex",
                  }}
                >
                  <div>
                    <AvatarGroup
                      max={3}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setProfileDisplayState({
                          show: true,
                          upvoted_by: value.upvoted_by,
                          id: value.id,
                        })
                      }
                    >
                      {displayUpvotedProfiles(value.upvoted_by)}
                    </AvatarGroup>
                  </div>
                  {value.likes.upvoted ? (
                    <IconButton
                      aria-label="upvote"
                      onClick={() => handleUnvoteOfCommentResponses(value.id)}
                    >
                      <FavoriteIcon
                        style={{
                          fontSize: "30px",
                          color: "#ff0000",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "15px",
                          textTransform: "uppercase",
                          color: "#000 !important",
                          marginLeft: "10px",
                          fontWeight: "700",
                        }}
                      >
                        upvotes({value.likes.counter})
                      </span>
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="unvote"
                      onClick={() => handleUpvoteOfCommentResponses(value.id)}
                    >
                      <FavoriteBorderIcon
                        style={{
                          fontSize: "30px",
                          color: "#000",
                        }}
                      />

                      <span
                        style={{
                          fontSize: "15px",
                          textTransform: "uppercase",
                          color: "#000 !important",
                          marginLeft: "10px",
                          fontWeight: "700",
                        }}
                      >
                        upvotes({value.likes.counter})
                      </span>
                    </IconButton>
                  )}
                </div>
                <div className="edit_button_cont">
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      setEditCommentResponses({
                        form: true,
                        responseID: value.id,
                        content: value.content,
                      });
                    }}
                  >
                    <ModeEditIcon
                      style={{
                        fontSize: "30px",
                        color: "#000",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "15px",
                        textTransform: "uppercase",
                        color: "#000",
                        marginLeft: "10px",
                        fontWeight: "700",
                      }}
                    >
                      edit
                    </span>
                  </IconButton>
                </div>
                <div
                  className="reply_of_reply_button_cont"
                  style={{
                    flex: 1,
                    textAlign: "right",
                  }}
                >
                  <IconButton
                    aria-label="reply"
                    onClick={() => {
                      setReplyOfReplyState({
                        display: true,
                        itemReplyId: value.id,
                        replyContent: "",
                      });
                    }}
                  >
                    <ReplyAllIcon
                      style={{
                        fontSize: "30px",
                        color: "#000",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "15px",
                        textTransform: "uppercase",
                        color: "#000",
                        marginLeft: "10px",
                        fontWeight: "700",
                      }}
                    >
                      reply
                    </span>
                  </IconButton>
                </div>
              </div>
              {display === true && value.id === itemReplyId ? (
                <div
                  style={{
                    border: "1px solid #eee",
                    padding: "20px",
                    marginBottom: "40px",
                  }}
                  className="container"
                >
                  <label className={styles.new_comment_reply_label}>
                    Reply to this comment
                  </label>
                  <CKEditor
                    className="form-control"
                    editor={ClassEditor}
                    data={replyContent}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setReplyOfReplyState({
                        ...replyOfReplyState,
                        replyContent: data,
                      });
                    }}
                    label="Comment-reply"
                  />

                  <Button
                    variant="outlined"
                    color="secondary"
                    endIcon={<SendOutlinedIcon />}
                    className="mt-2 mb-1"
                    onClick={() =>
                      handleCreateOfCommentReplyOfReply(
                        replyOfReplyState,
                        commentID,
                        setReplyOfReplyState
                      )
                    }
                  >
                    submit
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    endIcon={<HighlightOffOutlinedIcon />}
                    className="mt-2 mb-1 ml-2"
                    onClick={() =>
                      setReplyOfReplyState({
                        ...replyState,
                        display: false,
                        itemReplyId: value.id,
                      })
                    }
                  >
                    cancel
                  </Button>
                </div>
              ) : null}
              {displayCommentResponsesOfResponses(
                value.comment_response_of_response,
                commentID,
                value.id
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    });

    return <>{results}</>;
  };

  const displayComments = () => {
    // ==== EDIT STATE DESTRUCTURE ===
    const { itemId, form } = editState;
    const { display, itemReplyId, replyContent } = replyState;
    if (comments.length < 1) return "There are no comments at this time";
    const results = comments.map((item, indx) => {
      return (
        <Grid item key={indx}>
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            className="main-grid"
            key={indx}
          >
            <Grid item>
              {!_.isEmpty(item.creator) ? (
                <Avatar
                  alt={`${item.creator.first_name} ${item.creator.last_name}`}
                  src={`${item.creator.profile_avatar.url}`}
                  sx={{ width: 55, height: 55 }}
                  className="mr-3"
                />
              ) : (
                <Avatar src="/broken-image.jpg" />
              )}
              {comments.length < 2 ||
              item.id === comments[comments.length - 1].id ? null : (
                <div className="vertical-bar-comment-box-sub"></div>
              )}
            </Grid>

            <Grid item xs={11} zeroMinWidth className="sub-comment-container">
              <h4
                style={{ margin: 0, textAlign: "left" }}
                className="creator-of-comment"
              >
                {item.creator.first_name}
                {item.creator.last_name}
              </h4>
              <p
                style={{ textAlign: "left", color: "gray" }}
                className="comment-date-posted"
              >
                Posted &nbsp;
                {displayDate(item.created_at).value < 1
                  ? "Just now"
                  : displayDate(item.created_at).value}{" "}
                {displayDate(item.created_at).value < 1
                  ? null
                  : displayTime(
                      displayDate(item.created_at).unit,
                      displayDate(item.created_at).value
                    )}{" "}
              </p>
              <div className={styles.each_comment_container}>
                <div className={styles.comment_content_container}>
                  <div className={styles.comment_details}>
                    {form && itemId === item.id
                      ? displayEditForm()
                      : parse(item.content)}
                  </div>
                </div>
              </div>
              <div
                className="suggestion-comments-icons-v container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <div
                  className="sugget-like"
                  style={{
                    textAlign: "start",
                    display: "flex",
                  }}
                >
                  <div>
                    <AvatarGroup
                      max={3}
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setProfileDisplayState({
                          show: true,
                          upvoted_by: item.upvoted_by,
                          id: item.id,
                        })
                      }
                    >
                      {displayUpvotedProfiles(item.upvoted_by)}
                    </AvatarGroup>
                  </div>
                  {item.likes.upvoted ? (
                    <IconButton
                      aria-label="upvote"
                      onClick={() => handleUnvoteOfComment(item)}
                    >
                      <FavoriteIcon
                        style={{
                          fontSize: "30px",
                          color: "#ff0000",
                        }}
                      />
                      <span
                        style={{
                          fontSize: "15px",
                          textTransform: "uppercase",
                          color: "#000 !important",
                          marginLeft: "10px",
                          fontWeight: "700",
                        }}
                      >
                        upvotes({item.likes.counter})
                      </span>
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="unvote"
                      onClick={() => handleUpvoteOfComment(item)}
                    >
                      <FavoriteBorderIcon
                        style={{
                          fontSize: "30px",
                          color: "#000",
                        }}
                      />

                      <span
                        style={{
                          fontSize: "15px",
                          textTransform: "uppercase",
                          color: "#000 !important",
                          marginLeft: "10px",
                          fontWeight: "700",
                        }}
                      >
                        upvotes({item.likes.counter})
                      </span>
                    </IconButton>
                  )}
                </div>
                <div className="edit_button_cont">
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      setEditState({
                        form: true,
                        itemId: item.id,
                        content: item.content,
                      });
                    }}
                  >
                    <ModeEditIcon
                      style={{
                        fontSize: "30px",
                        color: "#000",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "15px",
                        textTransform: "uppercase",
                        color: "#000",
                        marginLeft: "10px",
                        fontWeight: "700",
                      }}
                    >
                      edit
                    </span>
                  </IconButton>
                </div>

                <div className="comments-reply">
                  <IconButton
                    aria-label="reply"
                    size="medium"
                    onClick={() => {
                      setReplyState({
                        ...replyState,
                        display: true,
                        itemReplyId: item.id,
                      });
                    }}
                  >
                    <ReplyAllIcon
                      fontSize="inherit"
                      style={{
                        fontSize: "30px",
                        color: "#000",
                      }}
                    />
                  </IconButton>
                  <span
                    style={{
                      fontSize: "15px",
                      textTransform: "uppercase",
                      color: "#000 !important",
                      marginLeft: "10px",
                      fontWeight: "700",
                    }}
                  >
                    Reply
                  </span>
                </div>
              </div>
              {display === true && item.id === itemReplyId ? (
                <div
                  style={{
                    border: "1px solid #eee",
                    padding: "20px",
                    marginBottom: "40px",
                  }}
                  className="container"
                >
                  <label className={styles.new_comment_reply_label}>
                    Reply to this comment
                  </label>
                  <CKEditor
                    className="form-control"
                    editor={ClassEditor}
                    data={replyContent}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setReplyState({ ...replyState, replyContent: data });
                    }}
                    label="Comment-reply"
                  />

                  <Button
                    variant="outlined"
                    color="secondary"
                    endIcon={<SendOutlinedIcon />}
                    className="mt-2 mb-1"
                    onClick={() =>
                      handleCreateOfCommentReponses(replyState, setReplyState)
                    }
                  >
                    submit
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    endIcon={<HighlightOffOutlinedIcon />}
                    className="mt-2 mb-1 ml-2"
                    onClick={() =>
                      setReplyState({
                        ...replyState,
                        display: false,
                        itemReplyId: item.id,
                      })
                    }
                  >
                    cancel
                  </Button>
                </div>
              ) : null}
              {displayCommentsResponses(item.comment_responses, item.id)}
            </Grid>
          </Grid>
        </Grid>
      );
    });

    return results;
  };

  const displayCommentBoxData = () => {
    if (isLoading)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "100px",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      );

    if (isError)
      return (
        <Alert variant="filled" severity="error">
          SomeThing went wrong while fetching the comments
        </Alert>
      );

    return (
      <div className={styles.main_container}>
        <Divider
          orientation="horizontal"
          sx={{
            width: "100%",
            minWidth: "100vh",
          }}
        />
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-md-3"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "#eee",
                minHeight: "100vh",
              }}
            >
              <Card sx={{ minWidth: 400, width: 400 }}>
                <CardContent>
                  <div className={styles.heading_title}>
                    <h4> {label} Overview: </h4>
                  </div>

                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem>
                      <div className={styles.details_container}>
                        <div className={styles.details_label_container}>
                          <DescriptionTwoToneIcon fontSize="large" />{" "}
                          <h6 className={styles.label_title}> {label}:</h6>
                        </div>
                        <div className={styles.actual_details}>
                          <ReadMoreReusable>{labelDetails}</ReadMoreReusable>
                        </div>
                      </div>
                    </ListItem>
                    {commentBoxIdea !== "" && (
                      <ListItem>
                        <div className={styles.details_container}>
                          <div className={styles.details_label_container}>
                            <TipsAndUpdatesIcon fontSize="large" />{" "}
                            <h6 className={styles.label_title}> idea:</h6>
                          </div>
                          <div className={styles.actual_details}>
                            <ReadMoreReusable>
                              {commentBoxIdea}
                            </ReadMoreReusable>
                          </div>
                        </div>
                      </ListItem>
                    )}

                    {commentBoxPurpose !== "" && (
                      <ListItem>
                        <div className={styles.details_container}>
                          <div className={styles.details_label_container}>
                            <ContactSupportIcon fontSize="large" />{" "}
                            <h6 className={styles.label_title}> purpose:</h6>
                          </div>
                          <div className={styles.actual_details}>
                            <ReadMoreReusable>
                              {commentBoxPurpose}
                            </ReadMoreReusable>
                          </div>
                        </div>
                      </ListItem>
                    )}
                  </List>
                </CardContent>
              </Card>
            </div>
            <div className="col-md-9">
              <BottomAppBar
                setOpen={setOpen}
                handleCreateComment={handleCreateComment}
                detail={detail}
                setCommentBoxDetail={setCommentBoxDetail}
                submitButton={submitButton}
                setSubmitButton={setSubmitButton}
              />
              <DialogContent
                className={styles.comment_content_area}
                style={{
                  padding: "35px",
                }}
              >
                {displayComments()}
              </DialogContent>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <CommentVotesProfiles
        profilesDisplayState={profilesDisplayState}
        setProfileDisplayState={setProfileDisplayState}
      />
      <Dialog
        fullScreen
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        TransitionComponent={Transition}
      >
        <AppBar
          position="static"
          color="transparent"
          className={styles.top_bar_container}
        >
          <Toolbar variant="dense">
            <Button
              variant="contained"
              color="secondary"
              startIcon={<KeyboardBackspaceIcon />}
              size="small"
              onClick={() => setOpen(false)}
            >
              {" "}
              back to {label}
            </Button>
          </Toolbar>
        </AppBar>
        {displayCommentBoxData()}
      </Dialog>
    </React.Fragment>
  );
}

export default CommentBox;
