/*eslint-disable */
import _ from "lodash";
import React, { useState } from "react";
import NeedCard from "../masterNeeds/NeedCard";
import NeedModal from "../masterNeeds/NeedModal";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { Button } from "@mui/material";
import { UseEditProjectNeed } from "../../hooks/project/singleProject";

const ProjectNeeds = ({
  id,
  speedDialState,
  needData,
  needError,
  needIsLoading,
  needCategoriesData,
  needCategoriesIsLoading,
  needCategoriesError,
  createNewNeedMutation,
}) => {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const [tabNo, setTabNo] = useState(0);
  const [open, setOpen] = useState(false);

  // -- RQ
  const updateMasterNeedMutation = UseEditProjectNeed();

  const closeModal = () => {
    setOpen(false);
  };

  const handleCreateNeed = (data) => {
    createNewNeedMutation.mutate(data);
  };

  const handleUpdateNeed = (nId, data) => {
    const needData = {
      id: nId,
      data,
    };
    updateMasterNeedMutation.mutate(needData);
  };

  const showNeeds = () => {
    switch (tabNo) {
      case 0:
        return showData(needData.open_needs);
      case 1:
        return showData(needData.closed_needs);
      default:
        break;
    }
  };

  const showData = (data) => {
    if (_.isEmpty(data)) {
      return (
        <h4 className="text-danger text-center">
          <i className="fa fa-frown-o" aria-hidden="true"></i>
          &nbsp; Sorry No Needs Available
        </h4>
      );
    } else {
      return data.map((need, idx) => (
        <NeedCard
          key={need.id}
          idx={idx}
          data={need}
          master={false}
          projects={[]}
          categories={needCategoriesData}
          handleSubmit={handleUpdateNeed}
        />
      ));
    }
  };

  if (needIsLoading) return <CircularProgress />;
  if (needError)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          Something went wrong while fetching the project needs
        </Alert>
      </div>
    );

  return (
    <>
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => toggleProjectSpeedDialMenu("need", true)}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button"
        >
          Add Need
        </Button>
      </div>
      <NeedModal
        categories={needCategoriesData}
        needCategoriesIsLoading={needCategoriesIsLoading}
        needCategoriesError={needCategoriesError}
        category={null}
        isOpen={open}
        closeModal={closeModal}
        handleSubmit={handleCreateNeed}
        proId={id}
      />
      <div>
        <div className="p-1">
          <button
            className="app-add-btn m-2"
            type="button"
            onClick={() => setTabNo(0)}
          >
            <i className="fa fa-folder-open text-success"></i>
            &nbsp; Open
          </button>
          <button
            className="app-add-btn m-2"
            type="button"
            onClick={() => setTabNo(1)}
          >
            {" "}
            <i className="fas fa-times-circle text-danger"></i>
            &nbsp; Closed
          </button>
        </div>
        <div className="p-3">{showNeeds()}</div>
      </div>
    </>
  );
};

export default ProjectNeeds;
