import React from "react";
import Modal from "react-bootstrap/Modal";

function BmcInfoModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          What Is Business Model Canvas?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The business model canvas is a great tool to help you understand a
          business model in a straightforward, structured way. Using this canvas
          will lead to insights about the customers you serve, what value
          propositions are offered through what channels, and how your company
          makes money. You can also use the business model canvas to understand
          your own business model or that of a competitor! The Business Model
          Canvas was created by Alexander Osterwalder, of Strategyzer.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="create-project-btn m-3 btn" onClick={props.onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default BmcInfoModal;
