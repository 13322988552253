/*eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import ProfileSideBar from "../components/profile/reusableComponents/ProfileSideBar";
import ProfileSummary from "../components/profile/ProfileSummary";
import ProfileNetwork from "../components/profile/ProfileNetwork";
import ProfileGoals from "../components/profile/ProfileGoals";
import ProfileInventories from "../components/profile/ProfileInventories";
import EditProfile from "../components/EditProfile";

import {
  checkLoggedInStatus,
  fetchProfile,
  updateProfile,
  createCoreValue,
  fetchCoreValue,
  fetchGoals,
  createGoals,
  createInventory,
  fetchInventory,
  createProject,
} from "../redux";
import _ from "lodash";
import Navbar from "../components/navbar/Navbar";
import "../stylesheets/profiles.css";
import "../stylesheets/projects.css";
import {
  createMyNetwork,
  fetchMyNetwork,
} from "../redux/MyNetwork/myNetworkActions";
import ProfileProjects from "../components/profile/ProfileProjects";
import ProfileSuggestions from "../components/profile/ProfileSuggestions";
import Avatar from "@mui/material/Avatar";
import styles from "../stylesheets/cssModules/profile.module.css";
import { Button, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { emphasize } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});
const Profile = ({
  isLoggedIn,
  fetchProfile,
  profile,
  updateProfile,
  createCoreValue,
  fetchCoreValue,
  createGoals,
  fetchGoals,
  createMyNetwork,
  createProject,
  fetchMyNetwork,
}) => {
  const [id, setId] = useState(null);
  const [open, setOpen] = useState(false);
  const [tabName, setTabName] = useState("summary");
  const [coreValues, setCoreValues] = useState({});
  const [goals, setGoals] = useState({});
  const [myNetwork, setMyNetwork] = useState({});
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    isLoggedIn().then((res) => {
      setId(res.payload.profile_id);
      fetchProfile(res.payload.profile_id)
        .then((res) => {
          setProjects(res.payload.projects);
          fetchCoreValue(res.payload.profile.id).then((results) => {
            setCoreValues(results.payload);
          });
          fetchGoals(res.payload.profile.id).then((results) => {
            setGoals(results.payload);
          });
          fetchMyNetwork(res.payload.profile.id).then((results) => {
            setMyNetwork(results.payload);
          });
        })
        .catch((e) => "Internal Server Error");
    });
  }, [isLoggedIn, fetchProfile, fetchCoreValue, fetchGoals, fetchMyNetwork]);

  const handleClick = (event) => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleCoreValue = (profileCoreValue) => {
    createCoreValue(id, profileCoreValue).then((res) => {
      fetchCoreValue(id).then((results) => {
        setCoreValues(results.payload);
      });
    });
  };

  const handleGoal = (goal) => {
    createGoals(id, goal).then((res) => {
      fetchGoals(id).then((results) => {
        setGoals(results.payload);
      });
    });
  };

  const handleNetwork = (contact) => {
    createMyNetwork(id, contact).then((res) => {
      fetchMyNetwork(id).then((results) => {
        setMyNetwork(results.payload);
      });
    });
  };

  const handlRefreshProjects = () => {
    fetchProfile(id)
      .then((res) => {
        setProjects(res.payload.projects);
      })
      .catch((e) => "Internal Server Error");
  };

  const showTab = () => {
    if (!_.isEmpty(profile.data)) {
      const res = profile.data.profile;
      switch (tabName) {
        case "summary":
          return <ProfileSummary profile={res} />;
        case "goals":
          return (
            <ProfileGoals
              id={id}
              goals={goals}
              handleGoalSubmit={handleGoal}
              privacy={true}
            />
          );
        case "networks":
          return (
            <ProfileNetwork
              myNetwork={myNetwork}
              handleNetworkSubmit={handleNetwork}
              privacy={true}
            />
          );

        case "inventories":
          return <ProfileInventories id={id} privacy={true} />;
        case "projects":
          return (
            <ProfileProjects
              projects={projects}
              privacy={true}
              projectCategories={profile.data.project_categories}
              handlRefreshProjects={handlRefreshProjects}
            />
          );
        case "suggestions":
          return <ProfileSuggestions profile={profile.data} />;
        default:
          return <ProfileSummary profile={res} />;
      }
    }
  };
  const handleLinkClick = (event, target) => {
    event.preventDefault(); // Stop the default navigation

    // Open the link in a new tab with desired features (optional)
    window.open(`${target}`, "_blank", "noopener,noreferrer");
  };

  const showData = () => {
    if (!_.isEmpty(profile.data)) {
      const res = profile.data.profile;

      console.log(res);
      return (
        <>
          <EditProfile
            isOpen={open}
            closeModal={closeModal}
            currentUser={profile.data.profile}
            updateProfile={updateProfile}
          />
          <div className={styles.main_container}>
            <ProfileSideBar setTab={setTabName} tab={tabName} />
            <div className={styles.main_display_area}>
              <div className={styles.header_image_area}>
                <div className={styles.profile_edit_button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={openModal}
                  >
                    Edit Profile
                  </Button>
                </div>
              </div>

              <div className={styles.profile_image_area}>
                {res.profile_avatar.url !== null ? (
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                  >
                    <Avatar
                      alt={`${res.first_name} ${res.last_name}`}
                      src={`${res.profile_avatar.url}`}
                      sx={{ width: 130, height: 130, border: "1px solid #fff" }}
                    />
                  </StyledBadge>
                ) : (
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                  >
                    <Avatar
                      src="/broken-image.jpg"
                      sx={{ width: 130, height: 130, border: "1px solid #fff" }}
                    />
                  </StyledBadge>
                )}
                <div className={styles.profile_name_container}>
                  <h2 className={styles.actual_profile_name}>
                    {res.first_name} {res.last_name}
                  </h2>
                  {res.role !== null && (
                    <h4 className={styles.profile_role}> {res.role}</h4>
                  )}
                </div>
                <div className={styles.social_media_icons}>
                  {res.facebook_url !== null && (
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{
                        margin: "5px",
                      }}
                      startIcon={<FacebookIcon />}
                      onClick={(e) => handleLinkClick(e, res.facebook_url)}
                    >
                      Facebook
                    </Button>
                  )}
                  {res.instagram_url !== null && (
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{
                        margin: "5px",
                      }}
                      startIcon={<InstagramIcon />}
                      onClick={(e) => handleLinkClick(e, res.instagram_url)}
                    >
                      {" "}
                      Instagram
                    </Button>
                  )}
                  {res.twitter_url !== null && (
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{
                        margin: "5px",
                      }}
                      startIcon={<TwitterIcon />}
                      onClick={(e) => handleLinkClick(e, res.twitter_url)}
                    >
                      Twitter
                    </Button>
                  )}
                </div>
              </div>
              <Divider
                sx={{
                  marginTop: "-100px",
                }}
              />
              <div className={styles.profile_nav}>
                <div role="presentation" onClick={handleClick}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                      component="a"
                      href="#"
                      label="DashBoard"
                      icon={<HomeIcon fontSize="medium" />}
                    />
                    <StyledBreadcrumb
                      component="a"
                      href="#"
                      label="Profile"
                      icon={<AccountCircleIcon fontSize="medium" />}
                    />
                    <StyledBreadcrumb
                      label={tabName}
                      deleteIcon={
                        <ExpandMoreIcon
                          fontSize="medium"
                          sx={{
                            color: "white",
                          }}
                        />
                      }
                      onDelete={handleClick}
                      sx={{
                        backgroundColor: "purple",
                        color: "white",
                      }}
                    />
                  </Breadcrumbs>
                </div>
              </div>
              <Divider />
              {showTab()}
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <Navbar />
      {showData()}
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedIn: () => dispatch(checkLoggedInStatus()),
  fetchProfile: (id) => dispatch(fetchProfile(id)),
  updateProfile: (id, data) => dispatch(updateProfile(id, data)),
  createCoreValue: (id, data) => dispatch(createCoreValue(id, data)),
  fetchCoreValue: (id) => dispatch(fetchCoreValue(id)),
  createGoals: (id, data) => dispatch(createGoals(id, data)),
  fetchGoals: (id) => dispatch(fetchGoals(id)),
  createInventory: (id, data) => dispatch(createInventory(id, data)),
  fetchInventories: (id) => dispatch(fetchInventory(id)),
  createMyNetwork: (id, data) => dispatch(createMyNetwork(id, data)),
  fetchMyNetwork: (id) => dispatch(fetchMyNetwork(id)),
  createProject: (data) => dispatch(createProject(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
