import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { UseEditProjectDiscussion } from "../../../hooks/project/singleProject";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";

function EditDiscussion({
  isEditOpen,
  projectID,
  setIsEditOpen,
  closeEditModal,
  discussionEditState,
}) {
  // === MAKE THE FORM CONTROLLED ===
  const [formState, setFormState] = useState({
    discussion: "",
    idea: "",
    purpose: "",
    discussionID: null,
  });

  useEffect(() => {
    setFormState({
      discussion: discussionEditState.discussion,
      idea: discussionEditState.idea,
      purpose: discussionEditState.purpose,
      discussionID: discussionEditState.discussion_id,
    });
  }, [discussionEditState]);

  const editProjectDiscussionMutation = UseEditProjectDiscussion(() =>
    setIsEditOpen(false)
  );

  // === SUBMIT THE FORM ===
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const editData = {
      discussionData: {
        ...formState,
      },
    };
    const data = {
      editData,
      projectID,
      discussionID: formState.discussionID,
    };
    editProjectDiscussionMutation.mutate(data);
  };

  return (
    <Modal show={isEditOpen} onHide={closeEditModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#047C7C" }}
        closeButton
      >
        <Modal.Title>Edit Discussion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {editProjectDiscussionMutation.isError && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <Alert variant="filled" severity="error">
              Something went wrong while editing the discussion.
            </Alert>
          </div>
        )}
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="need">Title of discussion</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={discussionEditState.discussion}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, discussion: data });
              }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="idea">Idea</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={discussionEditState.idea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, idea: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="purpose">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={discussionEditState.purpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, purpose: data });
              }}
            />
          </div>

          <div className="form-group float-right">
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={editProjectDiscussionMutation.isLoading && true}
            >
              {editProjectDiscussionMutation.isLoading
                ? "Editing..."
                : "Edit Discussion"}
            </Button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
}

export default EditDiscussion;
