import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ideaIcon from "../../../images/idea.png";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";

const ProjectSuggestionModal = ({
  closeModal,
  isOpen,
  handleSubmit,
  createNewsuggestionMutation,
}) => {
  const [idea, setIdea] = useState("");
  const [purpose, setPurpose] = useState("");
  const [details, setDetails] = useState("");

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const suggestion = {
      project_suggestion: {
        idea: idea,
        purpose: purpose,
        details: details,
      },
    };
    handleSubmit(suggestion);
    setIdea("");
    setPurpose("");
    setDetails("");
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#309897" }}
        closeButton
      >
        <Modal.Title>Create New Project Suggestion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {createNewsuggestionMutation.isError && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Alert variant="filled" severity="error">
              Something went wrong while creating the suggetion
            </Alert>
          </div>
        )}
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="description">Suggestion</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={details}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDetails(data);
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="idea">
              <img className="psb-idea-icon" src={ideaIcon} alt="idea-icon" />
              Idea
            </label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={idea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setIdea(data);
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={purpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setPurpose(data);
              }}
            />
          </div>

          <div>
            <hr />
            <div className="form-group float-right">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={createNewsuggestionMutation.isLoading ? true : false}
              >
                {createNewsuggestionMutation.isLoading
                  ? "Creating suggestion..."
                  : "Create Suggestion"}
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectSuggestionModal;
