import React, { useState, Fragment, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";

import { fetchProjectTopTenFields } from "../../../redux";
import { UseUpdateProjectTop10 } from "../../../hooks/project/singleProject";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";

const ProjectTopTenEditModal = ({
  isOpen,
  closeEditModal,
  name,
  desc,
  pId,
  ttId,
  fetchFields,
}) => {
  const [title, setTitle] = useState(name);
  const [description, setDescription] = useState(desc);
  const [inputFields, setInputFields] = useState([]);

  useEffect(() => {
    fetchFields(pId, ttId)
      .then((response) => {
        const fetched = response.payload.result;
        setInputFields(fetched);
      })
      .catch((error) => console.log("Internal Application Error"));
  }, [fetchFields, pId, ttId]);

  const handleChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "field_name") {
      values[index].field_name = event.target.value;
    } else {
      values[index].field_type = event.target.value;
    }

    setInputFields(values);
  };

  const handleAddField = () => {
    let values = [...inputFields];
    if (values.length < 5) {
      values.push({ field_name: "", field_type: "" });
    } else {
      alert("You cannot create more than 6 fields");
    }

    setInputFields(values);
  };

  const handleRemoveField = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const combineFields = () => {
    const values = [...inputFields];
    return values;
  };

  const updateTop10Mutation = UseUpdateProjectTop10(closeEditModal);
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const project_fields_attributes = combineFields();
    const data = {
      top10Data: {
        title: title,
        description: description,
        project_fields_attributes: project_fields_attributes,
      },
      projectID: pId,
      pttID: ttId,
    };
    updateTop10Mutation.mutate(data);
  };

  return (
    <>
      <ToastContainer />
      <Modal show={isOpen} onHide={closeEditModal}>
        <Modal.Header
          className="text-white"
          style={{ backgroundColor: "#309897" }}
          closeButton
        >
          <Modal.Title>Edit Top10</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {updateTop10Mutation.isError && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <Alert variant="filled" severity="error">
                Something went wrong while editing the top 10.
              </Alert>
            </div>
          )}
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <CKEditor
                className="form-control"
                editor={ClassEditor}
                data={title}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTitle(data);
                }}
                label="title"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <CKEditor
                className="form-control"
                editor={ClassEditor}
                data={description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
                label="description"
                required
              />
            </div>
            <div>
              <div className="form-group mt-2 d-flex justify-content-between">
                <h5 className="pt-2">Edit Fields</h5>
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={() => handleAddField()}
                >
                  +
                </button>
              </div>
              <div className="row mx-auto">
                {inputFields.map((field, idx) => (
                  <Fragment key={`frag-${idx}`}>
                    <div className="form-group col-md-6 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        id="field_name"
                        name="field_name"
                        value={field.field_name}
                        placeholder="field name"
                        onChange={(event) => handleChange(idx, event)}
                        required
                        disabled={field.field_name === "Name"}
                      />
                    </div>
                    <div className="form-group col-md-4 mt-2">
                      <select
                        className="form-control"
                        name="field_type"
                        id="field_type"
                        value={field.field_type}
                        onChange={(event) => handleChange(idx, event)}
                        disabled={field.field_name === "Name"}
                      >
                        <option value="DEFAULT" disabled>
                          -- field type --
                        </option>
                        <option value="text">text</option>
                        <option value="number">number</option>
                        <option value="url">link</option>
                        <option value="date">date</option>
                      </select>
                    </div>
                    <div className="form-group col-md-2">
                      <button
                        className="btn btn-danger mt-2"
                        type="button"
                        onClick={() => handleRemoveField(idx)}
                        disabled={field.field_name === "Name"}
                      >
                        -
                      </button>
                    </div>
                  </Fragment>
                ))}
              </div>
              <hr />
              <div className="form-group float-right">
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={updateTop10Mutation.isLoading ? true : false}
                  type="submit"
                >
                  {updateTop10Mutation.isLoading
                    ? "Editing project top10..."
                    : "  Edit project top10"}
                </Button>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <h5>TUC SYSTEM</h5>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchFields: (pId, ttId) => dispatch(fetchProjectTopTenFields(pId, ttId)),
});

export default connect(null, mapDispatchToProps)(ProjectTopTenEditModal);
