import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const ProjectQuickLinkModal = ({ isOpen, closeModal, handleSubmit }) => {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      url: link,
    };
    handleSubmit(data);
  };

  return (
    <>
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header
          className="text-white"
          style={{ backgroundColor: "#047C7C" }}
          closeButton
        >
          <Modal.Title>Add Quick Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                className="form-control"
                name="name"
                type="text"
                placeholder="type name here"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="link">Link</label>
              <input
                className="form-control"
                name="link"
                type="url"
                placeholder="type url here"
                onChange={(e) => setLink(e.target.value)}
                required
              />
            </div>
            <div className="form-group float-right">
              <button className="add-btn rounded text-white" type="submit">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <h5>TUC SYSTEM</h5>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectQuickLinkModal;
