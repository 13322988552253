/*eslint-disable */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import parse from "html-react-parser";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, Divider } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Badge from "@mui/material/Badge";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { styled } from "@mui/material/styles";
import AppProfilesList from "./globals/AppProfilesList";
import CommentBox from "./globals/CommentBox";
import {
  UseCreateOfGoalCommentReponses,
  UseCreateProjectGoalComment,
  UseEditOfGoalCommentReponses,
  UseEditProjectGoalComment,
  UseFetchAllGoalComments,
  UseUnVoteOfCommentResponse,
  UseunVoteOfGoalComment,
  UseUpvoteOfCommentResponse,
  UseUpvoteOfGoalComment,
  UseCreateOfGoalCommentResponseOfResponse,
  UseUpvoteOfCommentResponseOfResponse,
  UseUnVoteOfCommentResponseOfResponse,
  UseEditOfGoalCommentReponsesOfResponses,
} from "../../hooks/project/singleProject";

const ProjectGoalDetailsCard = ({
  data,
  setOpenEditModal,
  setEditState,
  setKpiModal,
  setSelectedGoal,
  setObjectiveModal,
  setObjectiveEditState,
  setSelectedObjective,
  setObjectiveEditModal,
  projectGoalKpis,
  setEditKpiState,
  setKpiEditModal,
  setKpiID,
  like,
  dislike,
  projectID,
  kpisError,
  kpisIsLoading,
}) => {
  const [openProfiles, setOpenProfiles] = useState(false);
  const [upvotedProfiles, setUpvotedProfiles] = useState([]);
  // Function to handle the click event for creating a comment and opening the comment box
  const [commentBox, setCommentBox] = useState(false);
  const [goalID, setGoalID] = useState(null);
  const [commentBoxDetail, setCommentBoxDetail] = useState("");
  const [goalTitle, setGoalTitle] = useState("");

  // ====  EFFICIENTRY HANDLE THE SUBMIT BUTTON ====
  const [submitButton, setSubmitButton] = useState(false);
  const [editSubmitButton, setEditSubmitButton] = useState(false);

  const handleEdit = (data) => {
    setEditState(data);
    setOpenEditModal(true);
  };

  useEffect(() => {
    if (commentBox === true) setGoalID(goalID);
    if (commentBox === false) setGoalID(null);

    return () => {
      setGoalID(null);
    };
  }, [goalID, commentBox]);

  // ---- RQ QUERIES ----

  const {
    data: commentData,
    isLoading: commentIsLoading,
    isError: commentError,
  } = UseFetchAllGoalComments(projectID, goalID);

  // ---- RQ MUTATIONS ----

  const createNewCommentMutation = UseCreateProjectGoalComment(() => {
    setCommentBoxDetail("");
  });

  const editCommentMutatation = UseEditProjectGoalComment(() =>
    console.log("edited")
  );

  const upvoteOfcommentMutation = UseUpvoteOfGoalComment();
  const unvoteOfCommentMutation = UseunVoteOfGoalComment();
  const createOfCommentResponseMutation = UseCreateOfGoalCommentReponses(() =>
    console.log("down")
  );
  const editOfCommentResponseMutation = UseEditOfGoalCommentReponses(() =>
    console.log("edit")
  );
  const upvoteOfCommentResponseMutation = UseUpvoteOfCommentResponse();
  const unvoteOfCommentResponseMutation = UseUnVoteOfCommentResponse();

  const handleCreateComment = () => {
    const data = {
      project_id: projectID,
      project_goal_id: goalID,
      commentData: {
        comment: {
          content: commentBoxDetail,
          project_goal_id: goalID,
        },
      },
    };
    createNewCommentMutation.mutate(data);
  };

  const handleEditOfComment = (editState, setEditState) => {
    const { itemId, content } = editState;
    const data = {
      project_id: projectID,
      project_goal_id: goalID,
      id: itemId,
      commentData: {
        comment: {
          content: content,
          project_goal_id: goalID,
        },
      },
    };
    editCommentMutatation.mutate(data);
    setEditState({
      form: false,
      itemId: null,
      content: "",
    });
  };

  const handleUpvoteOfComment = (item) => {
    const data = {
      upvote: {
        upvoteable_id: item.id,
        upvoteable_type: "ProjectGoalComment",
      },
    };

    upvoteOfcommentMutation.mutate(data);
  };

  const handleUnvoteOfComment = (item) => {
    const data = {
      upvoteable_id: item.id,
      upvoteable_type: "ProjectGoalComment",
    };
    unvoteOfCommentMutation.mutate(data);
  };

  const handleCreateOfCommentReponses = (replyState, setReplyState) => {
    const { itemReplyId, replyContent } = replyState;
    const data = {
      project_id: projectID,
      project_goal_id: goalID,
      project_goal_comment_id: itemReplyId,
      commentData: {
        comment: {
          project_goal_comment_id: itemReplyId,
          content: replyContent,
        },
      },
    };
    createOfCommentResponseMutation.mutate(data);
    setReplyState({
      display: false,
      itemReplyId: null,
      replyContent: "",
    });
  };

  const handleEditOfCommentResponses = (
    editCommentResponse,
    setEditCommentResponses,
    commentID
  ) => {
    const { responseID, content } = editCommentResponse;

    const data = {
      project_id: projectID,
      project_goal_id: goalID,
      project_goal_comment_id: commentID,
      id: responseID,
      commentData: {
        comment: {
          project_gaol_comment_id: commentID,
          content: content,
        },
      },
    };

    editOfCommentResponseMutation.mutate(data);
    setEditCommentResponses({
      form: false,
      responseID: null,
      content: "",
    });
  };

  const handleUpvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectGoalCommentResponse",
      },
    };

    upvoteOfCommentResponseMutation.mutate(data);
  };

  const handleUnvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectGoalCommentResponse",
    };
    unvoteOfCommentResponseMutation.mutate(data);
  };

  const createCommentResponseOfResponseMutation =
    UseCreateOfGoalCommentResponseOfResponse();
  const handleCreateOfCommentReplyOfReply = async (data, commentID, state) => {
    const replyOfReplyData = {
      projectID: projectID,
      goalID: goalID,
      projectCommentID: commentID,
      projectCommentResponseID: data.itemReplyId,
      commentReplyOfReply: {
        comment: {
          response_text: data.replyContent,
        },
      },
    };

    createCommentResponseOfResponseMutation.mutate(replyOfReplyData);

    state({
      display: false,
      itemReplyId: null,
      replyContent: "",
    });
  };

  const editGoalReplyOfReplyMutation =
    UseEditOfGoalCommentReponsesOfResponses();

  const handleEditOfReplyOfReply = async (
    replyOfReplyEditState,
    commentID,
    commentResponseID,
    setReplyOfReplyEditState
  ) => {
    const replyOfReplyData = {
      projectID: projectID,
      goalID: goalID,
      projectCommentID: commentID,
      projectCommentResponseID: commentResponseID,
      ID: replyOfReplyEditState.responseID,
      commentReplyOfReplyEdit: {
        comment: {
          response_text: replyOfReplyEditState.content,
        },
      },
    };

    editGoalReplyOfReplyMutation.mutate(replyOfReplyData);

    setReplyOfReplyEditState({
      ...replyOfReplyEditState,
      form: false,
    });
  };

  const upvoteCommentGoalReplyOfReplyMutation =
    UseUpvoteOfCommentResponseOfResponse();

  const handleUpvoteOfCommentReplyOfReply = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectGoalCommentReponseOfResponse",
      },
    };
    upvoteCommentGoalReplyOfReplyMutation.mutate(data);
  };

  const downvoteCommentGoalReplyOfReplyMutation =
    UseUnVoteOfCommentResponseOfResponse();
  const handleUnvoteOfCommentReplyOfReply = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectGoalCommentReponseOfResponse",
    };
    downvoteCommentGoalReplyOfReplyMutation.mutate(data);
  };

  // === RENDER ALL PROJECT KPI FOR ANY PARTICULAR GOAL ===
  const renderProjectGoalsKpis = (objectiveID, obj, data) => {
    const result = projectGoalKpis.filter((value) => {
      if (value.id == objectiveID) return value;
    });

    if (result.length < 1) return;
    if (result[0].project_goal_objective_kpis.length < 1)
      return <div className="no-kpi-added-yet"> No KPIs added yet.</div>;

    const display = result[0].project_goal_objective_kpis.map(
      (value, index) => {
        return (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              color="secondary"
              fontSize="18px"
              fontWeight="900"
            >
              KPI {index + 1}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="secondary" />
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent
              fontSize="20px"
              marginTop="-5px"
              key={index}
              className="timeline-content-kpi-goal"
            >
              {parse(value.name)}
              <Button
                className="edit-kpi"
                variant="outlined"
                sx={{
                  marginLeft: "5px",
                }}
                onClick={() => {
                  setEditKpiState(value.name);
                  setKpiID(value.id);
                  setSelectedObjective(obj.id);
                  setSelectedGoal(data.id);
                  setKpiEditModal(true);
                }}
              >
                Edit KPI
              </Button>
            </TimelineContent>
          </TimelineItem>
        );
      }
    );

    return display;
  };

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const SmallAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
    width: 30,
    height: 30,
  }));

  const handleClose = () => {
    setOpenProfiles(false);
  };

  const handleOpen = () => {
    setOpenProfiles(true);
  };

  const handleProfileModal = (data) => {
    setUpvotedProfiles([...data.upvoted_by]);
    handleOpen();
  };

  const displayUpvotedProfiles = (data) => {
    if (data.upvoted_by.length < 1) return null;

    const profiles = () => {
      return data.upvoted_by.map((item, idx) => {
        return (
          <SmallAvatar
            key={idx}
            alt={`${item.profile.first_name} ${item.profile.last_name}`}
            src={
              item.profile.profile_avatar !== null
                ? item.profile.profile_avatar.url
                : "/broken-image.jpg"
            }
          />
        );
      });
    };

    return (
      <SmallAvatarGroup max={2} onClick={() => handleProfileModal(data)}>
        {profiles()}
      </SmallAvatarGroup>
    );
  };

  return (
    <>
      <AppProfilesList
        profiles={upvotedProfiles}
        open={openProfiles}
        handleClose={handleClose}
      />
      <CommentBox
        open={commentBox}
        setOpen={setCommentBox}
        isLoading={commentIsLoading}
        isError={commentError}
        comments={commentData ? commentData : []}
        handleCreateComment={handleCreateComment}
        detail={commentBoxDetail}
        setCommentBoxDetail={setCommentBoxDetail}
        submitButton={submitButton}
        setSubmitButton={setSubmitButton}
        label="Goal"
        labelDetails={goalTitle}
        handleEditOfComment={handleEditOfComment}
        editSubmitButton={editSubmitButton}
        refreshGoals={() => console.log("refresh")}
        handleUpvoteOfComment={handleUpvoteOfComment}
        handleUnvoteOfComment={handleUnvoteOfComment}
        handleCreateOfCommentReponses={handleCreateOfCommentReponses}
        handleEditOfCommentReponses={handleEditOfCommentResponses}
        handleUpvoteOfCommentResponses={handleUpvoteOfCommentResponses}
        handleUnvoteOfCommentResponses={handleUnvoteOfCommentResponses}
        commentBoxIdea={""}
        commentBoxPurpose={""}
        handleCreateOfCommentReplyOfReply={handleCreateOfCommentReplyOfReply}
        handleEditOfReplyOfReply={handleEditOfReplyOfReply}
        handleUpvoteOfCommentReplyOfReply={handleUpvoteOfCommentReplyOfReply}
        handleUnvoteOfCommentReplyOfReply={handleUnvoteOfCommentReplyOfReply}
      />
      <div className="card border-bottom rounded button-goal-collapse">
        <div className="card-header justify-content-between goal-coording-operator">
          <div className="goal-coording-operator" id={`headingOne${data.id}`}>
            <div className="mb-0 project-goal-display">
              <span className="goal-bar-display">Goal:</span>{" "}
              <span> {parse(data.project_goal)}</span>
            </div>
          </div>
        </div>
        {/* ---- buttons container --- */}
        <div className="button_group_goal">
          <Button
            startIcon={<ArrowCircleDownIcon />}
            data-toggle="collapse"
            data-target={`#collapseOne${data.id}`}
            aria-expanded="false"
            aria-controls={`collapseOne${data.id}`}
            variant="contained"
            size="small"
            sx={{
              height: "35px",
            }}
          >
            View Objectives({data.project_goal_objectives.length})
          </Button>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {displayUpvotedProfiles(data)}
            {data.likes.upvoted ? (
              <IconButton onClick={() => dislike(data)}>
                <Badge badgeContent={data.likes.counter} color="secondary">
                  <FavoriteIcon style={{ color: "red", fontSize: "30px" }} />
                </Badge>
              </IconButton>
            ) : (
              <IconButton onClick={() => like(data)}>
                <Badge badgeContent={data.likes.counter} color="secondary">
                  <FavoriteBorderOutlinedIcon
                    style={{ color: "#000", fontSize: "30px" }}
                  />
                </Badge>
              </IconButton>
            )}
          </div>

          <IconButton
            variant="outlined"
            onClick={() => {
              setGoalID(data.id);
              setGoalTitle(data.project_goal);
              setCommentBox(true);
            }}
          >
            <Badge badgeContent={data.comments_counter} color="secondary">
              <ChatBubbleOutlineOutlinedIcon
                style={{ color: "#000", fontSize: "30px" }}
              />
            </Badge>
          </IconButton>

          <IconButton
            onClick={() => handleEdit(data)}
            sx={{
              zIndex: 1,
            }}
          >
            <EditIcon style={{ color: "#000", fontSize: "30px" }} />
          </IconButton>
        </div>
        <Divider />
        <div
          id={`collapseOne${data.id}`}
          className="collapse"
          aria-labelledby={`headingOne${data.id}`}
          data-parent={`#goalAccordian`}
        >
          <div className="card-body border-bottom">
            <Button
              size="small"
              color="secondary"
              onClick={() => {
                setSelectedGoal(data.id);
                setObjectiveModal(true);
              }}
              startIcon={<AddCircleIcon style={{ fontSize: "20px" }} />}
              variant="contained"
            >
              Add Objective
            </Button>
            {!_.isEmpty(data.project_goal_objectives) ? (
              <ul className="objectives-display">
                {data.project_goal_objectives.map((obj, index) => (
                  <div className="card" key={obj.id}>
                    <div className="card-body">
                      {" "}
                      <div>
                        <span className="objective-bar">
                          Objective {index + 1}:
                        </span>{" "}
                        <span className="objective-it-self">
                          {parse(obj.name)}
                        </span>
                      </div>
                      <div className="button_group">
                        <Button
                          className="mr-2"
                          variant="outlined"
                          color="warning"
                          onClick={() => {
                            setObjectiveEditState(obj.name);
                            setSelectedObjective(obj.id);
                            setSelectedGoal(data.id);
                            setObjectiveEditModal(true);
                          }}
                          endIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddCircleIcon />}
                          color="warning"
                          onClick={() => {
                            setSelectedObjective(obj.id);
                            setSelectedGoal(data.id);
                            setKpiModal(true);
                          }}
                        >
                          Kpi's
                        </Button>
                      </div>
                    </div>
                    <div className="card-footer">
                      {kpisIsLoading === false && kpisError === null && (
                        <Timeline
                          sx={{
                            [`& .${timelineOppositeContentClasses.root}`]: {
                              flex: 0.05,
                            },
                          }}
                          className="timeline-kpi-goal-project"
                        >
                          {renderProjectGoalsKpis(obj.id, obj, data)}
                        </Timeline>
                      )}
                    </div>
                  </div>
                ))}
              </ul>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: "uppercase",
                  color: "red",
                  fontWeight: "800",
                }}
              >
                <h5>There are no objectives</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectGoalDetailsCard;
