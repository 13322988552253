// ==== THIS FILES HANDLES QUERIES FOR ALL PROJECTS CONTAINER ====
import { useQuery } from "react-query";
import { fetchUser } from "../../services/users/userService";

const UseFetchUser = () => {
  const fetchSingleUser = async () => {
    const result = await fetchUser();
    return result;
  };
  return useQuery({
    queryKey: ["user"],
    queryFn: fetchSingleUser,
  });
};

export { UseFetchUser };
