import React from "react";
import _ from "lodash";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import styles from "./gallery/css/index.module.css";
import { Button } from "@mui/material";

const ProjectGallery = ({
  setAddImageModal,
  galleryData,
  galleryIsLoading,
  galleryError,
}) => {
  if (galleryIsLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );

  if (galleryError)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Alert variant="filled" severity="error">
          Something went wrong while fetching gallery
        </Alert>
      </div>
    );

  const displayImages = () => {
    return galleryData?.images.map((value, index) => {
      return value === null ? null : (
        <ImageListItem key={index}>
          <img
            className={styles.image_list_item}
            srcSet={value}
            src={value}
            alt={`view_${index}`}
            loading="lazy"
          />
        </ImageListItem>
      );
    });
  };

  return (
    <div className="profile-core-values container-m-well mt-4 mb-2">
      <Button
        variant="contained"
        color="secondary"
        startIcon={<AddIcon />}
        onClick={() => setAddImageModal(true)}
      >
        Add Images
      </Button>

      <div className="container-fluid">
        {_.isEmpty(galleryData?.images) ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Alert variant="filled" severity="warning">
              There are no images at the moment
            </Alert>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ImageList
              sx={{ width: 500, height: 450 }}
              cols={3}
              rowHeight={164}
            >
              {displayImages()}
            </ImageList>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectGallery;
