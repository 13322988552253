// ==== THIS FILES HANDLES QUERIES FOR ALL NETWORK CONTAINER ====
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  addNewNetworkMember,
  addProjectToNetwork,
  createNewNetwork,
  editNetwork,
  getAllNetworks,
  getAllProjectsForNetwork,
  getShowNetworkPageData,
} from "../../services/NetworksService";

// --- THIS FETCHES ALL THE NETWORKS ----
const UseFetchAllNetworks = () => {
  const fetchAllNetworks = async () => {
    const { result } = await getAllNetworks();
    return result;
  };
  return useQuery({
    queryKey: ["networks"],
    queryFn: fetchAllNetworks,
  });
};

const UseFetchAllProjectsForNetwork = (networkId) => {
  const fetchAllProjectsForNetwork = async () => {
    const { result } = await getAllProjectsForNetwork(networkId);
    return result;
  };
  return useQuery({
    queryKey: ["networks", "project_networks", networkId],
    queryFn: fetchAllProjectsForNetwork,
  });
};

const UseFetchNetworkShowPage = (pageID) => {
  const fetchNetworkShowPage = async () => {
    const { result } = await getShowNetworkPageData(pageID);
    return result;
  };
  return useQuery({
    queryKey: ["networks", pageID],
    queryFn: fetchNetworkShowPage,
  });
};

// --- MUTATION OF DATA ----
const UseCreateNetwork = (fn) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (networkData) => createNewNetwork(networkData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["networks"],
      });
      fn();
    },
  });
};

const UseAddProjectsToNetwork = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (networkProjectData) =>
      addProjectToNetwork(networkProjectData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["networks"],
      });
      fn();
    },
  });
};

const UseEditNetwork = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (networkData) => editNetwork(networkData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["networks"],
      });
      fn();
    },
  });
};

const UseAddMemberToNetwork = (fn) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (memberData) => addNewNetworkMember(memberData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["networks"],
      });
      fn();
    },
  });
};

export {
  UseFetchAllNetworks,
  UseFetchAllProjectsForNetwork,
  UseFetchNetworkShowPage,
  UseCreateNetwork,
  UseAddProjectsToNetwork,
  UseEditNetwork,
  UseAddMemberToNetwork,
};
