import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { Button } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import _ from "lodash";
import { UseAddCollaboratorsToProject } from "../../hooks/project/singleProject";

const ProjectCollabModal = ({
  isOpen,
  closeModal,
  users,
  allProfilesLoading,
  allProfilesError,
  project_id,
  projectData,
}) => {
  const [user, setUser] = useState([]);
  const [value, setValue] = useState(null);

  const resetFormData = () => {
    setUser([]);
    closeModal();
  };
  const addCollaboratorsMutation = UseAddCollaboratorsToProject(resetFormData);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (_.isEmpty(user)) return;

    const result = _.uniq(user);
    result.forEach((value) => {
      const data = {
        collaboratorsData: {
          collaboration: {
            user_id: value,
            project_id: project_id,
          },
        },
        project_id,
      };
      addCollaboratorsMutation.mutate(data);
    });
  };

  const displayForm = () => {
    if (allProfilesLoading)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      );

    if (allProfilesError)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert variant="filled" severity="error">
            Something went wrong while fetching the profiles
          </Alert>
        </div>
      );

    const { project_collaborators } = projectData;
    const displayProfilesData = users.filter(
      (obj1) =>
        !project_collaborators.some((obj2) => obj2.user_id === obj1.user_id)
    );

    const handleSearch = () => {
      if (value === null) return displayProfilesData;
      // eslint-disable-next-line array-callback-return
      return displayProfilesData.filter((obj) => {
        const sortname = `${obj.first_name} ${obj.last_name}`;
        if (sortname === value) return [obj];
      });
    };

    return (
      <form>
        <div className="form-group">
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            value={value}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setValue(newValue);
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setValue(newValue.inputValue);
              } else {
                setValue(newValue);
              }
            }}
            options={displayProfilesData.map(
              (u) => `${u.first_name} ${u.last_name}`
            )}
            renderInput={(params) => (
              <TextField {...params} label="Search User" />
            )}
            sx={{
              marginBottom: "25px",
            }}
          />
          <FormGroup>
            {handleSearch().map((u) => {
              return (
                <FormControlLabel
                  key={u.id}
                  required
                  control={
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          setUser([...user, u.user_id]);
                          return;
                        }
                      }}
                    />
                  }
                  label={`${u.first_name} ${u.last_name}`}
                  value={u.id}
                />
              );
            })}
          </FormGroup>
        </div>
      </form>
    );
  };

  return (
    <>
      <ToastContainer />
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header
          className="text-white"
          style={{ backgroundColor: "#047C7C" }}
          closeButton
        >
          <Modal.Title>Add Collaborator</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            height: "400px",
            minHeight: "400px",
            maxHeight: "400px",
            overflowY: "scroll",
          }}
        >
          {addCollaboratorsMutation.isError && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Alert variant="filled" severity="error">
                Something went wrong while adding the collaborators!!!
              </Alert>
            </div>
          )}
          {displayForm()}
        </Modal.Body>
        <Modal.Footer>
          <div className="form-group">
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={addCollaboratorsMutation.isLoading ? true : false}
              onClick={handleFormSubmit}
            >
              {addCollaboratorsMutation.isLoading
                ? "Adding collaborators...."
                : " Add Collaborators"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ProjectCollabModal;
