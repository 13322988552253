/* eslint-disable no-unused-vars */
import React from "react";
import visionicon from "../../assets/vision-icon.png";
import missionicon from "../../assets/mission-icon.png";
import ideaicon from "../../assets/idea.png";
import ProjectIPMCard from "./ProjectIPMCard";
import AppTimeLine from "../reusablecomponents/AppTimeLine";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import Button from "@mui/material/Button";
import styles from "./globals/TopProjectBar.module.css";
import SingleProjectNetwork from "./globals/SingleProjectNetwork";

const ProjectSummary = ({ project, projectSdgs, handleSDG }) => {
  return (
    <>
      <div className="s-project-summary container-m-well">
        <div className="s-project-summary-details shadow-lg">
          <ProjectIPMCard
            icon={ideaicon}
            title={"Idea"}
            details={project.idea}
          />
          <ProjectIPMCard
            icon={visionicon}
            title={"Purpose"}
            details={project.purpose}
          />
          <ProjectIPMCard
            icon={missionicon}
            title={"Mission"}
            details={project.mission}
          />
          <ProjectIPMCard
            title={"Sustainable Development Goals"}
            projectSdgs={projectSdgs}
            sdgsEditButton={true}
            handleSDG={handleSDG}
            icon={missionicon}
          />
        </div>
        <div className="s-project-summary-feed m-2 ">
          {/* --- NETWORKS --- */}
          <div>
            <SingleProjectNetwork project={project} />
          </div>
          <div className={styles.timeline_container}>
            <div className={styles.timeline_top_bar}>
              <AccessTimeTwoToneIcon /> <h6>Timeline</h6>
            </div>

            <AppTimeLine time={project.created_at} />

            <div className={styles.time_line_button_bar}>
              <Button variant="contained" color="secondary">
                view full timeline
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSummary;
