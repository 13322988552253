import { BASEURL } from "../../API/apiData";

const getAllReplyComments = async (
  projectID,
  discussionID,
  projectCommentID
) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/discussion/${discussionID}/project_comments/${projectCommentID}/project_comment_responses`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewReplyComments = async (
  projectID,
  discussionID,
  projectCommentID,
  data
) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/discussion/${discussionID}/project_comments/${projectCommentID}/project_comment_responses`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewReplyOfReply = async (data) => {
  const token = localStorage.getItem("token");
  const {
    projectID,
    discussionID,
    projectCommentID,
    projectCommentResponseID,
    commentReplyOfReply,
  } = data;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentReplyOfReply),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/discussion/${discussionID}/project_comments/${projectCommentID}/project_comment_responses/${projectCommentResponseID}/project_comment_response_of_responses`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editReplyOfReplyComment = async (data) => {
  const token = localStorage.getItem("token");
  const {
    projectID,
    discussionID,
    projectCommentID,
    projectCommentResponseID,
    ID,
    commentReplyOfReplyEdit,
  } = data;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentReplyOfReplyEdit),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/discussion/${discussionID}/project_comments/${projectCommentID}/project_comment_responses/${projectCommentResponseID}/project_comment_response_of_responses/${ID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export {
  getAllReplyComments,
  createNewReplyComments,
  createNewReplyOfReply,
  editReplyOfReplyComment,
};
