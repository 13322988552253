import React from "react";
import styles from "../../stylesheets/cssModules/profile.module.css";
import ProfileMVCard from "./ProfileMVCard";
import visionicon from "../../assets/vision-icon.png";
import missionicon from "../../assets/mission-icon.png";
import ProfileBadges from "./ProfileBadges";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import LanguageIcon from "@mui/icons-material/Language";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

const ProfileSummary = ({ profile }) => {
  return (
    <div className={`container-fluid ${styles.profile_summary_container} `}>
      <div className="row">
        <div className="col-md-8">
          <ProfileMVCard
            title={"vision"}
            icon={visionicon}
            details={profile.vision}
          />
          <ProfileMVCard
            title={"Mission"}
            icon={missionicon}
            details={profile.mission}
          />
        </div>
        <div className="col-md-4">
          <div className={styles.badges_container}>
            <h4
              style={{
                textTransform: "capitalize",
                fontWeight: 800,
                margin: "5px",
                position: "relative",
                top: "10px",
                left: "12px",
              }}
            >
              Badges
            </h4>
            <ProfileBadges userId={profile.user_id} />
          </div>
          <div
            className={styles.badges_container}
            style={{
              marginTop: "30px",
            }}
          >
            <h4
              style={{
                textTransform: "capitalize",
                fontWeight: 800,
                margin: "5px",
                position: "relative",
                top: "10px",
                left: "12px",
              }}
            >
              Contacts
            </h4>

            <Stack
              className="container-fluid"
              sx={{ width: "100%" }}
              spacing={2}
              style={{
                marginTop: "20px",
              }}
            >
              {profile.country !== null && (
                <Alert
                  variant="filled"
                  severity="success"
                  icon={<LanguageIcon />}
                >
                  <span
                    style={{
                      fontWeight: "800",
                      textTransform: "uppercase",
                      marginTop: "3px",
                    }}
                  >
                    Country:{" "}
                  </span>
                  <span
                    style={{
                      fontWeight: "800",
                      textTransform: "uppercase",
                      marginTop: "3px",
                      marginLeft: "20px",
                    }}
                  >
                    {profile.country}
                  </span>
                </Alert>
              )}
              {profile.address !== null && (
                <Alert
                  variant="filled"
                  severity="info"
                  icon={<ContactMailIcon />}
                >
                  <span
                    style={{
                      fontWeight: "800",
                      textTransform: "uppercase",
                      marginTop: "3px",
                    }}
                  >
                    Address:{" "}
                  </span>
                  <span
                    style={{
                      fontWeight: "800",
                      textTransform: "uppercase",
                      marginTop: "3px",
                      marginLeft: "20px",
                    }}
                  >
                    {profile.address}
                  </span>
                </Alert>
              )}
              {profile.contact_no !== null && (
                <Alert
                  variant="filled"
                  severity="warning"
                  icon={<PhoneAndroidIcon />}
                >
                  <span
                    style={{
                      fontWeight: "800",
                      textTransform: "uppercase",
                      marginTop: "3px",
                    }}
                  >
                    phone No:{" "}
                  </span>

                  <span
                    style={{
                      fontWeight: "800",
                      textTransform: "uppercase",
                      marginTop: "3px",
                      marginLeft: "20px",
                    }}
                  >
                    {profile.contact_no}
                  </span>
                </Alert>
              )}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSummary;
