import React from "react";
import { Link } from "react-router-dom";

const ToggleNav = ({ handleLogout, showToggleNavbar }) => {
  return (
    <div className="toggle-nav shadow-lg">
      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        {" "}
        <Link to={"/"}>
          <i className="fas fa-home"></i>&nbsp;&nbsp;Home
        </Link>
      </button>
      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        {" "}
        <Link to={"/dashboard"}>
          <i className="fas fa-compass"></i>&nbsp;&nbsp;Dashboard
        </Link>
      </button>
      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        <Link to={"/projects"}>
          <i className="fas fa-project-diagram"></i>&nbsp;&nbsp;Projects
        </Link>
      </button>
      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        <Link to={"/networks"}>
          <i className="fab fa-megaport"></i>&nbsp;&nbsp; Networks
        </Link>
      </button>
      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        <Link to={"/suggestion"}>
          {" "}
          <i className="fas fa-box-open"></i>&nbsp;&nbsp;Suggestion Box
        </Link>
      </button>
      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        {" "}
        <Link to={"/engagement_manager"}>
          <i className="fa fa-area-chart" aria-hidden="true"></i>
          &nbsp;&nbsp;Engagement Manager
        </Link>
      </button>
      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        <Link to={"/categories"}>
          <i className="fas fa-list-ol"></i>&nbsp;&nbsp;Top10 System
        </Link>
      </button>
      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        <Link to={"/roles"}>
          <i className="fas fa-sitemap"></i>&nbsp;&nbsp;1/10 System
        </Link>
      </button>
      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        <Link to={"/openitems"}>🚩🚩 RedFlag System 🚩🚩</Link>
      </button>
      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        <Link to={"/users"}>
          <i className="fas fa-users"></i>&nbsp;&nbsp;Users
        </Link>
      </button>
      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        <Link to={"/updates"}>
          <i className="fas fa-bullhorn"></i>&nbsp;&nbsp;Release Notes
        </Link>
      </button>
      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        {" "}
        <Link to={"/tags"}>
          <i className="fas fa-tag"></i>&nbsp;&nbsp;Tags
        </Link>
      </button>

      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        <Link to={"/legend"}>
          <i className="fas fa-book-open"></i>&nbsp;&nbsp;Legend
        </Link>
      </button>

      <button type="button" className="btn-wrapper" onClick={showToggleNavbar}>
        <Link to={"/financials"}>
          <i className="fas fa-file-invoice-dollar"></i>&nbsp;&nbsp;Financials
        </Link>
      </button>
    </div>
  );
};

export default ToggleNav;
