import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { Divider } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

import { styled } from "@mui/material/styles";
import { UseCreateNewProjectGallery } from "../../../hooks/project/singleProject";
// or

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const AddImagesModal = ({ addImageModal, setAddImageModal }) => {
  const { id } = useParams();
  const [selectedImages, setSelectedImages] = useState([]);
  const [images, setImages] = useState([]);
  const [filesImages, setFilesImages] = useState([]);

  useEffect(() => {
    setFilesImages([...images]);
  }, [images]);

  const handleClose = () => {
    setAddImageModal(false);
  };

  // --- RQ ----
  const createGalleryMutation = UseCreateNewProjectGallery(handleClose);

  const handleUploadClick = (event) => {
    var file = event.target.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        setSelectedImages([...selectedImages, reader.result]);
        setImages([...images, file]);
      };
    }
  };

  const handleCreateNewGallery = () => {
    // Create a new gallery
    const gallerFormData = new FormData();

    filesImages.forEach((file, index) => {
      let propertyName = `image_${index + 1}`;
      gallerFormData.append(`project_files[${propertyName}]`, file);
    });
    const data = {
      project_id: id,
      gallerFormData,
    };

    createGalleryMutation.mutate(data);
  };

  const displayImages = () => {
    if (selectedImages.length < 1) return;

    return (
      <ImageList
        sx={{ width: 400, height: 350, marginTop: "20px" }}
        cols={3}
        rowHeight={164}
      >
        {selectedImages.map((item, index) => (
          <ImageListItem key={index}>
            <img srcSet={`${item}`} src={`${item}`} alt={item} loading="lazy" />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };
  return (
    <React.Fragment>
      <Dialog
        maxWidth={"md"}
        open={addImageModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            color: "#000",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          Add Images (maximum 6 images)
        </DialogTitle>

        <Divider />
        <DialogContent
          sx={{
            minHeight: "300px",
            minWidth: "100%",
          }}
        >
          <Button
            component="label"
            role={undefined}
            startIcon={<AddAPhotoIcon fontSize="large" />}
            variant="contained"
            tabIndex={-1}
            sx={{
              width: "100%",
              minHeight: "50px",
            }}
            disabled={
              selectedImages.length > 6 || selectedImages.length === 6
                ? true
                : false
            }
          >
            {selectedImages.length > 6 || selectedImages.length === 6
              ? "You can not add any more images"
              : "Select images"}

            <VisuallyHiddenInput
              type="file"
              accept="image/jpeg,image/png,image/tiff,image/webp"
              onChange={(event) => handleUploadClick(event)}
              multiple
            />
          </Button>

          {displayImages()}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => handleCreateNewGallery()}
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<CloudUploadIcon />}
            disabled={
              selectedImages.length < 1 || createGalleryMutation.isLoading
                ? true
                : false
            }
          >
            {createGalleryMutation.isLoading
              ? "Uploading Images"
              : "Upload Images"}
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="error"
            size="small"
            startIcon={<CancelIcon />}
            disabled={createGalleryMutation.isLoading ? true : false}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddImagesModal;
