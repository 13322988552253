import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { Divider } from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import _ from "lodash";
import AppProjectSearchBar from "./AppProjectSearchBar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddProjectsToNetworkModal({
  open,
  setOpen,
  networkProjects,
  networkProjectsIsLoading,
  networkProjectsError,
  setSelectedProjects,
  selectedProjects,
  newNetworkProject,
  addProjectToNetworkMutation,
}) {
  // --- STATE ----
  const [searchValue, setSearchValue] = useState(null);
  // --- MUTATION ----
  const { isLoading, isError } = addProjectToNetworkMutation;
  // --- ACTIONS ---
  const handleClose = () => {
    setOpen(false);
    setSelectedProjects([]);
  };

  const displayProjects = () => {
    if (networkProjectsIsLoading)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      );

    if (networkProjectsError)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert variant="filled" severity="error">
            Something went wrong while fetching the projects...
          </Alert>
        </div>
      );

    if (_.isEmpty(networkProjects))
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert variant="filled" severity="info">
            There are no projects at this time...
          </Alert>
        </div>
      );

    const searchBar = () => {
      if (_.isEmpty(networkProjects)) return [];
      if (searchValue === null) return networkProjects;

      return networkProjects.filter(
        (value) => value.name === searchValue.name && value
      );
    };

    const result = searchBar().map((value) => {
      return (
        <FormControlLabel
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
          control={
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedProjects([...selectedProjects, value.id]);
                  return;
                }
              }}
            />
          }
          label={value.name}
          key={value.id}
        />
      );
    });
    return result;
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CloseIcon />}
          onClick={handleClose}
        >
          {" "}
          close
        </Button>
        <div className="text-center">
          <h3
            style={{
              fontSize: "22px",
              color: "#000",
              fontWeight: "800",
              textTransform: "uppercase",
            }}
          >
            <AccountTreeIcon /> Add Projects To Network
          </h3>
        </div>
      </DialogTitle>
      {isError && (
        <Alert variant="filled" severity="error">
          Something went wrong while adding projects to the network. Kindly try
          again...
        </Alert>
      )}

      <Divider />
      <DialogContent>
        &nbsp; &nbsp;
        <AppProjectSearchBar
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          projects={networkProjects}
        />
        &nbsp; &nbsp; &nbsp;
        <FormGroup>{displayProjects()}</FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={isLoading && true}
          onClick={newNetworkProject}
        >
          {isLoading ? "Adding projects to network..." : "   Add Projects"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddProjectsToNetworkModal;
