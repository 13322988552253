import React from "react";
import ReactDOM from "react-dom";
import "mdbreact/dist/css/mdb.css";
import App from "./App";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/reusablecomponents/ErrorFallback";

const handleError = (error, info) => {
  console.log("ErrorBoundary caught an error:", error, info);
};

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);
