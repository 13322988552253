import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchProfile,
  fetchCoreValue,
  fetchGoals,
  fetchMyNetwork,
} from "../redux";
import _ from "lodash";
import Navbar from "../components/navbar/Navbar";
import "../stylesheets/profiles.css";
import ProfileSummary from "../components/profile/ProfileSummary";
import ProfileNetwork from "../components/profile/ProfileNetwork";
import ProfileGoals from "../components/profile/ProfileGoals";
import ProfileInventories from "../components/profile/ProfileInventories";
import PrivacyWarning from "../components/reusablecomponents/PrivacyWarning";
import ProfileProjects from "../components/profile/ProfileProjects";
import ProfileSuggestions from "../components/profile/ProfileSuggestions";
import styles from "../stylesheets/cssModules/profile.module.css";
import ProfileSideBar from "../components/profile/reusableComponents/ProfileSideBar";
import { Button, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { emphasize } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Avatar from "@mui/material/Avatar";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const ProfileShowContainer = ({
  match,
  history,
  fetchProfile,
  profile,
  fetchCoreValue,
  fetchGoals,
  fetchInventories,
  fetchMyNetwork,
}) => {
  const id = match.params.id;
  const [tabName, setTabName] = useState("summary");
  const [, setCoreValues] = useState({});
  const [goals, setGoals] = useState({});
  const [myNetwork, setMyNetwork] = useState({});
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetchProfile(id).then((res) => {
      setProjects(res.payload.projects);
      fetchCoreValue(id).then((results) => {
        setCoreValues(results.payload);
      });
      fetchGoals(id).then((results) => {
        setGoals(results.payload);
      });
      fetchMyNetwork(id).then((results) => {
        setMyNetwork(results.payload);
      });
    });
  }, [
    fetchProfile,
    fetchCoreValue,
    fetchGoals,
    fetchInventories,
    fetchMyNetwork,
    id,
  ]);

  const showTab = () => {
    if (!_.isEmpty(profile.data)) {
      const res = profile.data.profile;
      switch (tabName) {
        case "summary":
          return <ProfileSummary profile={res} />;
        case "goals":
          return (
            <ProfileGoals
              id={id}
              goals={goals}
              handleGoalSubmit={() => console.log("unseen")}
              privacy={false}
            />
          );
        case "networks":
          return (
            <ProfileNetwork
              myNetwork={myNetwork}
              handleNetworkSubmit={() => console.log("unseen")}
              privacy={false}
            />
          );
        case "inventories":
          return <ProfileInventories id={id} privacy={false} />;
        case "projects":
          return (
            <ProfileProjects
              projects={projects}
              privacy={false}
              projectCategories={profile.data.project_categories}
              handlRefreshProjects={() => console.log("unseen")}
            />
          );
        case "suggestions":
          return <ProfileSuggestions profile={profile.data} />;
        default:
          return <ProfileSummary profile={res} />;
      }
    }
  };
  const handleClick = (event) => {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  const handleLinkClick = (event, target) => {
    event.preventDefault(); // Stop the default navigation

    // Open the link in a new tab with desired features (optional)
    window.open(`${target}`, "_blank", "noopener,noreferrer");
  };

  const showProfile = (res, userId) => {
    return (
      <>
        {/* --- start here --- */}
        <div className={styles.main_container}>
          <ProfileSideBar setTab={setTabName} tab={tabName} />
          <div className={styles.main_display_area}>
            <div className={styles.header_image_area}>
              <div className={styles.profile_edit_button}>
                {profile.user_id === userId ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.push("/profile")}
                  >
                    Edit Profile
                  </Button>
                ) : null}
              </div>
            </div>

            <div className={styles.profile_image_area}>
              {res.profile_avatar.url !== null ? (
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    alt={`${res.first_name} ${res.last_name}`}
                    src={`${res.profile_avatar.url}`}
                    sx={{ width: 130, height: 130, border: "1px solid #fff" }}
                  />
                </StyledBadge>
              ) : (
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    src="/broken-image.jpg"
                    sx={{ width: 130, height: 130, border: "1px solid #fff" }}
                  />
                </StyledBadge>
              )}
              <div className={styles.profile_name_container}>
                <h2 className={styles.actual_profile_name}>
                  {res.first_name} {res.last_name}
                </h2>
                {res.role !== null && (
                  <h4 className={styles.profile_role}> {res.role}</h4>
                )}
              </div>
              <div className={styles.social_media_icons}>
                {res.facebook_url !== null && (
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      margin: "5px",
                    }}
                    startIcon={<FacebookIcon />}
                    onClick={(e) => handleLinkClick(e, res.facebook_url)}
                  >
                    Facebook
                  </Button>
                )}
                {res.instagram_url !== null && (
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      margin: "5px",
                    }}
                    startIcon={<InstagramIcon />}
                    onClick={(e) => handleLinkClick(e, res.instagram_url)}
                  >
                    {" "}
                    Instagram
                  </Button>
                )}
                {res.twitter_url !== null && (
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      margin: "5px",
                    }}
                    startIcon={<TwitterIcon />}
                    onClick={(e) => handleLinkClick(e, res.twitter_url)}
                  >
                    Twitter
                  </Button>
                )}
              </div>
            </div>
            <Divider
              sx={{
                marginTop: "-100px",
              }}
            />
            <div className={styles.profile_nav}>
              <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                  <StyledBreadcrumb
                    component="a"
                    href="#"
                    label="DashBoard"
                    icon={<HomeIcon fontSize="medium" />}
                  />
                  <StyledBreadcrumb
                    component="a"
                    href="#"
                    label="Profile"
                    icon={<AccountCircleIcon fontSize="medium" />}
                  />
                  <StyledBreadcrumb
                    label={tabName}
                    deleteIcon={
                      <ExpandMoreIcon
                        fontSize="medium"
                        sx={{
                          color: "white",
                        }}
                      />
                    }
                    onDelete={handleClick}
                    sx={{
                      backgroundColor: "purple",
                      color: "white",
                    }}
                  />
                </Breadcrumbs>
              </div>
            </div>
            <Divider />
            {showTab()}
          </div>
        </div>
      </>
    );
  };

  const showData = () => {
    if (!_.isEmpty(profile.data)) {
      const res = profile.data.profile;
      const isPrivate = res.is_private;
      const userId = profile.data.current_user;

      if (isPrivate && userId !== res.user_id) {
        return <PrivacyWarning title="Profile" />;
      } else {
        return showProfile(res, userId);
      }
    }
  };

  return (
    <>
      <Navbar />
      {showData()}
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProfile: (id) => dispatch(fetchProfile(id)),
  fetchCoreValue: (id) => dispatch(fetchCoreValue(id)),
  fetchGoals: (id) => dispatch(fetchGoals(id)),
  fetchMyNetwork: (id) => dispatch(fetchMyNetwork(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileShowContainer);
