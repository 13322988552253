/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import nocores from "../../assets/nocorevalues.png";
import _ from "lodash";
import AppReusableCard from "./globals/AppReusableCard";
import AppReusableDisplayUpvotedProfiles from "./globals/AppReusableDisplayUpvotedProfiles";
import { Button } from "@mui/material";
import EditDiscussion from "./projectDiscussion/EditDiscussion";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import CommentBox from "./globals/CommentBox";
import {
  UseCreateDiscussionCommentResponse,
  UseCreateNewDiscussionComment,
  UseCreateNewDiscussionCommentReplyOfReply,
  UseCreateNewDiscussionUpvote,
  UseEditDiscussionComment,
  UseEditDiscussionCommentReplyOfReply,
  UseEditDiscussionCommentResponse,
  UseFetchProjectDiscussionComments,
  UseUnvoteDiscussion,
  UseUnvoteDiscussionCommentReplyOfReply,
  UseUnvoteDiscussionCommentResponse,
  UseUnvoteOfDiscussionComment,
  UseUpvoteDiscussionCommentReplyOfReply,
  UseUpvoteDiscussionCommentResponse,
  UseUpvoteOfDiscussionComment,
} from "../../hooks/project/singleProject";

const ProjectDiscussion = ({
  projectDiscussion,
  discussionIsLoading,
  discussionError,
  projectID,
  speedDialState,
}) => {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const [discussionEditState, setDiscussionEditState] = useState({});

  const [isEditOpen, setIsEditOpen] = useState(false);
  const closeEditModal = () => setIsEditOpen(false);

  const [oneDiscussion, setOneDiscussion] = useState(null);
  const [commentModal, setCommentModal] = useState(false);

  const [votersToggleDrawer, setVotersToggleDrawer] = useState(false);
  const [votersProfiles, setVotersProfiles] = useState([]);
  const [drawerTitle, setDrawerTitle] = useState("");

  const [commentBoxDetail, setCommentBoxDetail] = useState("");
  const [discussionSelection, setDiscussionSelection] = useState({
    discussion: "",
    idea: "",
    purpose: "",
  });

  const [editSubmitButton] = useState(false);

  // --- RQ (REACT QUERY)
  const discussionUpvoteMutation = UseCreateNewDiscussionUpvote();
  const handleLikeDiscussion = async (value) => {
    const data = {
      discussion_like: {
        discussion_id: value.id,
      },
    };
    const discussionLikeData = {
      projectID,
      discussionID: value.id,
      data,
    };
    discussionUpvoteMutation.mutate(discussionLikeData);
  };

  const discussionUnvoteMutation = UseUnvoteDiscussion();
  const handleUnlikeDiscussion = async (value) => {
    const discussionLikeData = {
      projectID,
      discussionID: value.id,
    };
    discussionUnvoteMutation.mutate(discussionLikeData);
  };
  // --- COMMENTS (RQ)
  const {
    data: commentData,
    isLoading: commentsIsLoading,
    error: commentsError,
  } = UseFetchProjectDiscussionComments(projectID, oneDiscussion);

  const createNewCommentMutation = UseCreateNewDiscussionComment(
    projectID,
    oneDiscussion,
    () => setCommentBoxDetail("")
  );
  const handleCreateComment = () => {
    const data = {
      project_comment: {
        comment_text: commentBoxDetail,
      },
    };
    const commentData = {
      projectID,
      discussionID: oneDiscussion,
      data,
    };
    createNewCommentMutation.mutate(commentData);
  };

  const editCommentMutation = UseEditDiscussionComment(
    projectID,
    oneDiscussion
  );

  const handleEditOfComment = async (editState, setEditState) => {
    const { itemId, content } = editState;
    const data = {
      project_id: projectID,
      project_discussion_id: oneDiscussion,
      id: itemId,
      project_comment: {
        comment_text: content,
      },
    };

    editCommentMutation.mutate(data);

    setEditState({
      form: false,
      itemId: null,
      content: "",
    });
  };

  const createUpvoteOfCommentMutation = UseUpvoteOfDiscussionComment(
    projectID,
    oneDiscussion
  );
  const handleUpvoteOfComment = (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id: upvoteable_id.id,
        upvoteable_type: "ProjectComment",
      },
    };
    createUpvoteOfCommentMutation.mutate(data);
  };

  const createUnvoteOfCommentMutation = UseUnvoteOfDiscussionComment(
    projectID,
    oneDiscussion
  );
  const handleUnvoteOfComment = async (upvoteable_id) => {
    const data = {
      upvoteable_id: upvoteable_id.id,
      upvoteable_type: "ProjectComment",
    };
    createUnvoteOfCommentMutation.mutate(data);
  };

  const createNewCommentResponseMutation = UseCreateDiscussionCommentResponse(
    projectID,
    oneDiscussion
  );
  const handleCreateOfCommentReponses = async (replyState, setReplyState) => {
    const { itemReplyId, replyContent } = replyState;
    const data = {
      project_id: projectID,
      discussion_id: oneDiscussion,
      project_comment_id: itemReplyId,
      commentReponse: {
        response_text: replyContent,
      },
    };
    createNewCommentResponseMutation.mutate(data);
    setReplyState({
      display: false,
      itemReplyId: null,
      replyContent: "",
    });
  };

  const editDiscussionCommentResponseMutation =
    UseEditDiscussionCommentResponse(projectID, oneDiscussion);
  const handleEditOfCommentResponses = async (
    editCommentResponse,
    setEditCommentResponses,
    commentID
  ) => {
    const { responseID, content } = editCommentResponse;

    const data = {
      project_id: projectID,
      discussion_id: oneDiscussion,
      project_comment_id: commentID,
      id: responseID,
      commentReponse: {
        response_text: content,
      },
    };
    editDiscussionCommentResponseMutation.mutate(data);
    setEditCommentResponses({
      form: false,
      responseID: null,
      content: "",
    });
  };

  const upvoteCommentDiscussionResponse = UseUpvoteDiscussionCommentResponse(
    projectID,
    oneDiscussion
  );
  const handleUpvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectCommentResponse",
      },
    };
    upvoteCommentDiscussionResponse.mutate(data);
  };

  const downvoteCommentDiscussionResponseMutation =
    UseUnvoteDiscussionCommentResponse(projectID, oneDiscussion);
  const handleUnvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectCommentResponse",
    };
    downvoteCommentDiscussionResponseMutation.mutate(data);
  };

  const createReplyOfReplyMutation = UseCreateNewDiscussionCommentReplyOfReply(
    projectID,
    oneDiscussion
  );
  const handleCreateOfCommentReplyOfReply = async (data, commentID, state) => {
    const replyOfReplyData = {
      projectID: projectID,
      discussionID: oneDiscussion,
      projectCommentID: commentID,
      projectCommentResponseID: data.itemReplyId,
      commentReplyOfReply: {
        comment: {
          response_text: data.replyContent,
        },
      },
    };
    createReplyOfReplyMutation.mutate(replyOfReplyData);
    state({
      display: false,
      itemReplyId: null,
      replyContent: "",
    });
  };

  const editReplyOfReplyMutation = UseEditDiscussionCommentReplyOfReply(
    projectID,
    oneDiscussion
  );
  const handleEditOfReplyOfReply = async (
    replyOfReplyEditState,
    commentID,
    commentResponseID,
    setReplyOfReplyEditState
  ) => {
    const replyOfReplyData = {
      projectID: projectID,
      discussionID: oneDiscussion,
      projectCommentID: commentID,
      projectCommentResponseID: commentResponseID,
      ID: replyOfReplyEditState.responseID,
      commentReplyOfReplyEdit: {
        comment: {
          response_text: replyOfReplyEditState.content,
        },
      },
    };
    editReplyOfReplyMutation.mutate(replyOfReplyData);
    setReplyOfReplyEditState({
      ...replyOfReplyEditState,
      form: false,
    });
  };

  const upvoteCommentDiscussionReplyOfReplyMutation =
    UseUpvoteDiscussionCommentReplyOfReply(projectID, oneDiscussion);
  const handleUpvoteOfCommentReplyOfReply = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectCommentResponseOfResponse",
      },
    };
    upvoteCommentDiscussionReplyOfReplyMutation.mutate(data);
  };

  const downvoteCommentDiscussionReplyOfReplyMutation =
    UseUnvoteDiscussionCommentReplyOfReply(projectID, oneDiscussion);
  const handleUnvoteOfCommentReplyOfReply = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectCommentResponseOfResponse",
    };
    downvoteCommentDiscussionReplyOfReplyMutation.mutate(data);
  };

  // ==== EDIT OF THE DISCUSSION ====
  const HandleEditAction = (value) => {
    setDiscussionEditState({ ...value, discussion_id: value.id });
    setIsEditOpen(true);
  };
  const handleOpenCommentModal = (value) => {
    setOneDiscussion(value.id);

    setDiscussionSelection({
      idea: value?.idea || "",
      purpose: value?.purpose || "",
      discussion: value?.discussion || "",
    });
    setCommentModal(true);
  };

  const displayDiscussions = () => {
    if (_.isEmpty(projectDiscussion)) {
      return (
        <>
          <div className="w-100 text-center mb-4">
            <h4 className="text-dark mb-4">No discussions.</h4>
            <img
              className="no-cores-thumbnail mb-4"
              src={nocores}
              alt="no-cv"
            />
          </div>
        </>
      );
    }
    const results = projectDiscussion.map((value) => {
      return (
        <div key={value.id} className="assumption-card-cont">
          <AppReusableCard
            idea={value.idea}
            purpose={value.purpose}
            inputFieldName="Discussion"
            inputField={value.discussion}
            creatorFirstName={value.profile_first_name}
            creatorLastName={value.profile_last_name}
            createdAt={value.created_at}
            editAction={() => HandleEditAction(value)}
            openCommentModal={() => handleOpenCommentModal(value)}
            actionItems={true}
            creatorImage={
              value.creator_profile.profile_avatar
                ? value.creator_profile.profile_avatar.url
                : null
            }
            votes={value.likes.counter}
            userLiked={value.likes.upvoted}
            likeAction={() => handleLikeDiscussion(value)}
            unLikeAction={() => handleUnlikeDiscussion(value)}
            upvoted_by={value.upvoted_by}
            shared_with={value.shared_with}
            setVotersToggleDrawer={setVotersToggleDrawer}
            setVotersProfiles={setVotersProfiles}
            setDrawerTitle={setDrawerTitle}
            comments={value.comments}
          />
        </div>
      );
    });
    return results;
  };

  if (discussionIsLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );

  if (discussionError)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          Something went wrong while fetching the discussion kindly refresh{" "}
        </Alert>
      </div>
    );

  return (
    <>
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => toggleProjectSpeedDialMenu("discussion", true)}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button"
        >
          Add Discussion
        </Button>
      </div>
      <EditDiscussion
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        projectID={projectID}
        closeEditModal={closeEditModal}
        setDiscussionEditState={setDiscussionEditState}
        discussionEditState={discussionEditState}
      />

      <AppReusableDisplayUpvotedProfiles
        votersToggleDrawer={votersToggleDrawer}
        setVotersToggleDrawer={setVotersToggleDrawer}
        profiles={votersProfiles}
        drawerTitle={drawerTitle}
        setDrawerTitle={setDrawerTitle}
        setVotersProfiles={setVotersProfiles}
      />
      <CommentBox
        open={commentModal}
        setOpen={setCommentModal}
        isLoading={commentsIsLoading}
        isError={commentsError}
        comments={commentData ? commentData : []}
        handleCreateComment={handleCreateComment}
        detail={commentBoxDetail}
        setCommentBoxDetail={setCommentBoxDetail}
        label="Discussion"
        commentBoxIdea={discussionSelection.idea}
        commentBoxPurpose={discussionSelection.purpose}
        labelDetails={discussionSelection.discussion}
        idea={discussionSelection.idea}
        purpose={discussionSelection.purpose}
        handleEditOfComment={handleEditOfComment}
        editSubmitButton={editSubmitButton}
        handleUpvoteOfComment={handleUpvoteOfComment}
        handleUnvoteOfComment={handleUnvoteOfComment}
        handleCreateOfCommentReponses={handleCreateOfCommentReponses}
        handleEditOfCommentReponses={handleEditOfCommentResponses}
        handleUpvoteOfCommentResponses={handleUpvoteOfCommentResponses}
        handleUnvoteOfCommentResponses={handleUnvoteOfCommentResponses}
        handleCreateOfCommentReplyOfReply={handleCreateOfCommentReplyOfReply}
        handleEditOfReplyOfReply={handleEditOfReplyOfReply}
        handleUpvoteOfCommentReplyOfReply={handleUpvoteOfCommentReplyOfReply}
        handleUnvoteOfCommentReplyOfReply={handleUnvoteOfCommentReplyOfReply}
      />

      <div className="profile-core-values container-fluid container-m-well">
        <div className="discussion-display-container">
          <div className="discussion-card-area">{displayDiscussions()}</div>
        </div>
      </div>
    </>
  );
};

export default ProjectDiscussion;
