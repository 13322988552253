import { BASEURL } from "../../API/apiData";

const getAllComments = async (projectID, discussionID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/discussion/${discussionID}/project_comments`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewComments = async (commentData) => {
  const token = localStorage.getItem("token");
  const { projectID, discussionID, data } = commentData;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/discussion/${discussionID}/project_comments`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editProjectComment = async (data) => {
  const token = localStorage.getItem("token");

  const { project_id, project_discussion_id, id, project_comment } = data;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(project_comment),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/discussion/${project_discussion_id}/project_comments/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewCommentResponse = async (data) => {
  const { project_id, discussion_id, project_comment_id, commentReponse } =
    data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentReponse),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/discussion/${discussion_id}/project_comments/ ${project_comment_id}/project_comment_responses`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editDiscussionCommentResponse = async (data) => {
  const { project_id, discussion_id, project_comment_id, commentReponse, id } =
    data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentReponse),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/discussion/${discussion_id}/project_comments/ ${project_comment_id}/project_comment_responses/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export {
  getAllComments,
  createNewComments,
  editProjectComment,
  createNewCommentResponse,
  editDiscussionCommentResponse,
};
