import React, { useState } from "react";
import styles from "./profileSidebar.module.css";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import SummarizeIcon from "@mui/icons-material/Summarize";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Divider } from "@mui/material";

const ProfileSideBar = ({ setTab, tab }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sidebar
      backgroundColor="#fff"
      collapsed={collapsed}
      className={styles.side_bar_container}
    >
      <div className={styles.sidebar_top_bar}>
        <div className={styles.close_icon}>
          {collapsed ? (
            <ArrowCircleRightIcon
              color="secondary"
              fontSize="large"
              onClick={() => setCollapsed(false)}
            />
          ) : (
            <ArrowCircleLeftIcon
              color="secondary"
              fontSize="large"
              onClick={() => setCollapsed(true)}
            />
          )}
        </div>
      </div>
      <Divider light={true} className={styles.side_bar_divider} />
      <Menu>
        <MenuItem
          icon={<SummarizeIcon />}
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            "&:active": {
              backgroundColor: "#13395e",
              color: "#b6c8d9",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          onClick={() => setTab("summary")}
          active={tab === "" || tab === "summary" ? true : false}
          className={
            tab === "" || tab === "summary" ? `${styles.active_tab}` : null
          }
        >
          {" "}
          Summary{" "}
        </MenuItem>

        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<FlagCircleIcon />}
          onClick={() => setTab("goals")}
          active={tab === "goals" ? true : false}
          className={tab === "goals" ? `${styles.active_tab}` : null}
        >
          {" "}
          Goals{" "}
        </MenuItem>

        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<VolunteerActivismIcon />}
          onClick={() => setTab("projects")}
          active={tab === "projects" ? true : false}
          className={tab === "projects" ? `${styles.active_tab}` : null}
        >
          {" "}
          Projects{" "}
        </MenuItem>

        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<IntegrationInstructionsIcon />}
          onClick={() => setTab("suggestions")}
          active={tab === "suggestions" ? true : false}
          className={tab === "suggestions" ? `${styles.active_tab}` : null}
        >
          {" "}
          Suggestion Box{" "}
        </MenuItem>

        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<AssessmentIcon />}
          onClick={() => setTab("networks")}
          active={tab === "networks" ? true : false}
          className={tab === "networks" ? `${styles.active_tab}` : null}
        >
          {" "}
          MyNetwork
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<LeaderboardIcon />}
          onClick={() => setTab("inventories")}
          active={tab === "inventories" ? true : false}
          className={tab === "inventories" ? `${styles.active_tab}` : null}
        >
          Inventory
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default ProfileSideBar;
