import { BASEURL } from "../../API/apiData";

const fetchAllQuickLinks = async (project_id) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_quick_links`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewQuickLink = async (data) => {
  const token = localStorage.getItem("token");
  const { project_id, project_quick_link } = data;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(project_quick_link),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_quick_links`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editProjectQuickLink = async (data) => {
  const { id, projectId, quikData } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(quikData),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectId}/project_quick_links/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export { editProjectQuickLink, fetchAllQuickLinks, createNewQuickLink };
