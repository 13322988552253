import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Button } from "@mui/material";

const ProjectQuickLinks = ({
  links,
  showModal,
  setQuicklinkEdit,
  setEditLink,
  quickLinkError,
  quickLinkIsLoading,
  quickLinkMutation,
}) => {
  const handleEditOfQuickLink = (link) => {
    setEditLink(link);
    setQuicklinkEdit(true);
  };

  const displayQuickLinks = () => {
    if (links.length < 1)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert severity="info">There are no quick links at the moment</Alert>
        </div>
      );

    const results = links.map((link) => {
      return (
        <TimelineItem key={link.id}>
          <TimelineSeparator>
            <TimelineDot color="secondary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.name}
            </a>
            <Button
              variant="outlined"
              className="ml-3"
              color="secondary"
              onClick={() => handleEditOfQuickLink(link)}
            >
              Edit
            </Button>
          </TimelineContent>
        </TimelineItem>
      );
    });
    return results;
  };

  if (quickLinkIsLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );

  if (quickLinkError)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          Something went wrong while getting the quick links
        </Alert>
      </div>
    );

  return (
    <>
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={showModal}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button"
        >
          Add Quick Link
        </Button>
      </div>

      <div className="project-quick-links-container container-fluid">
        <div className="s-project-collaborations-header">
          <h3>
            <i className="fas fa-link"></i>&nbsp;&nbsp;Quick Links&nbsp;&nbsp;(
            {links.length})&nbsp;
          </h3>
        </div>
        <div className="s-project-quicklinks-list">
          {quickLinkMutation.isError && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                left: "30%",
                marginTop: "10px",
              }}
            >
              <Alert variant="filled" severity="error">
                Something went wrong while creating the quicklink. Kindly try
                again
              </Alert>
            </div>
          )}

          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {displayQuickLinks()}
          </Timeline>
        </div>
      </div>
    </>
  );
};

export default ProjectQuickLinks;
