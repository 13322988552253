/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styles from "./sidebar.module.css";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import SummarizeIcon from "@mui/icons-material/Summarize";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import CollectionsIcon from "@mui/icons-material/Collections";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ForumIcon from "@mui/icons-material/Forum";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Avatar from "@mui/material/Avatar";
import LinkIcon from "@mui/icons-material/Link";
import { deepOrange } from "@mui/material/colors";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Divider } from "@mui/material";

const SideBar = ({ singleProject, setTab, tab }) => {
  const [collapsed, setCollapsed] = useState(false);

  const { project } = singleProject;
  const { created_by } = project;
  return (
    <Sidebar
      backgroundColor="#fff"
      collapsed={collapsed}
      className={styles.side_bar_container}
    >
      <div className={styles.sidebar_top_bar}>
        <div className={styles.close_icon}>
          {collapsed ? (
            <ArrowCircleRightIcon
              color="secondary"
              fontSize="large"
              onClick={() => setCollapsed(false)}
            />
          ) : (
            <ArrowCircleLeftIcon
              color="secondary"
              fontSize="large"
              onClick={() => setCollapsed(true)}
            />
          )}
        </div>
        <div className={styles.project_creator}>
          <h6>Project Created By:</h6>

          {created_by !== null && created_by.profile_avatar.url !== null ? (
            <Avatar
              alt={`${created_by.first_name} ${created_by.last_name}`}
              src={`${created_by.profile_avatar.url}`}
              sx={{ width: 56, height: 56 }}
              className={styles.avatar_project_creator}
            />
          ) : (
            <Avatar
              sx={{ bgcolor: deepOrange[500], height: "56px", width: "56px" }}
              src="/broken-image.jpg"
              alt={`${created_by.first_name}`}
              className={styles.avatar_project_creator}
            />
          )}
          {created_by !== null && (
            <span>
              {created_by.first_name} {created_by.last_name}
            </span>
          )}
        </div>
      </div>
      <Divider light={true} className={styles.side_bar_divider} />
      <Menu>
        <MenuItem
          icon={<SummarizeIcon />}
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            "&:active": {
              backgroundColor: "#13395e",
              color: "#b6c8d9",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          onClick={() => setTab("sum")}
          active={tab === "" || tab === "sum" ? true : false}
          className={
            tab === "" || tab === "sum" ? `${styles.active_tab}` : null
          }
        >
          {" "}
          Summary{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<LinkIcon />}
          onClick={() => setTab("quick")}
          active={tab === "quick" ? true : false}
          className={tab === "quick" ? `${styles.active_tab}` : null}
        >
          {" "}
          Quick Links
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<FlagCircleIcon />}
          onClick={() => setTab("goa")}
          active={tab === "goa" ? true : false}
          className={tab === "goa" ? `${styles.active_tab}` : null}
        >
          {" "}
          Goals{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<TipsAndUpdatesIcon />}
          onClick={() => setTab("ass")}
          active={tab === "ass" ? true : false}
          className={tab === "ass" ? `${styles.active_tab}` : null}
        >
          {" "}
          Assumptions{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<VolunteerActivismIcon />}
          onClick={() => setTab("nee")}
          active={tab === "nee" ? true : false}
          className={tab === "nee" ? `${styles.active_tab}` : null}
        >
          {" "}
          Inventory{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<CollectionsIcon />}
          onClick={() => setTab("gal")}
          active={tab === "gal" ? true : false}
          className={tab === "gal" ? `${styles.active_tab}` : null}
        >
          {" "}
          Gallery{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<FormatListNumberedIcon />}
          onClick={() => setTab("top")}
          active={tab === "top" ? true : false}
          className={tab === "top" ? `${styles.active_tab}` : null}
        >
          {" "}
          Top10{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<ForumIcon />}
          onClick={() => setTab("dis")}
          active={tab === "dis" ? true : false}
          className={tab === "dis" ? `${styles.active_tab}` : null}
        >
          {" "}
          Discussion{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<IntegrationInstructionsIcon />}
          onClick={() => setTab("sug")}
          active={tab === "sug" ? true : false}
          className={tab === "sug" ? `${styles.active_tab}` : null}
        >
          {" "}
          Suggestion Box{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<AccountTreeIcon />}
          onClick={() => setTab("sub")}
          active={tab === "sub" ? true : false}
          className={tab === "sub" ? `${styles.active_tab}` : null}
        >
          {" "}
          Sub Projects{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<AssessmentIcon />}
          onClick={() => setTab("swo")}
          active={tab === "swo" ? true : false}
          className={tab === "swo" ? `${styles.active_tab}` : null}
        >
          {" "}
          SWOT Analysis{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<LeaderboardIcon />}
          onClick={() => setTab("bmc")}
          active={tab === "bmc" ? true : false}
          className={tab === "bmc" ? `${styles.active_tab}` : null}
        >
          BMC
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<CalendarMonthIcon />}
          className={tab === "cal" ? `${styles.active_tab}` : null}
          onClick={() => setTab("cal")}
          active={tab === "cal" ? true : false}
        >
          {" "}
          Calender
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default SideBar;
