/*eslint-disable */
import React, { useState, useEffect } from "react";
import "../stylesheets/swot.css";
import SwotBlocks from "../components/singleProject/swot/SwotBlocks";
import NoSwotsComponent from "../components/singleProject/swot/NoSwotsComponent";
import SwotInfoModal from "../components/singleProject/swot/SwotInfoModal";
import {
  createNewSwot,
  getAllSwot,
} from "../services/swotAnalysis/projectSwot";

function SwotContainer({ projectID, singleProject, setSwotLength }) {
  let projectName = singleProject.name;
  const [swotInfo, setSwotInfo] = useState({});
  const [loader, setLoader] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  const fetchProjectSwot = async () => {
    const { message, result } = await getAllSwot(projectID);
    if (message === "Swot not available") {
      setLoader(false);
      setSwotInfo({
        message,
      });
      return;
    } else if (message === "Swot found successfully") {
      setLoader(false);
      setSwotInfo({
        message: message,
        title: result.title,
        id: result.id,
      });
      setSwotLength(1);
      return;
    }
  };

  useEffect(() => {
    fetchProjectSwot();
  }, []);

  const showAlert = () => {
    if (
      window.confirm(
        "Are you sure you want to create a Swot analysis for this project?"
      )
    ) {
      createNewProjectSort();
      return;
    }
    return;
  };

  const createNewProjectSort = async () => {
    setLoader(true);
    const { message, result } = await createNewSwot(projectID, projectName);
    if (message === "Swot created successfuly") {
      setLoader(false);
      setSwotInfo({
        message: "Swot found successfully",
        title: result.title,
        id: result.id,
      });
      setSwotLength(1);
    }
  };

  const displayLoader = () => {
    return (
      <div className="loader-items-sort">
        <span
          className="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  const displayInfo = () => {
    if (swotInfo.message === "Swot not available") {
      return (
        <NoSwotsComponent
          message={swotInfo.message}
          createNewProjectSort={showAlert}
          loader={loader}
          setModalShow={setModalShow}
        />
      );
    } else if (swotInfo.message === "Swot found successfully") {
      return (
        <SwotBlocks
          swotTitle={swotInfo.title}
          setModalShow={setModalShow}
          swotID={swotInfo.id}
          projectID={projectID}
        />
      );
    }
  };

  return <div>{loader === true ? displayLoader() : displayInfo()}</div>;
}

export default SwotContainer;
