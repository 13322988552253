import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Alert from "@mui/material/Alert";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { UseCreateNewProjectDiscussion } from "../../../hooks/project/singleProject";
import { Button } from "@mui/material";

function CreateDiscussion({ isOpen, closeModal, projectID }) {
  // === MAKE THE FORM CONTROLLED ===
  const [formState, setFormState] = useState({
    discussion: "",
    idea: "",
    purpose: "",
  });

  const resetFormState = () => {
    setFormState({
      discussion: "",
      idea: "",
      purpose: "",
    });
    closeModal();
  };

  const createNewDiscussionMutation =
    UseCreateNewProjectDiscussion(resetFormState);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const savingData = {
      discussionData: {
        discussionData: { ...formState },
      },
      project_id: projectID,
    };
    createNewDiscussionMutation.mutate(savingData);
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#047C7C" }}
        closeButton
      >
        <Modal.Title>Add Discussion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {createNewDiscussionMutation.isError && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <Alert variant="filled" severity="error">
              An error occured while creating the discussion kindly try
              again....
            </Alert>
          </div>
        )}

        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="need">Title of discussion</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formState.discussion}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, discussion: data });
              }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="idea">Idea</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formState.idea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, idea: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="purpose">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formState.purpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, purpose: data });
              }}
            />
          </div>

          <div className="form-group float-right">
            <Button
              type="submit"
              disabled={createNewDiscussionMutation.isLoading && true}
              variant="contained"
              color="secondary"
            >
              {createNewDiscussionMutation.isLoading
                ? "Creating discussion...."
                : "Create Discussion"}
            </Button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateDiscussion;
