import React from "react";
import parse from "html-react-parser";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Button } from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import PushPinIcon from "@mui/icons-material/PushPin";
import {
  UseCreateSingleProjectFollow,
  UseDeleteSingleProjectFollow,
} from "../../hooks/project/singleProject";

const ProjectDescription = ({
  description,
  project,
  toggleProjectSpeedDialMenu,
}) => {
  const pinMutataion = UseCreateSingleProjectFollow();
  const handlePin = () => {
    const projectID = project.id;
    pinMutataion.mutate(projectID);
  };

  const unPinMutation = UseDeleteSingleProjectFollow();
  const handleUnpin = () => {
    const projectID = project.id;
    unPinMutation.mutate(projectID);
  };

  return (
    <>
      <div className="project-dec-title-bar-ss">
        <SummarizeIcon /> <span>Project Description</span>{" "}
        <Button
          variant="contained"
          color="secondary"
          className="ml-5 project-edit-button"
          startIcon={<EditTwoToneIcon color="primary" />}
          onClick={() => toggleProjectSpeedDialMenu("editproject", true)}
          size="small"
        >
          Edit project
        </Button>
        {project.is_favorite ? (
          <Button
            color="secondary"
            size="medium"
            variant="contained"
            startIcon={<PushPinIcon color="success" />}
            sx={{
              marginLeft: "20px",
            }}
            onClick={handleUnpin}
            disabled={unPinMutation.isLoading}
          >
            UNPIN
          </Button>
        ) : (
          <Button
            color="secondary"
            size="medium"
            variant="outlined"
            startIcon={<PushPinIcon />}
            sx={{
              marginLeft: "20px",
            }}
            onClick={handlePin}
            disabled={pinMutataion.isLoading}
          >
            PIN
          </Button>
        )}
      </div>
      <div className="project-description-container-ss">
        <div className="actual-description">{parse(description)}</div>
      </div>
    </>
  );
};

export default ProjectDescription;
