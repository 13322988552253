/*eslint-disable */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import InfoIcon from "@mui/icons-material/Info";
import { fetchUserBadges } from "../../services/badges/badgeService";
import UserBadge from "../badges/UserBadge";

const ProfileBadges = ({ userId }) => {
  const [badges, setBadges] = useState([]);

  const getUserBadges = async (data) => {
    const response = await fetchUserBadges(data);
    if (response) setBadges(response.result);
  };

  useEffect(() => {
    getUserBadges({ user_id: userId });
  }, [userId]);

  const showBadges = () => {
    if (!_.isEmpty(badges)) {
      return (
        <>
          <div className="user-badge-container">
            {badges.map((badge) => (
              <UserBadge key={badge.id} badge={badge} />
            ))}
          </div>
        </>
      );
    } else if (_.isEmpty(badges)) {
      return (
        <>
          <div
            className="w-100 text-center"
            style={{
              marginTop: "50px",
            }}
          >
            <Alert icon={<InfoIcon fontSize="inherit" />} severity="success">
              You have not been awarded any badge yet.
            </Alert>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="profile-core-values">{showBadges()}</div>
    </>
  );
};

export default ProfileBadges;
