import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import styles from "../css/main.module.css";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import HomeIcon from "@mui/icons-material/Home";
import Avatar from "@mui/material/Avatar";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import logo from "../../../assets/img/logo.png";
import { deepOrange } from "@mui/material/colors";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const TopBar = ({ profile }) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setTimeout(() => {
      history.push("/signin");
      window.location.reload(true);
    }, 1000);
  };

  const navigateTo = (profile) => history.push(`/profile`);

  const navigateToAdmin = () => history.push(`/admin`);

  const navigateToLandingPage = () => history.push("/");

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      color="transparent"
      className={styles.top_bar_container}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-10 col-12">
            <div role="presentation" className={styles.bread_navigation}>
              <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                  component="a"
                  onClick={navigateToLandingPage}
                  label="Landing Page"
                  icon={<HomeIcon fontSize="small" />}
                  className={styles.bread_project_name_one}
                />
                <StyledBreadcrumb
                  label={"DashBoard"}
                  className={styles.bread_project_name_active}
                  icon={<CheckCircleIcon fontSize="small" color="#000" />}
                />
              </Breadcrumbs>
            </div>
          </div>
          <div className={`col-md-2 col-12 ${styles.collaborators_container}`}>
            <div className="container-fluid">
              <div className="row">
                <div className={styles.mobile_logo_container}>
                  <img
                    className={styles.tuc_logo_mobile}
                    src={logo}
                    alt="nPmRzQ.png"
                    border="0"
                  />
                </div>
                <div className={styles.favorite_container}>
                  <div className={styles.upvoted_by_container}>
                    <Tooltip title="Account settings">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        {profile.profile.profile_avatar &&
                        profile.profile.profile_avatar.url !== null ? (
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            variant="dot"
                          >
                            <Avatar
                              alt={`${profile.profile.first_name} ${profile.profile.last_name}`}
                              src={`${profile.profile.profile_avatar.url}`}
                            />
                          </StyledBadge>
                        ) : (
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            variant="dot"
                          >
                            <Avatar
                              sx={{ bgcolor: deepOrange[500] }}
                              src="/broken-image.jpg"
                              alt={`${profile.profile.first_name}`}
                            />
                          </StyledBadge>
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem onClick={() => navigateTo(profile)}>
                      {profile.profile.profile_avatar &&
                      profile.profile.profile_avatar.url !== null ? (
                        <Avatar
                          alt={`${profile.profile.first_name} ${profile.profile.last_name}`}
                          src={`${profile.profile.profile_avatar.url}`}
                        />
                      ) : (
                        <Avatar
                          sx={{ bgcolor: deepOrange[500] }}
                          src="/broken-image.jpg"
                          alt={`${profile.profile.first_name}`}
                        />
                      )}{" "}
                      Profile
                    </MenuItem>
                    {profile.user.user_type_id === 1 && (
                      <MenuItem onClick={() => navigateToAdmin()}>
                        <AdminPanelSettingsIcon
                          className="mr-2"
                          sx={{
                            fontSize: "32px",
                          }}
                        />
                        Admin
                      </MenuItem>
                    )}

                    <Divider />
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <PersonAdd fontSize="small" />
                      </ListItemIcon>
                      Add another account
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      Settings
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </div>{" "}
                {/* <==== Favorite container */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppBar>
  );
};

export default TopBar;
