/*eslint-disable */
import React, { useState } from "react";
import { connect } from "react-redux";
import { createProjectTopTen, fetchProjectTopTens } from "../../../redux";
import { ToastContainer } from "react-toastify";
import "../../../stylesheets/projectTop10.css";
import ProjectTop10All from "./ProjectTop10All";
import ProjectTop10Single from "./ProjectTop10Single";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

const ProjectTop10 = ({
  id,
  topTens,
  topTensIsLoading,
  topTensError,
  speedDialState,
}) => {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const [viewNo, setViewNo] = useState(1);
  const [topTenId, setTopTenId] = useState(null);

  const setId = (val) => {
    setViewNo(2);
    setTopTenId(val);
  };

  const showView = () => {
    if (viewNo == 1) {
      return (
        <>
          <ProjectTop10All pId={id} topTens={topTens} setId={setId} />
        </>
      );
    } else if (viewNo == 2) {
      return (
        <ProjectTop10Single pId={id} ttId={topTenId} setViewNo={setViewNo} />
      );
    }
  };

  if (topTensIsLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );

  if (topTensError)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert variant="filled" severity="error">
          Something went wrong while fetching the project to10s
        </Alert>
      </div>
    );

  return (
    <>
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => toggleProjectSpeedDialMenu("top10", true)}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button"
        >
          Add Top10
        </Button>
      </div>
      <ToastContainer />
      <div className="profile-core-values">
        <div className="w-100 p-2">{showView()}</div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchProjectTopTens: (id) => dispatch(fetchProjectTopTens(id)),
  createProjectTopTen: (id, data) => dispatch(createProjectTopTen(id, data)),
});

export default connect(null, mapDispatchToProps)(ProjectTop10);
