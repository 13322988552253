import React from "react";
import Modal from "react-bootstrap/Modal";
import ideaIcon from "../../../images/idea.png";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Alert from "@mui/material/Alert";
import { Button } from "@mui/material";
import { UseEditProjectSuggestion } from "../../../hooks/project/singleProject";

const ProjectSuggestionEditModal = ({
  projectID,
  suggestionEdit,
  setSuggestionEdit,
  closeModal,
  isOpen,
}) => {
  const editSuggestionMutation = UseEditProjectSuggestion(closeModal);
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const editData = {
      suggestionData: {
        project_suggestion: {
          idea: suggestionEdit.idea,
          purpose: suggestionEdit.purpose,
          details: suggestionEdit.details,
        },
      },
      pId: projectID,
      psId: suggestionEdit.id,
    };
    editSuggestionMutation.mutate(editData);
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#309897" }}
        closeButton
      >
        <Modal.Title>Edit Project Suggestion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {editSuggestionMutation.isError && (
            <Alert variant="filled" severity="error">
              Something went wrong while editing the suggestion..
            </Alert>
          )}
        </div>

        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="description">Suggestion</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={suggestionEdit.details}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSuggestionEdit({
                  ...suggestionEdit,
                  details: data,
                });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="idea">
              <img className="psb-idea-icon" src={ideaIcon} alt="idea-icon" />
              Idea
            </label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={suggestionEdit.idea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSuggestionEdit({
                  ...suggestionEdit,
                  idea: data,
                });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={suggestionEdit.purpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSuggestionEdit({
                  ...suggestionEdit,
                  purpose: data,
                });
              }}
            />
          </div>

          <div>
            <hr />
            <div className="form-group float-right">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={editSuggestionMutation.isLoading ? true : false}
              >
                {editSuggestionMutation.isLoading
                  ? "Editing suggestion..."
                  : "Edit suggestion"}
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(ProjectSuggestionEditModal);
