import { BASEURL } from "../../API/apiData";

const getAllSubProjects = async (projectID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/subprojects`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const postSubProject = async (subData) => {
  const { projectID, data } = subData;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/subprojects`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const removeSubProject = async (data) => {
  const token = localStorage.getItem("token");
  const { id: projectID, subProjectID } = data;
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/subprojects/${subProjectID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export { getAllSubProjects, postSubProject, removeSubProject };
