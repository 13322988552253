import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { UseEditProjectAssumption } from "../../../hooks/project/singleProject";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";

function EditAssumption({
  closeEditModal,
  openEditModal,
  assumptionID,
  editState,
  projectID,
}) {
  //  ==== FORM CONTROL STATE ====
  const [formState, setFormState] = useState({
    assumption: "",
    idea: "",
    purpose: "",
  });

  // ==== POPULATE THE EDIT FORM ====
  useEffect(() => {
    setFormState({
      assumption: editState.assumption,
      idea: editState.idea,
      purpose: editState.purpose,
    });
  }, [editState]);

  // --- RQ EDIT ASSUMPTION MUTATION
  const EditAssumptionMutation = UseEditProjectAssumption(closeEditModal);

  //  ==== SUBMIT THE FORM ====
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      assumptionID,
      projectID,
      assumptionData: {
        ...formState,
      },
    };
    EditAssumptionMutation.mutate(data);
  };

  return (
    <Modal show={openEditModal} onHide={closeEditModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#047C7C" }}
        closeButton
      >
        <Modal.Title>Edit Assumption</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {EditAssumptionMutation.isError && (
          <Alert variant="filled" severity="error">
            Something went wrong while editing the assumption. Kindly try again
          </Alert>
        )}

        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="need">Assumption</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={editState.assumption}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, assumption: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="idea">Idea</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={editState.idea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, idea: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="purpose">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={editState.purpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, purpose: data });
              }}
            />
          </div>
          <div className="form-group float-right">
            <Button
              variant="contained"
              type="submit"
              disabled={EditAssumptionMutation.isLoading ? true : false}
              color="secondary"
            >
              Edit Assumption
            </Button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
}

export default EditAssumption;
