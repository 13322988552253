import { BASEURL } from "../../API/apiData";

const fetchAllProjectDiscussions = async (projectID) => {
  const token = localStorage.getItem("token");
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(
      `${BASEURL}projects/${projectID}/discussion`,
      requestOptions
    );
    const results = await response.json();
    return results;
  } catch (error) {
    return error;
  }
};

const createProjectDiscussion = async (data) => {
  const token = localStorage.getItem("token");
  const { project_id, discussionData } = data;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(discussionData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/discussion`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editProjectDiscussion = async (data) => {
  const token = localStorage.getItem("token");
  const { editData, projectID, discussionID } = data;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(editData),
  };

  try {
    await fetch(
      `${BASEURL}projects/${projectID}/discussion/${discussionID}`,
      requestOptions
    );
  } catch (error) {
    return error;
  }
};

const createNewDiscussionShare = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}shares`, requestOptions);
  const results = await response.json();
  return results;
};

export {
  fetchAllProjectDiscussions,
  createProjectDiscussion,
  editProjectDiscussion,
  createNewDiscussionShare,
};
